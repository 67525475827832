/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Claimed Component
@import "../mixins.scss";

.redemption-div {
  margin-bottom: 90px;
  .redemption-title {
    font-size: 18px;
    line-height: 16px;
    color: #787b86;
  }
  .redemption-item {
    display: flex;

    // @include media-break("lg") {
    //   display: unset;
    // }
  }
  .red-itm {
    margin-right: 20px;
    h3 {
      font-size: 20px;
      @include media-break("lg") {
        font-size: 24px;
      }
    }

    @include media-break("lg") {
      margin-right: 0;
    }
  }
  .redemption-header {
    margin-top: 29px;
  }
  .nodata_hea {
    letter-spacing: -0.24px;
    font-weight: normal;

    @media screen and (max-width: 768px) {
      padding: 0 15px;
      font-size: 18px;
    }
  }
  .nodata_cap {
    color: #787B86;
    max-width: 500px;
    font-size: 18px;
    text-align: center;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
      width: 75%;
    }
  }
}
