/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Notification Component
.notification-div {
  margin-bottom: 144px;
  .notification-title {
    font-size: 18px;
    line-height: 16px;
    color: #000;
  }
  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #989898;
  }
  .notification-item {
    flex-direction: column;

    .not {
      &-tit {
        font-weight: 500;
        font-size: 17px;
        line-height: 22px;
        letter-spacing: -0.41px;
        color: #000000;
      }
      &-desc {
        font-size: 13px;
        line-height: 18px;
        color: #616161;
        margin-top: 5px;
      }
    }
  }
  .dark {
    font-weight: 600;
  }
  .border-bottom {
    border-bottom: 1px solid #ececec;
  }
}
