/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

.hiddenDiv {
  visibility:hidden;
  height: 0px;
  font-family: 'Noto_Sans_Bold';
}
.hiddenCondBold {
  visibility:hidden;
  height: 0px;
  font-family: 'Noto_Sans_CondBold';
}

.header-wrap {
  padding: 0 30px;
  // margin-bottom: 30px;
  height: 74px;
  background: #143973;
  @media (min-width: 1500px) {
    padding: 0 240px;
  }
  .header-inner-wrap {
    height: 100%;
    .left-menu {
      
      ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        // justify-content: space-between;
        list-style-type: none;
        padding: 0;
       

      
        li {
          cursor: pointer;
          margin-right: 22px;
          padding: 27px 10px;
          &:hover{
            background-color: hsla(0,0%,100%,.3);
            // border-bottom: 2px solid #fff;
          }
          span {
            color: #DAF6FE;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            font-family: "Bliss_Medium";
            white-space: nowrap;
    //        letter-spacing: -0.24px;
          }
          &.active {
            position: relative;
            &:after {
              content: "";
              position: absolute;
              width: 100%;
              left: 0;
              bottom: 0px;
              border-bottom: 4px solid #469ddd;
            }
            span {
              font-weight: 600;
              color: #fff;
            }
          }
        }
        .more-submenu-li {
          position: relative;
          .more-submenu {
            position: absolute;
            width: 300px;
            top: 74px;
            padding: 20px 0px;
            z-index: 1;
            border-radius: 8px;
            background: #fff;
            -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-name: dropdown;
            animation-name: dropdown;
            ul {
              flex-direction: column;
              justify-content: start;
              align-items: normal;
              li {
                padding-bottom: 7px !important;
                padding-top: 7px !important;
                padding-left: 13px;
                margin: 0;
                &:hover {
                  background-color: #469ddd;
                  span {
                    color: #fff;
                  }
                }
                &.active {
                  &:after {
                    border-bottom: none;
                  }
                  span {
                    border-bottom: 4px solid #469ddd;
                  }
                }
                span {
                  padding: 0 3px 2px 1px;
                  color: #595959;
                }
              }
            }
          }
        }
        .more-submenu-li.menu-active{
          .more-menu:after{
              content: "";
              position: absolute;
              width: 100%;
              left: 0;
              bottom: 0px;
              border-bottom: 4px solid #ffff;
            }
          
        }
      }
    }
    .right-menu {
      width: 75%;
      margin-left: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      @media (min-width: 1500px) {
        width: 75%;
      }
      @media (min-width: 1700px) {
        width: 73%;
        justify-content: flex-end;
        .spacer {
          margin-right: 36px;
        }
        .extspacer {
          margin-right: 48px;
        }
      }
      .deal-div {
        position: relative;
        height: 74px;
        padding: 10px 0px;
      }
      .my-account {
        position: relative;
        .btn-account {
          padding: 9px 16px;
          background: #469dde;
          border: 1px solid #469dde;
          box-sizing: border-box;
          border-radius: 6px;
          &.btn {
            &:active {
              box-shadow: none;
            }
          }
          &:hover{
            background: #0083bd;
            border:1px solid #007bb0
          }
          span {
            color: #daf6fe;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            text-transform: uppercase;
            font-family: "Bliss_Medium";
          }
          img {
            padding-left: 18px;
          }

          &.act {
            background: #fff;
            span {
              color: #469dde;
            }
          }
        }
        .myaccount-submenu {
          position: absolute;
          width: 240px;
          top: 54px;
          padding: 15px 0;
          z-index: 1;
          background: #fff;
          -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
          -webkit-animation-name: dropdown;
          animation-name: dropdown;
          border-radius: 6px;
          margin-left: -70%; // remove for left align
          ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            li {
              padding: 7px 0 7px 30px;
              cursor: pointer;
              span {
                color: #222222;
                //    text-transform: uppercase;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.015em;
              }
              &.active {
                // position: relative;
                // &:after{
                //     content: "";
                //     position: absolute;
                //     width: 100%;
                //     left: 0px;
                //     bottom: 10px;
                //     border-bottom: 4px solid #469DDD;
                // }
                // span{
                //     font-weight: 600;
                // }
                span {
                  color: #ffffff;
                  font-weight: 600;
                }
                background-color: #469ddd;
                border-radius: 2px;
              }
              &:hover {
                span {
                  color: #ffffff;
                }
                background-color: #469ddd;
              }
            }
          }
        }
      }
      .language-wrap {
        .select-wrap {
          .slt__value-container {
            background: transparent !important;
            font-size: 15px !important;
            line-height: 18px;
            color: #E5ECF5;
            font-family: "Bliss_Medium";
            .flex-center {
              margin-right: -10px;
              img {
                padding-top: 4px;
                margin-left: 11px;
              }
            }
          }
          .css-26l3qy-menu {
            width: 250px;
            box-shadow: none;
            border-radius: 0px;
            margin-top: 20px;
            -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-name: dropdown;
            animation-name: dropdown;
            .slt__option--is-selected {
              background-color: #fff;
            }
          }
          .css-4ljt47-MenuList {
            padding: 20px 10px;
            .slt__option {
              padding: 12px;
            }
          }
        }
        &.active {
          background: #436090;
          padding: 16px 1px;
          border-bottom: 5px solid #fff;
        }
      }
      .language-wrap-ar {
        .select-wrap {
          .slt__value-container {
            background: transparent !important;
            font-size: 14px !important;
            color: #fff;
            .flex-center {
              margin-left: -15px;
              img {
                padding-top: 4px;
                margin-right: 11px;
              }
            }
          }
          .css-26l3qy-menu {
            width: 250px;
            box-shadow: none;
            border-radius: 0px;
            margin-top: 20px;
            -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-name: dropdown;
            animation-name: dropdown;
            .slt__option--is-selected {
              background-color: #fff;
            }
          }
          .css-4ljt47-MenuList {
            padding: 20px 10px;
            .slt__option {
              padding: 12px;
            }
          }
        }
        &.active {
          background: #436090;
          padding: 16px 1px;
          border-bottom: 5px solid #fff;
        }
      }
      .search-div {
        &.active {
          background: #436090;
          padding: 25px 20px;
          border-bottom: 5px solid #fff;
        }
      }
      .whishlist-div {
        &.active {
          background: #436090;
          padding: 25px 20px;
          border-bottom: 5px solid #fff;
        }
      }
      .location-div {
        &.active {
          background: #436090;
          padding: 25px 20px;
          border-bottom: 5px solid #fff;
        }
      }
    }
  }
  &.header-wrap-ar {
    .left-menu {
      ul {
       
          li {
            margin-right: 0;
            margin-left: 22px;
          }
        }
      
      .more-submenu-li {
        
        .more-submenu {
          right: 0;
          -webkit-animation-name: dropdownAR !important;
          animation-name: dropdownAR !important;
          ul {
            li {
              padding-right: 15px;
              &.active {
                &:after {
                  left: 0px;
                  right: 10px;
                }
              }
            }
          }
        }
      }
      .more-submenu-li.menu-active{
        .more-menu:after{
            content: "";
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0px;
            border-bottom: 4px solid #ffff;
          }
        
      }

    }
    .right-menu {
      margin-right: auto;
      margin-left: inherit;

      @media (min-width: 1700px) {
        .spacer {
          margin-left: 36px;
          margin-right: 0;
        }
        .extspacer {
          margin-left: 48px;
          margin-right: 0;
        }
      }

      .myaccount-submenu {
        -webkit-animation-name: dropdownAR !important;
        animation-name: dropdownAR !important;
        ul {
          li {
            padding: 7px 30px 7px 0px !important;
          }
        }
      }
      .language-wrap {
        .select-wrap {
          .css-26l3qy-menu {
            -webkit-animation-name: dropdownAR !important;
            animation-name: dropdownAR !important;
          }
        }
      }
    }
  }
}

.search-bar-div {
  z-index: 1;
  &.adib-search-bar {
    top: 74px !important;
    .search-content {
      padding: 70px 30px;
      min-height: 450px;
      .header-search-box {
        align-items: baseline;
      }
      .headerBoxLeft {
        width: 60%;
        @media (max-width:768px) {  
          width: 100%;
          }
        .search-container {
          float: left;
          width: 100%;
          .auto-suggest-wrap {
            position: relative;
            top: 40px;
            .icon-wrap {
              position: absolute;
              top: 10px;
              left: 10px;
              z-index: 1;
            }
            .search-text {
              position: absolute;
              top: 10px;
              right: 30px;
              z-index: 1;
              span {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: #143973;
                letter-spacing: -0.24px;
                text-transform: uppercase;
              }
            }
            .search-text-ar {
              position: absolute;
              top: 10px;
              left: 30px;
              z-index: 1;
              span {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #143973;
                letter-spacing: -0.24px;
                text-transform: uppercase;
              }
            }
            .react-autosuggest__input {
              border: 1px solid #dfdfdf;
              border-radius: 2px;
              padding-left: 40px;
            }
            .react-autosuggest__suggestions-container {
              position: absolute;
              top: 40px;
              .react-autosuggest__suggestions-list {
                margin: 0 !important;
                padding: 0 15px;
                max-height: 150px;
              }
            }
          }
        }
        .tag-options {
          margin-top: 115px;
       //   float: left;
          .flex-item {
            margin-top: 25px;
            .card-cont {
              font-weight: 500;
              border: 1px solid #143973;
              color: #143973;
              text-transform: uppercase;
            }
          }

          &.sec {
            margin-top: 30px;
          }
          &.recent {
            margin-top: 115px;
          }
        }
      }
      .headerBoxRight {
        @media (min-width:768px) {  
        width: 30%;
        }
        .recent-search-wrap {
          margin-top: 30px;
        }
        .close-icon-flex {
          display: flex;
          justify-content: flex-end;
        }
      }
      .headerBoxLeft_ar {
        width: 60%;
        @media (max-width:768px) {  
          width: 100%;
          }
        .search-container {
          float: left;
          width: 100%;
          .auto-suggest-wrap {
            position: relative;
            top: 40px;
            .icon-wrap {
              position: absolute;
              top: 10px;
              right: 10px;
              z-index: 1;
            }
            .search-text {
              position: absolute;
              top: 10px;
              left: 30px;
              z-index: 1;
              span {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #143973;
                letter-spacing: -0.24px;
                text-transform: uppercase;
              }
            }
            .search-text-ar {
              position: absolute;
              top: 10px;
              left: 30px;
              z-index: 1;
              span {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #143973;
                letter-spacing: -0.24px;
                text-transform: uppercase;
              }
            }
            .react-autosuggest__input {
              border: 1px solid #dfdfdf;
              border-radius: 2px;
              padding-right: 40px;
            }
            .react-autosuggest__suggestions-container {
              position: absolute;
              top: 40px;
              .react-autosuggest__suggestions-list {
                margin: 0 !important;
                padding: 0 15px;
                max-height: 150px;
              }
            }
          }
        }
        .tag-options {
          margin-top: 115px;
      //    float: right;
          .flex-item {
            margin-top: 25px;
            .card-cont {
              font-weight: 500;
              border: 1px solid #143973;
              color: #143973;
              text-transform: uppercase;
            }
          }

          &.sec {
            margin-top: 30px;
          }
          &.recent {
            margin-top: 115px;
          }
        }
      }
    }
  }
}

@keyframes dropdown {
  0% {
    opacity: 0;
    left: 30px;
  }
  to {
    opacity: 1;
    left: 0;
  }
}

@keyframes dropdownAR {
  0% {
    opacity: 0;
    right: 30px;
  }
  to {
    opacity: 1;
    right: 0;
  }
}

@media (max-width: 768px) {
  .search-bar-div.adib-search-bar .search-content {
    padding: 20px;
    min-height: 335px;
  }
  .search-bar-div.adib-search-bar
    .search-content
    .headerBoxLeft
    .headerBoxLeft_ar
    .search-container {
    margin-top: 22px;
  }
  .search-bar-div.adib-search-bar .search-content .headerBoxLeft .headerBoxLeft_ar .tag-options {
    margin-top: 22px;
  }
  .search-bar-div .base-container .card-cont.mbl {
    width: 135px !important;
  }
  .search-bar-div.adib-search-bar
    .search-content
    .headerBoxLeft
    .headerBoxLeft_ar
    .search-container
    .auto-suggest-wrap {
    top: 0px;
  }
  .adib-search-header-mbl {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}
