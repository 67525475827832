/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/
.portfol-card{
    margin-left:-25px;
    @media (max-width: 1300px) {
    margin-left:-45px;
   }
    @media (max-width: 1200px) {
    margin-left:-65px;
  }
    @media (max-width: 1080px) {
    margin-left:-85px;
  }
   @media (max-width: 1000px) {
    margin-left:-95px;
  }
  @media (min-width:769px) and (max-width: 1000px) {
    margin-left: 15px;
  }
  }
.redeem-offer-card{
    padding: 15px 25px;
    background: #EAA140;
    border-radius: 4px;
    cursor: pointer;
    h3{
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        letter-spacing: -0.24px;
        color: #141414;
        margin-top: 0px;
    }
    .redeem-code{
        background: #141414;
        border-radius: 4px;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.24px;
        color: #FFFFFF; 
        padding: 7px 0px;
        text-align: center;
        margin-top: 8px;
    }
    .avail-code{
        background: #141414;
        border-radius: 4px;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        letter-spacing: -0.24px;
        color: #FFFFFF; 
        padding: 7px 0px;
        margin-top: 8px;
        text-align: center;
    }
    .offer-text{
        font-size: 10px;
        line-height: 14px;
        font-weight: 400;
        color: #303133;
        padding: 12px 0px 16px 0px;
        border-bottom:  1px solid #2F5186;
        word-wrap: break-word;

        .ellipsis {
            direction: ltr;
            display: inline-block;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: bottom;
        }
    }
    .offer-text-port{
        font-size: 10px;
        line-height: 14px;
        font-weight: 400;
        color: #303133;
        padding: 0px 0px 16px 0px;
        border-bottom:  1px solid #2F5186;
        word-wrap: break-word;
        .ellipsis {
            direction: ltr;
            display: inline-block;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: bottom;
        }
    }
    .oops-text{
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        color: #FFFFFF;
    }
    .expire-text{
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        color: #FFFFFF;
        padding: 12px 0px 16px 0px;
        border-bottom:  1px solid #FFECEC;
        text-align: center;
    }
    .valid-text{
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #303133;
        margin-top: 12px;
    }

    @media (min-width: 768px) and (max-width: 999px) {
        margin-top: 18px;
        width: 300px;
    }
}
