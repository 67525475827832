/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/
@import "../variable.scss";

.faq-div {
  font-family: "Noto Sans";
  .query-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.24px;
    color: #000000;
  }

  .query-title-mbl {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.337647px;
    color: #000000;
    margin-bottom: 0px;
  }
  .query-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #303133;
    a {
      text-decoration: underline;
    }
  }
  .query-desc-mbl {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: #001122;
    margin-top: 5px;
    a {
      text-decoration: underline;
    }
  }
  .freqTitle {
    font-family: if(
      $clientName == "adib",
      "Bliss_Bold" !important,
      "Noto Sans" !important
    );
  }
  .freqTitle-mbl {
    font-family: if(
      $clientName == "adib",
      "Bliss_Bold" !important,
      "Noto Sans" !important
    );
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.24px;
    color: #000811 !important;
  }
  .faq-wrap {
    margin-top: 35px;
    .question {
      padding: 15px 10px;
      background: #ffffff;
      border: 2px solid #e3e3e3;
      border-radius: 8px;
      margin-bottom: 16px;
    }
    .question h4 {
      margin-top: 0px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: -0.24px;
      color: #303133;
      &.active {
        font-weight: 600;
        font-family: if(
          $clientName == "adib",
          "Bliss_Bold" !important,
          "Noto Sans" !important
        );
      }
    }
    .question p {
      margin-top: 15px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      white-space: pre-line;
    }
    .question header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .question header h4 {
      margin-bottom: 0;
    }
    .btn {
      background: transparent;
      border-color: transparent;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: none;
    }
    .question-title {
      font-family: if(
        $clientName == "adib",
        "Bliss_Medium" !important,
        "Noto Sans" !important
      );
      cursor: pointer;
    }
  }
  .faq-footer {
    margin-top: 50px;
    margin-bottom: 60px;
  }
}

.faq-wrap-mbl {
  margin-top: 35px;
  .question {
    padding: 15px 10px;
    background: #ffffff;
    border: 2px solid #e3e3e3;
    border-radius: 8px;
    margin-bottom: 16px;
  }
  .question h4 {
    margin-top: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.24px;
    color: #303133;
    &.active {
      font-weight: 600;
      font-family: if(
        $clientName == "adib",
        "Bliss_Bold" !important,
        "Noto Sans" !important
      );
    }
  }
  .question p {
    margin-top: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    white-space: pre-line;
  }
  .question header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .question header h4 {
    margin-bottom: 0;
  }
  .btn {
    background: transparent;
    border-color: transparent;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: none;
  }
  .question-title {
    font-family: if(
      $clientName == "adib",
      "Bliss_Medium" !important,
      "Noto Sans" !important
    );
    cursor: pointer;
  }
}
