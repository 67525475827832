/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Card Component
@import "../mixins.scss";

.card {
  .card-cont {
    height: 90px;
    background: #ffffff;
    border: 1px solid #ececec;
    border-radius: 8px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .title-div {
    .hed-tit {
      font-size: 18px;

      @include media-break("md") {
        font-size: 20px;
      }
    }
  }
  .margin-left {
    margin-right: 20px;
    @include media-break("md") {
      margin-right: 60px;
    }
  }

  .slick-list {
    margin-top: 24px;
    padding: 0 20% 0 0;
  }

  .slick-slide {
    // width: 135px !important;
    padding: 0 8px 0 0;
    box-sizing: border-box;
  }
}
