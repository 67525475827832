/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Card Carousel
@import "../mixins.scss";
@import "../variable.scss";

.card_carousel {
  margin-bottom: 28px;
  .ind_list{
    // padding-left: 15px !important;
    // padding-right: 15px !important;
    @media screen and (min-width: 900px)and (max-width : 1900px)  {
      padding-left: 64px !important;
    padding-right: 64px !important;
    }
    @media screen and (min-width: 2000px) {
      padding-left: 187px !important;
    padding-right: 187px !important;
  }
}
  .carousel-div {
    // margin-top: 8px;

    @media screen and (max-width: 600px) {
      &.pdr_15 {
        padding-right: 15px;
      }
    }
  }
  .title-div {
    .hed-tit {
      // margin-left: 30px;
      // margin-right: 30px;
      font-size: 20px;
      font-weight: 600;
      line-height: 33px;
      letter-spacing: -0.24px;
      //color: if($clientName == 'adib', #262626  ,if($clientName == 'hdfc', #444 ,#000 !important));
      @include media-break("md") {
        font-size: if($clientName == "adib", 22px !important, 24px !important);
      }
      @media screen and (max-width: 900px) {
        margin-left: 15px;
        margin-right: 0px;
        color: #000811;
        line-height: 21px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .margin-right {
    // margin-right: 20px;
    // margin-left: 20px;
    @include media-break("md") {
      // margin-right: 30px;
      // margin-left: 30px;
    }
  }
  .margin-left {
    margin-left: 20px;
    @include media-break("md") {
      margin-left: 34px;
    }
  }

  .slick-slider {
    // margin-right: 30px !important;
    // padding-right: 30px !important;
  }
  .slick-list {
    margin-top: 18px;
    padding: 0 20% 5% 0px;
    @media screen and (max-width: 900px) {
      margin-top: 0px;
      padding: 0 20% 5% 15px;
    }
  }
  .slick-track {
    margin: 0px !important;
  }
  .slick-slide {
    padding-right: 13px;
    box-sizing: border-box;
    padding-top: 10px;
  }
  .slick-prev {
    left: -20px;
    z-index: 100;
  }

  .slide:hover {
    transition: 0.3s;
    transform: translate(0, -10px) !important;
    box-shadow: 0px 8px 32px 5px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
  }

  .slick-next {
    right: 20px;
    z-index: 100;

    @media screen and (min-width: 1400px) {
      right: 35px;
    }
    @media screen and (min-width: 1500px) {
      right: 33px;
    }
    @media screen and (min-width: 1700px) {
      right: 38px;
    }
  }
  .slick-prev,
  .slick-next {
    top: 39% !important;
    z-index: 1 !important;
  }
  .slick-prev::before,
  .slick-next::before {
    content: "";
  }
}
.main_container {
  overflow: hidden !important;
  width: 100%;
  display: block;
}
.main_container_Similar_Merchant {
  overflow: hidden !important;
  width: 100%;
  display: block;
}
.card_carousel_heartImg {
  display: inline-block !important;
  cursor: pointer !important;
  // position: absolute !important;
  margin-top: 13px !important;
}
.card_carousel_heartImgDiv {
  text-align: right !important;
  padding-right: 20px !important;
}

.carousel_arabic_min {
  .slick-list {
    padding-right: 0px !important;
    // margin-right: 0px !important;
    @media screen and (min-width: 768px) {
      margin-right: 0px !important;
    }
    @media screen and (max-width: 768px) {
      margin-right: 0px !important;
    }
  }
  .slick-slide {
    padding-left: 13px !important;
    padding-right: 0px !important;
  }
}

.carousel_arabic {
  .slick-list {
    padding-right: 0px !important;
    // margin-right: 30px !important;
    @media screen and (max-width: 768px) {
      margin-right: 0px !important;
    }
  }
  .slick-slide {
    padding-left: 13px !important;
    padding-right: 0px !important;
  }
}

[dir="rtl"] .slick-slide {
  float: left;
}

.carousel_min {
  .slick-list {
    margin-right: 0px !important;
  }
}
[dir="rtl"] .slick-next {
  right: 0px !important;
}
[dir="rtl"] .slick-prev {
  left: 20px !important;
}
@media screen and (max-width: 933px) {
  .scrollPosition{
    top: -155px !important;
  }
}