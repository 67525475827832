/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// App Feedback
@import "../mixins.scss";
@import "../variable.scss";

.app-div {
  // margin-bottom: 144px;
  min-height: 684px;
  .app {
    &-title {
      font-size: 18px;
      line-height: 16px;
      color: #787b86;
    }
  }
  [class$="menu"]{
    [class$="option"]{
      background-color: #fff;
      color: #000;
      &:hover{
        color: #004388;
        background: #D0E7FF;
      }
      
    }
  }

  .disable-icon {
    .lin-location-pin {
      display: none;
    }
    .css-yk16xz-control,
    .css-1pahdxg-control,
    .css-1fhf3k1-control {
      padding-left: 5px !important;
      min-height: 52px !important;
      background: #fafbfc;
      cursor: pointer;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-1wa3eu0-placeholder {
      font-weight: 600;
      font-size: 17px;
      line-height: 23px;
      letter-spacing: 0.337647px;
      color: #000000;
    }
    .css-1hwfws3 > div {
      color: $gray-04 !important;
    }
    .css-tlfecz-indicatorContainer {
      color: $gray-04 !important;
    }
    .css-2b097c-container {
      .css-l7s834-control,
      .css-l7s834-control:hover {
        border: 1px solid #004388 !important;
        border-color: #004388 !important;
        box-shadow: 0 0 0 0px #004388 !important;
      }
    }
  }
  .disable-icon-mbl {
    .lin-location-pin {
      display: none;
    }
    .css-yk16xz-control,
    .css-1pahdxg-control,
    .css-1fhf3k1-control {
      padding-left: 5px !important;
      min-height: 52px !important;
      background: #fafbfc !important;
      border-radius: 6px;
      cursor: pointer;
      border: 0px !important;
    }
    .css-1uccc91-singleValue {
      font-weight: 600 !important;
      font-size: 17px !important;
      line-height: 23px;
      letter-spacing: 0.337647px;
      color: #000000 !important;
    }
    .css-yk16xz-control:hover {
      border: 0px !important;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-1wa3eu0-placeholder {
      font-weight: 600;
      font-size: 17px;
      line-height: 23px;
      letter-spacing: 0.337647px;
      color: #000000;
    }
    .css-1hwfws3 > div {
      color: $gray-04 !important;
    }
    .css-tlfecz-indicatorContainer {
      color: #c7c7cc !important;
    }
    .css-l7s834-control,
    .css-l7s834-control:hover {
      border: 1px solid #004388 !important;
      border-color: #004388 !important;
      box-shadow: 0 0 0 0px #004388 !important;
    }
    .appFeedback-select{
      font-weight: 600;
      font-size: 17px;
      line-height: 23px;
      letter-spacing: 0.337647px;
      color: #000000;
    }
  }
  .feedTitle {
    font-family: if(
      $clientName == "adib",
      "Bliss_Bold" !important,
      "Noto Sans" !important
    );
  }

  .feedTitle-mbl {
    font-family: if(
      $clientName == "adib",
      "Bliss_Bold" !important,
      "Noto Sans" !important
    );
    font-size: 18px !important;
    line-height: 25px !important;
    letter-spacing: -0.24px !important;
    font-weight: 600 !important;
  }

  .app-title-mbl {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px !important;
    letter-spacing: 0.25px;
    margin-top: 5px;
    color: #001122 !important;
  }
  .feed-tit {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 1px;
    color: #252220;
    font-family: if(
      $clientName == "adib",
      "Bliss_Bold" !important,
      "Noto Sans" !important
    );
  }
  .feed-tit-mbl {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.337647px;
    color: #000000;
    font-family: if(
      $clientName == "adib",
      "Bliss_Bold" !important,
      "Noto Sans" !important
    );
  }
  .text-area {
    background: #fafbfc;
    border-radius: 6px;
    border: 0px;
  }
  .exp {
    cursor: pointer;
    background: #fafbfc;
    border: 2px solid transparent;
    width: 48px;
    height: 48px;
    border-radius: 48px;

    @include media-break("lg") {
      margin-right: 20px;
    }

    &-act {
      border: 2px solid #004388;
    }
  }
  .exp-ar {
    @include media-break("lg") {
      margin-right: 0;
      margin-left: 20px;
    }
  }
  .emoji-items {
    display: flex;
    justify-content: space-evenly;

    @include media-break("lg") {
      justify-content: unset;
    }
  }
  // .add-div {
  //   width: 33px;
  //   height: 33px;
  //   border-radius: 33px;
  //   font-weight: 300;
  //   font-size: 32px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   border: 2px solid #0292df;
  //   color: #0292df;
  // }
  .add-div {
  }
  .attach {
    margin-top: 2px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #0292df;
    letter-spacing: -0.41px;
  }
  .dark {
    color: #000;
  }
}

.sub-row {
  position: absolute;
  bottom: 35px;
  width: 100%;
}

.app-fed-sumbit {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.24px;
  text-transform: uppercase;
  color: #ffffff;
}

.btn-sub {
  background: #005ab7;
  border: 1px solid #1d86ff;
  min-width: 100% !important;
  text-align: center;
  padding: 11px 0;
  border-radius: 5px;
}

.suc-feedback {
  text-align: center;
  margin-top: 61px;
  min-height: 474px;
  .success-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.337647px;
    color: #000000;
    margin-top: 18px;
  }
  .success-des {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    margin-top: 12px;
  }

  .home-btn {
    position: absolute;
    bottom: 62px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;

    .home-tit {
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      // color: #0292df;
    }

    .home-arr {
      width: 18px;
      padding: 6px 0 0 6px;
    }
  }
}
