
.header-mobile{
    position: relative;
        top: 0px;
        left: 0px;
    
    .header-menu{
        background-color: #143973;
    }
    .header-top{   
        display: flex;
        justify-content: space-between;
        padding:0px 15px;
        background-color: #143973;
        width: 100%;
    .header-left{
        padding-top: 7px;
    }
    .header-right{
        display: flex;
        padding-top: 28px;
        .search{
            padding-right: 23px;
            margin-left: 23px;
        }
        .cancel-icon{
            path{
                stroke:#fff
            }
        }
    }}
    .category-menu{
        padding-top: 15px;
        padding-bottom: 24px;
    }
    .category-menu.white{
        padding-top: 15px;
        padding-bottom: 24px;
        border-bottom: 1px solid #30558F; 
    }
    .menu.white{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 24px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: #FFFFFF;
        .arrow-icon{
            svg{
        fill:#fff
            }
    }
    }
  
    .account-sec{
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 48px;
    background: #469DDE;
    border: 1px solid #469DDE;
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    span{
        padding-right: 18px;
    }
    }
    
}
.header-mobile.opened{
    //  height: 100vh;
    //  display: block;
    //  transition: all 0.5s ease;
     .category-menu{
        display: block;
        transition: all 0.5s ease;
        animation-delay: 0.3s;
     }
     .header-menu{
        height: calc(100vh - 74px);
        transition: all 0.5s ease;
        opacity: 1;
    }

.header-menu-modal-closed{
    opacity: 0;
    position: absolute;
    left: 100%;
    transition: left 1s ease;
}
.header-menu-modal-opened{
    opacity: 0;
    position: absolute;
    left: 100%;
    transition: left 1s ease;
} 
.header-menu-modal-opened.ar{
    opacity: 0;
    position: absolute;
    right: 100% !important;
    transition: right 1s ease !important;
} 
}
.header-mobile.closed{
     //74px
     height: 74px;
    //  display: none;
     transition: all 0.5s ease;
    //  .category-menu,.menu-header,.back-menu{
    //     display: none;
    //     transition: all 0.5s ease;
    //     animation-delay: 0.3s;
    //  }
     .header-menu{
         height: 0px;
        //  display: none;
         height: 0px;
         visibility: hidden;
         opacity: 0;
         transition: all 0.5s ease;
        
     }
     .account-sec{
        display: none;
        transition: all 0.5s ease;
        animation-delay: 0.3s;
     }
     .header-menu-modalopened{
        height: 0px;
        //  display: none;
         height: 0px;
         visibility: hidden;
        //  transition: all 0.5s ease;  
        
     }
.back-menu{width: 0px;transition: all 0.3s ease;}
// .menu,.category-menu{  visibility: hidden;transition: all 0.1s;}
}



.header-mobile-white-bg{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
//    background-color: white;
   .header-menu{
    width: 100%;
    opacity: 1;
    position: absolute;
    left: 0%;
    transition: left 1s ease;
    background-color: #143973;
    color: #FFFFFF;
    /* background-color: white !important; */
    height: calc(100vh - 74px);
   }
   .header-menu-modal-opened{
    width: 100%;
    opacity: 1;
    position: absolute;
    left: 0%;
    transition: left 1s ease;
    background-color: white !important;
    height: calc(100vh - 74px);
    overflow-y: auto;
}
.header-menu-modal-opened.ar{
    width: 100%;
    opacity: 1;
    position: absolute;
    right: 0% !important;
    transition: right 1s ease !important;
    background-color: white !important;
    height: calc(100vh - 74px);
    overflow-y: auto;
}
.header-menu-modal-closed{
    opacity: 0;
    position: absolute;
    left: 100%;
    transition: left 1s ease;
}
   .category-menu{
    padding-top: 15px;
    padding-bottom: 24px;
}
.menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #FFFFFF;
    .arrow-icon{
        svg{
    fill:#fff
        }
}
img{
    padding-left: 16px;
    padding-right: 16px;
}
}
.menu.selected{
    justify-content: start;
    font-weight: 600;
}
.account-sec{
position: absolute;
width: 100%;
left: 0;
bottom: 0;
display: flex;
justify-content: center;
align-items: center;
color: white;
height: 48px;
background: #469DDE;
border: 1px solid #469DDE;
font-size: 15px;
line-height: 20px;
text-transform: uppercase;
span{
    padding-right: 18px;
}
}
   .header-menu-modal{
    width:0px;
    transition:all 0.3s ease;
}
.header-menu-modal-opened{
    width: 100%;
    opacity: 1;
    position: absolute;
    left: 0%;
    transition: left 1s ease;
    background-color: white !important;
    height: calc(100vh - 74px);
   
}
.header-menu-modal-opened.ar{
    width: 100%;
    opacity: 1;
    position: absolute;
    right: 0% !important;
    transition: right 1s ease !important;
    background-color: white !important;
    height: calc(100vh - 74px);
   
}
   


.back-menu{
    padding: 0px 15px;
    // position: absolute;
    // top: 74px;
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 15px;
    background-color: #b0e7ff;
    color: #003878;
    text-transform: uppercase;
    // width: 100%;
    // transition: all 0.3s ease;
    span{
        padding-left: 8px;
    }
    
   }
   .menu-header{
    padding: 15px 15px 0px 15px;
    //    position: absolute;
    //    top: 134px;
    font-size: 27px;
    font-weight: 200;
    color: #262626;
    text-transform: capitalize;
    width:100%;
    transition: all 0.3s ease;
    
   }
    .header-top{   
        display: flex;
        justify-content: space-between;
        padding:0px 15px;
        background-color: #143973 !important;
    .header-left{
        padding-top: 7px;
    }
    .header-right{
        display: flex;
        padding-top: 28px;
        .search{
            padding-right: 23px;
            margin-left: 23px;
        }
        .cancel-icon{
            path{
                stroke:#fff
            }
        }
    }}
    .category-menu{
        padding-bottom: 24px;
    //    padding-right: 15px;
    //    padding-left: 15px;
       width: 100%;
       transition: all 0.3s ease;
       
    }
    .menu{
        display: flex;
        align-items: center;
        padding-top: 24px;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: #262626;
        .arrow-icon{
            svg{
        fill:#fff
            }
          
    }
    }
    .account-sec{
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 48px;
    background: #469DDE;
    border: 1px solid #469DDE;
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    span{
        padding-right: 18px;
    }
    }
    height: 100vh;
    width: 100vw;
    //  display: block;
     transition: width 0.5s ease;
     .category-menu,.menu-header{
        display: block;
        transition: width 0.5s ease;
        animation-delay: 0.3s;
     }
    .header-menu-modal{
        width:100%;
        transition:all 0.3s ease;
        position: absolute;
        left: 100%;
        width: 100%;
        height: calc(100vh - 74px);
        background: blue;
        -webkit-animation: slide 0.5s forwards;
        -webkit-animation-delay: 2s;
        animation: slide 0.5s forwards;
        animation-delay: 2s;
    }
}

.header-menu-modal-opened{
    width: 0%;
    opacity: 0;
    position: absolute;
    left: 0%;
    transition: left 1s ease;
}
.header-menu-modal-opened.ar{
    width: 0%;
    opacity: 0;
    position: absolute;
    right: 0% !important;
    transition: right 1s ease !important;
}

.header-menu-modal-closed{
    opacity: 0;
    position: absolute;
    left: 100%;
    width: 100%;
    transition: left 1s ease;
}

.header-menu{
    height: calc(100vh - 74px);
}
.sub-menu{
    font-weight: 300;
    font-size: 11px;
    line-height: 20px;
    padding-left: 15px;
    padding-right: 15px;
    letter-spacing: -0.24px;
    color: #FFFFFF;
    padding-top: 3px;
    text-transform: capitalize;
}