/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

@font-face {
    font-family: 'droid-sans-arabic';
    src: url("../fonts/adib-arabic/droid-sans-arabic.ttf") format("truetype"),
         url("../fonts/adib-arabic/droid-sans-arabic.otf") format("opentype"),
         url("../fonts/adib-arabic/droid-sans-arabic.woff") format("woff"),
         url("../fonts/adib-arabic/droid-sans-arabic.eot") format("embedded-opentype"),
         url("../fonts/adib-arabic/droid-sans-arabic.svg") format("svg");;
 }

 .arabic-body{
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5 !important;
    font-weight: 500;
    font-size: 1rem;
    font-family: 'droid-sans-arabic' !important;
    background-color: #fff;
    //Home Page
    .base-container-ar{
      .main-title{
         font-family: inherit !important;
         font-weight: bold !important;
      }
      .recent-title{
         font-family: inherit !important;
      }
      .card-cont{
         font-weight: bold !important;
      }
    }
    .arabic-lang{
       .profile-content{
          .profile-info{
             font-family: inherit;
          }
          .notification{
             margin-bottom: 5px;
          }
       }
    }
    .carousel_arabic{
       .img-content{
         .col-lft-ar{
            font-weight: bold !important;
         }
       }
       .info-content{
          .card-category-name{
             font-weight: bold !important;
          }
       }
    }
    .ftr-main{
       .justify-start{
          font-weight: bold !important;
       }
       .contact-div{
          .contact-phone-ar{
             font-weight: bold !important;
          }
       }
       .contact{
          .ftr-support-ar{
             font-weight: bold !important;
          }
       }
    }
    //Merchant Details Page
    .offer-info{
       .item-det-info-ar{
         line-height: 26px !important;
       }
    }
    //My Account Page
    .customer-div{
       .col-header{
          font-weight: bold !important;
       }
       .heading-item-title{
         font-weight: bold !important;
       }
    }
    .faq-wrap{
      .question-title{
         .active{
            font-weight: bold !important;
         }
      }
   }
   .faq-div {
      .freqTitle {
         font-family: inherit !important;
       }
       .faq-wrap {
         .question-title {
            font-family: inherit !important;
         }
         .question h4.active {
            font-family: inherit !important;
         }
       }
   }
    .claim-off {
      .title-1 {
         font-family: inherit !important;
       }
       .no_data_title {
         font-family: inherit !important;
       }
       .expires-on {
         font-family: inherit !important;
       }
    }
    .redemp {
      .title {
         font-family: inherit !important;
       }
       .no_data_title {
         font-family: inherit !important;
       }
    }
    .app-div .feedTitle{
      font-family: inherit !important;
    }
    .app-div .feed-tit {
      font-family: inherit !important;
    }
    .terms-div .mainTitle{
      font-family: inherit !important;
    }

    // Banner
    .banner-cont .boldTxt {
      font-family: inherit !important;
      font-weight: bold !important;
    }
    //Nav bar
    .header-wrap .header-inner-wrap {
      .right-menu .my-account .btn-account span {
         font-family: inherit !important;
       }
       .left-menu ul li span {
         font-family: inherit !important;
       }
    }
    // Card 
    .new-card-div .new_card .info-content {
      .card-category-name {
         font-family: inherit !important;
      }
      .expirees-info {
         font-family: inherit !important;
      }
    }
    .a-merchant-details .main-content .merchant-details-img-content .col-lft {
      font-family: inherit !important;
    }

    // Footer
    .footer .footer-top {
      font-family: inherit !important;
    }

    // Search bar
    .search-bar-div .base-container .main-title {
      font-family: inherit !important;
    }

    //Filter modal
    .filter-modal {
      .main-header .filter-title {
         font-family: inherit !important;
       }
       .filter-item-title, .filter-modal .item-title {
         font-family: inherit !important;
       }
       .item-title.clear {
         font-family: inherit !important;
       }
       .item-title {
         font-family: inherit !important;
       }
    }

    // Location 
    .header .not-loc-modal .main .sub-text {
      font-family: inherit !important;
    }

    // Table
    .table-main.redemption-table .table-header {
      font-family: inherit !important;
    }

 }
