/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// NavBar Component
@import "../mixins.scss";

.menubar-div {
  border-bottom: 1px solid #ececec;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-evenly;
  flex-direction: column;
  margin-bottom: 20px;

  @include media-break("lg") {
    display: flex;
    border-right: 1px solid #ececec;
    border-bottom: none;
    margin-bottom: 0;
  }

  .mnu {
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    color: #696969;
    margin-bottom: 10px;

    @include media-break("lg") {
      margin-bottom: 24px;
      display: unset;
      justify-content: unset;
      align-items: unset;
    }

    &.top {
      @include media-break("lg") {
        margin-top: 10px;
      }
    }

    &.bottom {
      @include media-break("lg") {
        margin-top: 6px;
      }
    }

    &-act {
      color: #00AE8E;
      border-bottom: 4px solid #000000;

      @include media-break("lg") {
        border-right: 4px solid #00AE8E;
        border-bottom: none;
      }
    }
  }
}

// Mobile Css
.menubar-div-mbl {
  overflow-x: auto;
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid #ececec;
  z-index: 0;

  .mnu {
    cursor: pointer;
    height: 20px;
    border-radius: 100px;
    color: #8e8e93;
    margin-right: 15px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    .title-mbl {
      font-size: 11px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px;
    }

    &-act {
      color: #fff;
      background: #00AE8E;
    }
  }
}
