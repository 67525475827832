/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Review Component
@import "../mixins.scss";

.review-item {
  .rating-div {
    flex-direction: column;
    margin-bottom: 64px;

    .rate-content {
      padding: 8px 18px;
      background: #f5f8ff;
      border-radius: 20px;
      width: 239px;
      margin-top: 64px;
      margin-bottom: 14px;

      .fa-star,
      .fa-star-half-o {
        font-size: 24px;
      }
    }
  }
  .rate-star-div {
    border-bottom: 1px solid #ececec;
    margin-bottom: 10px;

    @include media-break("lg") {
      border-right: 1px solid #ececec;
      border-bottom: none;
      margin-bottom: 0;
    }

    .last-item {
      margin-bottom: 10px;
      @include media-break("lg") {
        margin-bottom: 0;
      }
    }
  }
  .totalrate {
    font-weight: normal;
    font-size: 13px;
    height: 12px;
    line-height: 16px;
    color: #616161;
  }
  .star-text {
    font-size: 17px;
    line-height: 16px;
    color: #000000;
  }
  .progressbar {
    width: 200px;
    height: 16px;
  }
  .tag {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    background: #fff;
    border-radius: 20px;
    color: #222;
    cursor: pointer;
    overflow: hidden;
    padding: 10px 14px;
    border: 1px solid #ccc;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .line-div {
    margin-top: 44px;
    border-bottom: 1px solid #ececec;
  }
}
