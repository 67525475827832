/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/
@import "../variable.scss";

.card-hort {
  .card-wrp {
    width: 100%;
    border-bottom: 1px solid #e7e7e7;
    padding: 16px 0;
    display: flex;
    cursor: pointer;
    .card-logo {
      width: 35%;
      min-width: 130px;
      border-radius: 8px;
      position: relative;
      max-width: 130px;
      &.claim{
        border: 1px solid #e7e7e7;
      }
      .card-hat {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        z-index: 1;
      }
      .card-onl {
        font-family: "SF Pro Text";
        font-style: normal;
        font-weight: 600;
        font-size: 8px;
        line-height: 10px;
        text-align: center;
        letter-spacing: -0.08px;
        color: #ffffff;
        padding: 5px 6px;
        border-radius: 20px;
        background-color: #000000;
        position: absolute;
        top: 4px;
        left: 4px;
        z-index: 1;
      }
      .expiredMer {
        background-color: #B5B5B5 !important;
        color: rgba(0, 8, 17,0.5) !important;
      }
      .logo {
        display: flex;
        justify-content: center;
        //align-items: center;
        min-height: 90px;
        max-width: 100%;
        border-radius: 8px;
        // background-position: center;
        // background-repeat: no-repeat;
        // background-size: cover;
      }
      .expiredMob {
        filter: saturate(.05);
      }
    }
    .card-cnt {
      margin: 7px 0 0 16px;
      font-family: "Noto Sans";
      font-style: normal;
      .card-tit {
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: -0.24px;
        color: #000811;
      }
      .card-des {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #001122;
        padding-right: 10px;
      }
      .card-des-port{

        font-weight: 400;

        font-size: 12px;

        line-height: 16px;

        color: #001122;

        padding-right: 10px;

        padding-top: 5px;

      }
      .card-exp {
        margin-top: 8px;
        font-size: 12px;
        line-height: 16px;
        text-transform: capitalize;
        .exp {
          font-weight: 400;
          color: #727272;
        }

        .date {
          font-weight: 700;
          color: #000000;
        }
      }
      .card-expired {
        margin-top: 8px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-transform: capitalize;
        color: #E30613;
        .expInfo {
          position: relative;
          top: 3px;
          padding-left: 3px;
        }
      }
    }
    .expired-off{

  filter: grayscale(100%);

opacity: 0.5;

}

.vouch-div{

  height: 90px;

  width: 130px;

}

.card-port{
  display: flex;
  align-items: center;
}
.expired-off{
  filter: grayscale(100%);
opacity: 0.5;
}

.vouch-div{
  height: 90px;
  width: 130px;
}

.card-port{
  display: flex;
  align-items: center;
}
    .expiredMobInfo {
      opacity: 0.6;
    }
  }
}
