/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/
// @font-face {
//   // font-family: 'Noto_Sans_Nav';
//   src: url('../../fonts/NotoSans-Medium.woff2') format('woff2'),
//        url('../../fonts/NotoSans-Medium.woff') format('woff');
// }
// NavBar Component
.navbar-div {
  border-bottom: 1px solid #ececec;
  flex-wrap: wrap;
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  background-color: #fff;
  .nav {
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    height: 34px;
    color: #8e8e93;

    &-act {
      color: #00AE8E;
      border-bottom: 3px solid #00AE8E;
      svg {
        path {
          fill: #00AE8E;
        }
      }
    }
  }
}
.navbar-div-mbl {
  overflow-x: auto;
  scrollbar-width: 0px; /* Firefox */
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid #ececec;
  z-index: 0;

  .nav-mbl {
    cursor: pointer;
    height: 20px;
    border-radius: 100px;
    color: #8e8e93;
    margin-right: 15px;
    padding: 15px;

    .title-mbl {
      font-size: 11px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px;
      &.visa{
        max-width: max-content !important;
      }
    }

    &-act {
      color: #fff;
      background: #00AE8E;
    }
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.navbar-div-mbl::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.navbar-div-mbl {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.homeiconenglish{
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 11px;
}
.homeiconarabic {
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 11px;
}
.nav-bar-items {
  padding-left: 16px;
  padding-right: 16px;
}