/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

.footer{
    padding: 0px!important;
    @media screen and (max-width: 767px){
    display: none;
    }
    .footer-top{
        display: flex;
        justify-content: space-between;
        background-color: #004388;
        width: 100%;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        font-family: "Bliss_Bold";
        @media screen and (min-width: 768px){
            padding-left: 15px;
            padding-right: 15px;
        }
        @media screen and (min-width: 900px){
            padding-left: 64px;
            padding-right: 64px;
        }
        @media screen and (min-width: 1400px){
            padding-left: 64px;
            padding-right: 64px;
        }
        @media screen and (min-width: 1500px){
            padding-left: 64px;
            padding-right: 64px;
        }
        @media screen and (min-width: 1900px){
            padding-left: 187px;
            padding-right: 187px;
        }
        @media screen and (min-width: 2400px){
            padding-left: 524px;
            padding-right: 524px;
        }
        @media screen and (min-width: 3000px){
            padding-left: 1230px;
            padding-right: 1230px;
        }
        @media screen and (min-width: 5000px){ 
            padding-left: 36%;
            padding-right: 36%;
        }
        .footer-top-content{
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            position: relative;
            top: 5px;
            cursor: pointer;
            line-height: 20px;
        }
        .aref{
            color: #FFFFFF;
            text-decoration-line: none;
        }
        .line-sep{
            padding-left: 60px;
            padding-right: 60px;
            // opacity: 0.5;
            @media screen and (max-width: 1051px){
                padding-left: 30px;
                padding-right: 30px;
            }
            @media screen and (max-width: 961px){
                padding-left: 10px;
                padding-right: 10px;
            }
            @media screen and (max-width: 931px){
                padding-left: 5px;
                padding-right: 5px;
            }
            @media screen and (max-width: 901px){
            //    padding-left: 2px;
            //    padding-right: 2px;
            }
        }
    }
    .footer-inside{
        background-color: #001933;
        padding-bottom: 1rem;
        @media screen and (min-width: 768px){
            padding-left: 15px;
            padding-right: 15px;
        }
        @media screen and (min-width: 900px){
            padding-left: 64px;
            padding-right: 64px;
        }
        @media screen and (min-width: 1400px){
            padding-left: 64px;
            padding-right: 64px;
        }
        @media screen and (min-width: 1500px){
            padding-left: 64px;
            padding-right: 64px;
        }
        @media screen and (min-width: 1900px){
            padding-left: 187px;
            padding-right: 187px;
        }
        @media screen and (min-width: 2400px){
            padding-left: 524px;
            padding-right: 524px;
        }
        @media screen and (min-width: 3000px){
            padding-left: 1230px;
            padding-right: 1230px;
        }
        @media screen and (min-width: 5000px){ 
            padding-left: 36%;
            padding-right: 36%;
        }

        .footer-disc{
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            width: 80%;
            line-height: 22px;
            padding-top: 39px;
            padding-bottom: 25px;
            }
        // .footer-middle {
        //     display: flex;
        //     justify-content: space-between;
        //     .footer-disc{
        //         font-style: normal;
        //         font-weight: 400;
        //         font-size: 14px;
        //         color: #000811 !important;
        //         width: 80%;
        //         line-height: 22px;
        //         padding-top: 39px;
        //         padding-bottom: 25px;
        //         }
        // }
        
            
        .site-content{
            padding-top: 3rem;
        }
        // .footer-line{
        //     border: 0.5px solid #4B4E5A;
        // }
        .footer-bottom{
            padding-top: 1rem;
            display: flex;
            justify-content: space-between;
        .rights-reserved{
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
        }
        .maya-logo{
            position: relative;
            top: 2px;
        }
    }
    }
}