/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// All Offer Component
@import "../mixins.scss";
@import "../variable.scss";
@font-face {
  font-family: "Noto_Sans_CondMed";
  src: url("../../fonts/NotoSans-SemiCondensedMedium.woff2") format("woff2"),
    url("../../fonts/NotoSans-SemiCondensedMedium.woff") format("woff");
}

.ncf {
  @media screen and (max-width: 900px) {
    padding-left: 15px;
  }
  margin-bottom: 10rem;
  @media screen and (min-width: 451px) {
    //    margin-bottom: 18rem;
  }
  @media screen and (min-width: 1900px) {
    margin-bottom: 5rem;
  }
}

.offerlist {
  margin-top: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  width: 103.5% !important;
  margin-left: -10px !important;
  // padding:0px 20px 0px 0px
  @media screen and (min-width: 1500px) {
    width: 104.5% !important;
  }
  @media screen and (max-width: 768px) {
    justify-content: center;
    margin-left: 0px !important;
  }

  .allOffers-section{
    // padding:10px 10px 10px 10px;

    @media screen and (max-width: 600px) {
      //padding: 10px 0;
      width: 100%;
      width: -moz-available;          /* WebKit-based browsers will ignore this. */
      width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
      width: fill-available;
    }
    @media screen and (max-width: 450px) {
      //padding: 10px 0;
      width: 100%;
      width: -moz-available;          /* WebKit-based browsers will ignore this. */
      width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
      width: fill-available;
    }
  }

  @media (min-width: 992px) {
    .slide-all {
      width: 23% !important;
    }
  }

  @media (max-width: 810px) {
    .slide-all {
      width: 47% !important;
    }
  }

  @media screen and (max-width: 650px) {
    .slide-all {
      width: 100% !important;
     // margin: 0px !important;
    }
  }
  

  .slide-all:hover {
    transition: 0.3s;
    transform: translate(0, -10px) !important;
    box-shadow: 0px 8px 32px 5px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
  }
}

// @media screen and (min-width: 1400px) {
//   .alloffer {
//     padding-left: 60px !important;
//     padding-right: 60px !important;
//   }
// }

// @media screen and (min-width: 1500px) {
//   .alloffer {
//     padding-left: 240px !important;
//     padding-right: 240px !important;
//   }
// }

// @media screen and (min-width: 1200px) {
//   .alloffer {
//     padding-left: 30px !important;
//     padding-right: 30px !important;
//   }
// }

// @media only screen and (max-width: 1024px) {
//   .alloffer {
//     padding-left: 20px !important;
//     padding-right: 20px !important;
//   }
// }

// @media screen and (min-width: 900px) {
//   .alloffer {
//     padding-left: 20px !important;
//     padding-right: 20px !important;
//   }
// }

// @media screen and (max-width: 768px) {
//   .alloffer {
//     padding-left: 20px !important;
//     padding-right: 20px !important;
//   }
// }