@media screen and (min-width: 320px) {
  .mobile-right {
    display: none !important;
  }
  .mobile-logo-header {
    width: 60px;
  }
  .hide-image {
    margin-left: -242px !important;
    margin-right: 11px !important;
    transition: all 1s !important;
  }
  .search-bar-div {
    top: 60px !important;
  }
  .show-search-mobile .react-autosuggest__input {
    margin-right: 260px !important;
    transition: all 1s !important;
  }
  .icon-remove {
    z-index: 10000000 !important;
  }
  .mobile-similar-items {
    text-align: left !important;
    width: 100%;
    margin-bottom: 0px !important;
  }
}



@media screen and (min-width: 360px) {
  .mobile-right {
    display: none !important;
  }
  .mobile-logo-header {
    width: 60px;
  }
 
  .hide-image {
    margin-left: -242px !important;
    margin-right: 11px !important;
    transition: all 1s !important;
  }
  .search-bar-div {
    top: 50px !important;
  }
  .show-search-mobile .react-autosuggest__input {
    margin-right: 320px !important;
    transition: all 1s !important;
  }
  .icon-remove {
    z-index: 10000000 !important;
  }
  .mobile-similar-items {
    text-align: left !important;
    width: 100%;
    margin-bottom: 0px !important;
  }
}


@media screen and (min-width: 375px) {
  .mobile-right {
    display: none !important;
  }
  .mobile-logo-header {
    width: 85px;
  }
  .hide-image {
    margin-left: -242px !important;
    margin-right: 11px !important;
    transition: all 1s !important;
  }
  .search-bar-div {
    top: 60px !important;
  }
  .show-search-mobile .react-autosuggest__input {
    margin-right: 310px !important;
    transition: all 1s !important;
  }
  .icon-remove {
    z-index: 10000000 !important;
  }
  .mobile-similar-items {
    text-align: left !important;
    width: 100%;
    margin-bottom: 0px !important;
  }
}



@media screen and (min-width: 411px) {  
  .mobile-right {
    display: none !important;
  }
  .mobile-logo-header {
    width: 85px;
  }
  .hide-image {
    margin-left: -242px !important;
    margin-right: 11px !important;
    transition: all 1s !important;
  }
  .search-bar-div {
    top: 60px !important;
  }
  .show-search-mobile .react-autosuggest__input {
    margin-right: 320px !important;
    transition: all 1s !important;
  }
  .icon-remove {
    z-index: 10000000 !important;
  }
  .mobile-similar-items {
    text-align: left !important;
    width: 100%;
    margin-bottom: 0px !important;
  }
}



@media screen and (min-width: 480px) {
  .mobile-right {
    display: none !important;
  }
  .mobile-logo-header {
    width: 60px;
  }
  .hide-image {
    margin-left: -242px !important;
    margin-right: 11px !important;
    transition: all 1s !important;
  }
  .search-bar-div {
    top: 60px !important;
  }
  .show-search-mobile .react-autosuggest__input {
    margin-right: 420px !important;
    transition: all 1s !important;
  }
  .icon-remove {
    z-index: 10000000 !important;
  }
  .mobile-similar-items {
    text-align: left !important;
    width: 100%;
    margin-bottom: 0px !important;
  }
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 0px !important;
}
.react-autosuggest__suggestion:focus,
.react-autosuggest__suggestion:hover {
  background-color: #ddd;
}
}



@media screen and (min-width: 600px) {
  .mobile-right {
    display: none !important;
  }
  .mobile-logo-header {
    width: 60px;
  }
  .hide-image {
    margin-left: -242px !important;
    margin-right: 11px !important;
    transition: all 1s !important;
  }
  .mobile-similar-items {
    text-align: left !important;
    width: 100%;
    margin-bottom: 0px !important;
  }
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 0px !important;
  }
  .react-autosuggest__suggestion:focus,
  .react-autosuggest__suggestion:hover {
    background-color: #ddd;
  }
}

@media screen and (max-width: 600px) {
  .counter-number{
    font-size: 24px !important; 
    top: -20px;
    right: 50px;
  }
  .counter-number-right{
    font-size: 24px !important; 
    top: -20px;
    left: 50px;
  }
  .percentage-symbol {
    font-size: 12px;
    top: -10px;
    right: 35px;
  }
  .percentage-symbol-right {
    font-size: 12px;
    top: -10px;
    left: 35px;
  }
}


@media screen and (min-width: 768px) {
  .mobile-right {
    display: none !important;
  }
  .hide-image {
    margin-left: -242px !important;
    margin-right: 11px !important;
    transition: all 1s !important;
  }
  .search-bar-div {
    top: 60px !important;
  }
  .show-search-mobile .react-autosuggest__input {
    margin-right: 420px !important;
    transition: all 1s !important;
  }
  .icon-remove {
    z-index: 10000000 !important;
  }
  .mobile-similar-items {
    text-align: left !important;
    width: 100%;
    margin-bottom: 0px !important;
  }
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 0px !important;
}
.react-autosuggest__suggestion:focus,
.react-autosuggest__suggestion:hover {
  background-color: #ddd;
}
}

@media screen and (max-width: 768px) {
  .mobile-similar-items {
    text-align: left !important;
    width: 100%;
    margin-bottom: 0px !important;
    margin-top: 20px !important;
  }
  .mobile-filters {
    padding-right: 5px !important;
    border-radius: 4px;
  }
  .header .header-mobile-view .hide-image.hide-arabic {
    margin-right: -242px !important;
    transition: all 1s !important;
  }
  .show-search-mobile.arabic-search .react-autosuggest__input{
    margin-right: 75px !important;
    transition: all 1s !important;
    margin-left: 129px !important;
  }
  .mt-46 {
    margin-top: 26px !important;
  }
  .claimed-list {
    margin-top: 34px !important;
  }
  .redemption-header {
    margin-top: 20px !important;
  }
  .new-card-div:hover{
    box-shadow: none !important;
  }
  .customer-banner {
    width: 100% !important;
    justify-content: center !important;
  }
  .redemptionMobileTitle {
    max-width: 100px !important;
  }
  .floatRight, .profile-content {
    width: 100% !important;
  }
  .login-button {
    max-width: 150px !important;
  }
  .avail-det-ar {
    float: inherit !important;
  }
  .card-show .header-item{
    overflow-x: auto !important;
    white-space: nowrap !important;
    margin-top: 24px !important;
  }
  .header-item-bank{
    overflow-x: auto !important;
    white-space: nowrap !important;
    margin-top: 24px !important;
    padding-bottom: 10px !important;
  }
  .card-show .header-item .nav{
    margin-right: 34px !important;
    margin-left: 0px !important;
    display: inline-block !important;
    /* padding-right: 34px !important; */
  }
  .similarDiv,.similar-merchants-title{
    margin-top: 24px !important;
  }
  .header-search-box{
    flex-direction: column !important;
  }
  .claimed-title, .redemption-title, .app-title, .customer-title, .notification-title  {
    line-height: 18px !important;
  }
  .noResultDiv {
    margin-top: 60px !important;
  }
  .no_wish_img{
    width: 300px;
  }
  .alloffer,.wishlist-div,.ser-mbl {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 0px !important;
}
.react-autosuggest__suggestion:focus,
.react-autosuggest__suggestion:hover {
  background-color: #ddd;
}
.react-autosuggest__suggestions-list {
  padding-left: 20px !important;
}
.search-bar-div .base-container .card-cont{
  width: 105px !important;
}
.headerBoxLeft{
  width: 100% !important;
}
.search-bar-div .base-container .flex-item{
  margin-top: 22px !important;
}
.banner-items-arabic+.page-title{
  padding-right: 15px !important;
}
.banImg{
  max-width: 100% !important;
}
}



@media screen and (min-width: 900px) {
  .header-details,.bc_header{
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .banner-items, .ftr-main, .pag-pro, .wishlist-div{
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .banner-items-arabic{
    padding-right:64px !important;
  }
  .banner-items-arabic{
    padding-left: 64px !important;
    padding-right:64px !important
  }
  /* .banner-items+.page-title{
    padding-left: 30px !important;
  }
  .banner-items-arabic+.page-title{
    padding-left: 30px !important;
  } */
 
  .title-loader,.no-choice{
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  
  .mobile-right {
    display: flex !important;
  }
  .bazaar-header-logo {
    width: 153px;
    height: auto !important;
    margin-left: 15px;
    margin-right: 15px;
  }
  .bazaar-header-logo-hdfc {
    width: 251px;
    height: 42 !important;
    /* margin-left: 15px; */
    margin-right: 10px;
  }
  .banImg {
    max-width: 97% !important;
  }
}



@media screen and (min-width: 1024px) {
  .header-details,.alloffer,.ser-mbl,.title-loader,.bc_header{
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .no-choice{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .banner-items, .ftr-main, .pag-pro, .wishlist-div {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .banner-items-arabic{
    padding-left: 64px !important;
    padding-right:64px !important
  }
  /* .page-title{
    padding-left: 64px !important;
    padding-right: 64px !important;
  } */
  .mobile-right {
    display: flex !important;
  }
  .bazaar-header-logo {
    width: 153px;
    height: auto !important;
    margin-left: 15px;
    margin-right: 15px;
  }
  .bazaar-header-logo-hdfc {
    width: 251px;
    height: 42 !important;
    /* margin-left: 15px; */
    margin-right: 10px;
  }
  .mobile-country-select {
    /* margin-left: 0px !important;
    margin-right: 35px !important; */
  }
  .slick-list{
    overflow:inherit !important;
  }
  .mobile-profile-dropdown {
    /* right: 0px !important; */
  }

  .banImg {
    max-width: 97% !important;
    width: 100% !important;
  }
}



@media screen and (min-width: 1200px) {
  .header-details,.title-loader , .bc_header{
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  /* .page-title{
    padding-right: 51px !important;
  } */
  .banner-items,  .ftr-main, .pag-pro, .wishlist-div {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .no-choice{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .banner-items-arabic{
    padding-left: 64px !important;
    padding-right:64px !important
  }
  .wishlist-div,.alloffer,.ser-mbl {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }

  .mobile-right {
    display: flex !important;
  }
  .bazaar-header-logo {
    width: 153px;
    height: auto !important;
    margin-left: 15px;
    margin-right: 15px;
  }
  .bazaar-header-logo-hdfc {
    width: 251px;
    height: 42 !important;
    /* margin-left: 15px; */
    margin-right: 15px;
  }
  .mobile-country-select {
    /* margin-left: 0px !important;
    margin-right: 35px !important; */
  }
  .banImg {
    max-width: 97.5% !important;
    width: 100% !important;
  }
}


@media screen and (min-width: 1400px) {
  .header-details,.wishlist-div,.alloffer,.ser-mbl,.title-loader,.no-choice, .bc_header{
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
 
  .banner-items, .ftr-main, .pag-pro{
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .banner-items-arabic{
    padding-left: 60px !important;
    padding-right:60px !important
  }
  .filter-div{
    margin-left: 0px !important;
  }
 .hed-tit{
    margin: 0px !important;
    padding-left: 0px !important;
   
  }
  .card_carousel .slick-list{
    padding-left: 0px !important;
  }
  .react-autosuggest__suggestions-container{
    padding-left: 74px !important;
    padding-right: 84px !important;
  }
  .react-autosuggest__suggestions-list {
    padding-left: 0px !important;
  }
  /* .page-title{
    padding-left: 0px !important;
  } */
  .mobile-right {
    display: flex !important;
  }
  .bazaar-header-logo {
    width: 153px;
    height: auto !important;
    margin-left: 15px;
    margin-right: 15px;
  }
  .bazaar-header-logo-hdfc {
    width: 251px;
    height: 42 !important;
    /* margin-left: 15px; */
    margin-right: 15px;
  }
  .slick-list{
    overflow: inherit !important;
  }
  .banImg {
    max-width: 97% !important;
    width: 100% !important;
  }
  .merchant-img-content {
    max-width: 1380px;
    min-width: 1380px;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
}

@media screen and (min-width: 1500px) {
  .header-search-box
  {
    width: 80%;
  }
  /* .base-container-en {
    padding-left: 192px;
  }
  .base-container-ar {
    padding-right: 192px;
  } */
  .header-details,.alloffer, .ser-mbl,.bc_header {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }

  .react-autosuggest__suggestions-list {
    margin-left: 74px !important;
    margin-right: 84px !important;
  }

  .react-autosuggest__suggestions-list {
    padding-left: 0px !important;
  } 

  /* .banner-items, .page-title, .offerDiv, .similarDiv, .ftr-main, .pag-pro, .wishlist-div,.home,.title-loader,.banner-items-arabic{
    padding-left: 240px !important;
    padding-right: 240px !important;
  } */
 .no-choice{
   padding-left: 0px !important;
   padding-right: 0px !important;
 }
  .filter-div,.title-header{
    margin-left: 0px !important;
  }
  .offerlist{
    padding-left: 0px !important;
  }
  /* .hed-tit{
    margin:0px !important;
    padding-left: 0px !important;
  }
  .card_carousel .slick-list{
    padding-left: 0px !important;
  } */
  .mobile-right {
    display: flex !important;
  }
  .bazaar-header-logo {
    width: 153px;
    height: auto !important;
    margin-left: 15px;
    margin-right: 15px;
  }
  .bazaar-header-logo-hdfc {
    width: 251px;
    height: 42 !important;
    /* margin-left: 15px; */
    margin-right: 15px;
  }
  .slick-list{
    overflow: hidden !important;
  }
  .wishlistedDiv {
    padding: 0px 18px !important;
  }
  .headerCountry {
    padding-right: 16px !important;
  }
  .headerLeftCountry {
    padding-left: 16px !important;
  }
  .banImg {
    max-width: 100% !important;
    width: 100% !important;
  }
  .merchant-img-content {
    max-width: 1499px;
    min-width: 1499px;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
}

/* zoom out padding*/
@media screen and (min-width: 900px) {
  .banner-items, .offerDiv, .similarDiv, .ftr-main, .pag-pro, .wishlist-div,.title-loader,.banner-items-arabic, .new-navbar-div, .new_header .top_part{
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
}

@media screen and (min-width: 1400px) {
  .banner-items, .offerDiv, .similarDiv, .ftr-main, .pag-pro, .wishlist-div,.title-loader,.banner-items-arabic, .new-navbar-div, .new_header .top_part{
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  /* .page-title{
    padding-right: 47px !important;
  } */
}
@media screen and (min-width: 1500px) {
  .banner-items, .offerDiv, .similarDiv, .ftr-main, .pag-pro, .wishlist-div,.title-loader,.banner-items-arabic, .new-navbar-div, .new_header .top_part{
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  /* .page-title{
    padding-right: 51px !important;
  } */
}
@media screen and (min-width: 1700px){
  .react-autosuggest__suggestions-container{
    padding-left: 74px !important;
    padding-right: 114px !important;
  }
}
@media screen and (min-width: 1800px){
  .offerDiv,.similarDiv,.banner-items,.ftr-main, .pag-pro,.header-details,.wishlist-div,.alloffer,.ser-mbl,.title-loader,.no-choice, .bc_header, .new-navbar-div{
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .page-title{
    padding-right: 51px !important;
  }
  .react-autosuggest__suggestions-container{
    padding-left: 74px !important;
    padding-right: 150px !important;
  }
  .new_header .my_acc{
    right: 64 !important;
  }
}
@media screen and (min-width: 1900px){
  .offerDiv,.similarDiv,.banner-items,.ftr-main, .pag-pro,.header-details,.wishlist-div,.alloffer,.ser-mbl,.title-loader,.no-choice, .bc_header, .new-navbar-div, .new_header .top_part{
    padding-left: 187px !important;
    padding-right: 187px !important;
  }
  /* .page-title{
    padding-right: 174px !important;
  } */
  .new_header .my_acc{
    right: 187px !important;
  }
  .base-container-en {
    padding-left: 130px;
  }
  .base-container-ar {
    padding-right: 130px;
  }
  .react-autosuggest__suggestions-container{
    padding-left: 197px !important;
    padding-right: 257px !important;
  }
}
@media screen and (min-width: 2400px){
  .offerDiv,.similarDiv,.search-content,.banner-items,.ftr-main, .pag-pro,.header-details,.wishlist-div,.alloffer,.ser-mbl,.title-loader,.no-choice, .bc_header, .new-navbar-div, .new_header .top_part{
    padding-left: 524px !important;
    padding-right: 524px !important;
  }
  /* .page-title{
    padding-right: 511px !important;
  } */
  .react-autosuggest__suggestions-container{
    padding-left: 534px !important;
    padding-right: 590px !important;
  }
  .new_header .my_acc{
    right: 524px !important;
  }
  .base-container-en {
    padding-left: 180px;
  }
  .base-container-ar {
    padding-right: 180px;
  }
}
@media screen and (min-width: 3000px){
  .offerDiv,.similarDiv,.search-content,.banner-items,.ftr-main,.home, .pag-pro,.header-details,.home,.page-title,.wishlist-div,.alloffer,.ser-mbl,.title-loader,.no-choice, .bc_header, .new-navbar-div, .new_header .top_part{
    padding-left: 900px !important;
    padding-right: 900px !important;
  }
  .page-title{
    padding-right: 887px !important;
  }
  .react-autosuggest__suggestions-container{
    padding-left: 980px !important;
    padding-right: 980px !important;
  }
  .new_header .my_acc{
    right: 900px !important;
  }
}
@media screen and (min-width: 3400px) and (max-width: 4000px)  {
  .offerDiv,.similarDiv,.search-content,.banner-items,.ftr-main, .pag-pro,.header-details,.home,.page-title,.wishlist-div,.alloffer,.ser-mbl,.title-loader,.no-choice, .bc_header, .new-navbar-div, .new_header .top_part{
    padding-left: 1350px !important;
    padding-right: 1350px !important;
  }
  .page-title{
    padding-right: 1337px !important;
  }
  .react-autosuggest__suggestions-container{
    
    padding-left: 1360px !important;
    padding-right: 1450px !important;
  }
}
@media screen and (min-width: 4000px){
  .offerDiv,.similarDiv,.search-content,.banner-items,.ftr-main, .pag-pro,.header-details,.home,.page-title,.wishlist-div,.alloffer,.ser-mbl,.title-loader,.no-choice, .bc_header, .new-navbar-div, .new_header .top_part{
    padding-left: 30% !important;
    padding-right: 30% !important
  }
  .react-autosuggest__suggestions-container{
    padding-left: 30% !important;
    padding-right: 32% !important;
  }
  .new_header .my_acc{
    right: 30% !important;
  }
}
@media screen and (min-width: 5000px) {
  .offerDiv,.similarDiv,.search-content,.banner-items,.ftr-main, .pag-pro,.header-details,.home,.page-title,.wishlist-div,.alloffer,.ser-mbl,.title-loader,.no-choice, .bc_header, .new-navbar-div, .new_header .top_part{
    padding-left: 36% !important;
    padding-right: 36% !important
  }
  .react-autosuggest__suggestions-container{
    padding-left: 31.5% !important;
    padding-right: 31.5% !important;
  }  
  .new_header .my_acc{
    right: 36% !important;
  }
}

@media only screen and (max-width: 1024px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  .wishlistedDiv {
    padding-left: 20px !important;
  }
  .location-text {
    width: 100px !important;
  }
  .bazaar-header-logo {
    width: 150px !important;
    height: 40px !important;
  }
  .bazaar-header-logo-hdfc {
    width: 251px;
    height: 42 !important;
  }
  .headerCountry {
    padding-right: 20px !important;
  }
  .headerLeftCountry {
    padding-left: 20px !important;
  }
  .show-search-mobile.arabic-search .react-autosuggest__input {
    margin-right: 20px !important;
    transition: all 1s !important;
    margin-left: 420px;
  }
  /* .banner-items+.page-title{
    padding-left: 30px !important;
  }
  .banner-items-arabic+.page-title{
    padding-left: 30px !important;
  } */
  /* .banner-items+.title-loader{
    padding-left: 15px !important;
  }
  .banner-items-arabic+.title-loader{
    padding-right: 15px !important;
  } */
  .no-choice {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
}

@media screen and (max-width: 1100px) { 
  .location-text {
    width: 100px !important;
  }
}
@media only screen and (min-width: 1200px) { 
  .location-text {
    width: 150px !important;
  }
}

@media only screen and (min-width: 1600px) {
  .merchant-img-content {
    max-width: 1200px;
    min-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
}
@media only screen and (min-width: 1700px) {
  .merchant-img-content {
    max-width: 1686px;
    min-width: 1686px;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

}
@media only screen and (min-width: 1800px) {
  .merchant-img-content {
    max-width: 1799px;
    min-width: 1799px;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
}
@media only screen and (min-width: 1900px) {
  .merchant-img-content {
    max-width: 1780px;
    min-width: 1780px;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
}
@media screen and (min-width: 2400px){
  .merchant-img-content {
    max-width: 1780px;
    min-width: 1780px;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
}
@media screen and (min-width: 3000px){
  .merchant-img-content {
    max-width: 1700px;
    min-width: 1700px;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
}


