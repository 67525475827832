/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Card Carousel
@import "../mixins.scss";

.card_list,
.card-list-merchant {
  cursor: pointer;
  .content {
    margin-bottom: 24px;
  }

  .img-content {
    padding: 8px;
    border-radius: 8px;
    height: 150px;
    border-top: 0.8px solid #f0f0f0;
    border-left: 0.8px solid #f0f0f0;
    border-right: 0.8px solid #f0f0f0;

    .col-lft {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 12px;
      height: 25px;
      background: rgba(229, 229, 234, 0.4);
      border-radius: 14px;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      color: #fff;
      text-align: center;
      letter-spacing: -0.08px;
    }

    .col-rgt {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    .card-info {
      margin-bottom: 13px;
    }
    &:hover {
      box-shadow: 0px 140px 33px rgba(0, 0, 0, 0.09);
    }
  }
  .info-content {
    background: #ffffff;
  //  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.12);
    border: 1px solid #ececec;
    border-radius: 8px;
    padding: 12px 12px 12px 7px;
    top: -12px;

    .info-div {
      color: #252220;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      margin-bottom: 6px;
    }

    .info-desc {
      color: #252220;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 12px;
      min-height: 36px !important;
    }

    .card-det {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;

      /* identical to box height, or 118% */
      letter-spacing: 0.07px;
      color: #616161;
    }

    .expirees-info {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
    }

    .rating {
      display: flex;
      align-items: center;
      .dot {
        width: 3px;
        height: 3px;
        border-radius: 3px;
        background: #c7c7cc;
      }

      .star {
        height: 11px;
        transform: scale(2);
      }

      .rat-tit {
        margin-left: 4px;
      }
    }
    &:hover {
      box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.11);
    }
  }
}

.card_list {
  .content {
    padding-left: 0 !important;
  }
}
.banner-items-arabic{
  padding-right: 15px;
}

.banner-items {
  padding-left: 15px;
  @media screen and (max-width: 1024px) {
    padding-right: 15px;
  }
  img {
    //max-height: 144px;
  }

  @include media-break("md") {
    //padding-left: 60px;

    img {
      // max-height: 240px;
      // height: auto;
    }
  }
}

.card-list-merchant {
  .content {
    width: 260px !important;
    margin-bottom: 24px;
  }

  .img-content {
    padding: 8px;
    border-radius: 4px;
    border-top: 0.8px solid #f0f0f0;
    border-left: 0.8px solid #f0f0f0;
    border-right: 0.8px solid #f0f0f0;
  }

  .info-content {
    box-shadow: none !important;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #ececec;
  }
}
