.cookie-popup-container{
  padding: 25px 39px;
  position: fixed;
  bottom:0;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  width: 100%;
  .close-icon{
    position:  absolute;
    right : 13.61px;
    top : 13.61px;
    width: 18px;
    height: 18px;
    img{
      width: 100%;
    }
  }
  .close-icon:hover{
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
      padding: 32px 20px 31px 20px;
    }
  .cookie-content{
      text-align: left;
      width: 100%;
      @media screen and (max-width: 768px) {
          flex-basis: auto;
      }
      .cookie-title{
          font-family: 'Bliss 2';
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 29px;
          color: #005AB7;;
         margin-bottom: 8px;
         @media screen and (max-width: 768px) {
          font-size: 24px;
          margin-bottom: 12px;
        }
      }
    
      .cookie-desc{
          font-family: 'Bliss 2';
          font-weight: 300;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: 0.15px;
          color: #262626;
          .learn-more{
              color: #003978;
              text-decoration: underline;
          }
          .learn-more:hover{
              cursor: pointer;
          }
      }
  }
  .cookie-buttons{
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .cookie-btn{
          font-family: 'Bliss 2';
          color: #fff;
          border-color: #68b631;
          margin-right: 1em;
          min-width: 125px;
          height: auto;
          white-space: normal;
          word-break: break-word;
          word-wrap: break-word;
          padding: 12px 10px;
          line-height: 1.2;
          font-size: 15px;
          border-radius: 2px;
          @media screen and (max-width: 768px) {
              flex-basis: 100%;
              margin: 0;
              margin-bottom: 16px;
              
          }
      }
      .reject{
          color: #153974;
          border-color: #153974;
          background-color: #FFF
      }
      .accept{
          background-color: #003978;
          border-color: #003978;
          color: #FFFFFF;
      }
  }
  

}

.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/*==== FADE IN UP ===*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}