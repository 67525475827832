/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

.card-remove {
  transform: scale(0, 0);
  transition: transform 0.5s;
}
.loader {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.59);
  z-index: 99999999;
  transition: 0.3s all;
  width: 100vw;
  height: 100vh;
}
.new_loader {
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  justify-content: center; 
}
.new_loader p {
  color: #968d8d;
  font-size: 18px;
  font-weight: 600;
  margin-top: -40px;
}
.no-wishlist{
  margin:108px 0px 129px 0px;

}
.user-image{
  width: 18px;
}
.usernameRight {
  padding-right: 6px;
}
.usernameLeft {
  padding-left: 6px;
}
.page-loader{
  position: relative;
  height: 100vh;
}
.page-loader-wrap {
  position: absolute;
  width: 100%;
  height: 50vh;
  top: 50%;
}
.page-footer-text{
  position: relative;
  width: 100%;
  text-align: center;
}
.page-footer-text h3{
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.24px;
}
.page-footer-text span{
 /* color: #000;
  font-weight: 600; */
}
.page-loader-image {
  width: 100%;
  height: 100%;
}
.page-loader-image img {
  padding-top: 30px;
  margin: 0 auto;
}
.page-counter{
  position: relative;
}
.counter-number{
  position: absolute;
  top: -75px;
  right: 100px;
  font-size: 100px;
  font-weight: 600;
  letter-spacing: -0.24px;
}
.counter-number-right{
  position: absolute;
  top: -75px;
  left: 100px;
  font-size: 100px;
  font-weight: 600;
  letter-spacing: -0.24px;
}
.percentage-symbol {
  position: absolute;
  font-size: 50px;
  letter-spacing: -0.24px;
  font-weight: 600;
  top: -20px;
  right: 55px;
}
.percentage-symbol-right {
  position: absolute;
  font-size: 50px;
  letter-spacing: -0.24px;
  font-weight: 600;
  top: -20px;
  left: 55px;
}
.search-card {
  width: auto !important;
  height: auto !important;
  min-height: 36px;
  line-height: 20px !important;
}