@import "../../assets/scss/variable.scss";

.header-mobile {
  background-color: #004b92;
  width: 100%;
  height: 74px;
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  &.act {
    background-color: #d0e7ff  !important;
    padding: 24px 27px 24px 12px !important;
  }
  .left-part {
    display: flex;
    align-items: center;
    // .sep-line{
    //     height: 37px;
    //     width: 1px;
    //     border-left: 1px solid #D0E7FF;
    //     margin: 0px 9px;

    //     &.act{
    //         border-left: 1px solid #2B5492 !important;
    //     }
    // }
    // .mobileLogo {
    //   img {
    //     // width: 241px;
    //     height: 55px;
    //   }
    //   @media screen and (max-width: 350px) {
    //     img {
    //       width: 200px !important;
    //       height: 43px  !important;
    //     }
    //   }
    // }
  }
  .right-part {
    display: flex;
    align-items: center;
    .search-ic {
      display: flex;
      align-items: center;
      padding: 0px 12px;
      height: 100%;
      &.act {
        background: rgba(255, 255, 255, 0.3);
        box-shadow: inset 0px -3px 0px #ffffff;
      }
    }
    .ham-menu {
      margin-left: 12px;
    }
  }
}

.new-hid-menu {
  height: 0px;
  visibility: hidden;
  // transition: all 0.3s ease;
  // opacity: 0;
  position: absolute;
}

.none {
  display: none;
}

.new-hid-menu-opened {
  width: 100%;
  position: absolute;
  transition: all 0.5s ease;
  background-color: #ffffff;
  height: calc(100vh - 65px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  .top-part {
    background-color: #d0e7ff;
    &.act {
      padding-top: 20px;
      padding-bottom: 25px;
    }
    .my-acc {
      display: flex;
      align-items: center;
      padding: 2px 20px 18px 12px;
      .cont-part {
        font-weight: 500;
        font-size: 20px;
        color: #000811;
        padding-left: 16px;
      }
    }
    .login-sec {
      margin: 0px 20px 0px 14px;
      text-align: center;
      background: #005ab7;
      border: 1px solid #1d86ff;
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .loc-section {
    margin: 0px 16px;
    padding-bottom: 24px;
    font-weight: 400;
    font-size: 18px;
    color: #001122;
    border-bottom: 1px solid #e2e2e2;
    .each {
      padding-top: 24px;
      &.act {
        display: flex;
        justify-content: space-between;
      }
      .sub-part {
        font-weight: 500;
        font-size: 14px;
        color: #727272;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }
  .allow-scroll {
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    .prof-section {
      margin: 0px 16px;
      padding-bottom: 24px;
      padding-top: 0px;
      font-weight: 400;
      font-size: 18px;
      color: #001122;

      &.act {
        border-bottom: 1px solid #e2e2e2;
      }
      .each {
        padding-top: 24px;
      }
    }

    .logout-sec {
      margin: 0px 16px;
      padding-top: 24px;
      font-weight: 500;
      font-size: 18px;
      color: #e30613;
      display: flex;
      justify-content: space-between;
    }

    .pow-icon {
      width: 100%;
      position: fixed;
      left: 50%;
      bottom: 0px;
      transform: translate(-50%, -10%);
      margin: 0 auto;
      text-align: center;
      background: #f9f9f9;
      // border: 1px solid black;
    }
    .pow-icon2 {
      display: flex;
      justify-content: center;
      padding-bottom: 15px !important;
      padding-top: 60px !important;
      margin-bottom: 26px;
      background: #f9f9f9;
    }
  }
}
@media screen and (min-height: 700px) {
  .mdl-fott {
    margin-top: 18px;
  }
}
@media screen and (max-height: 700px) {
  .mdl-fott {
    padding: 0 5px 10px 5px;
  }
  }

.cpy-text {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #3f3f3f;
  margin: 14px 30px 9px 30px;
}

.new_header {
  .top_part {
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 10px;
    @media screen and (min-width: 1400px) {
      padding-left: 60px;
      padding-right: 60px;
    }
    @media screen and (min-width: 1500px) {
      padding-left: 240px;
      padding-right: 240px;
    }
    .cselect {
      .ltext {
        font-weight: 500;
        font-size: 15px;
      }
    }
    .react-autosuggest__input {
      // color: #ffffff;
      font-size: 15px;
      font-weight: normal;
      font-family: "Noto Sans" !important;
    }
    .react-autosuggest__input::placeholder {
      color: if($clientName == "hdfc", white  !important, grey !important);
      opacity: 1;
      // font-weight: 500;
    }
  }
  .my_acc {
    right: 64px;
    @media screen and (min-width: 1400px) {
      right: 64px;
    }
    @media screen and (min-width: 1500px) {
      right: 64px;
    }
  }
}

.bot-line {
  border-bottom: 1px solid #e3e3e3;
  position: relative;
  display: flex;
  width: 94%;
  left: 30px;
  @media screen and (min-width: 933px) {
    left: 64px;
    width: 91.5%;
  }
  @media screen and (min-width: 1200px) {
    left: 64px;
    width: 90%;
  }
  @media screen and (min-width: 1300px) {
    left: 64px;
    width: 90.5%;
  }
  @media screen and (min-width: 1400px) {
    left: 64px;
    width: 91%;
    width: 90.5%;
  }
  @media screen and (min-width: 1500px) {
    left: 64px;
    width: 92%;
    width: 91.5%;
  }
  @media screen and (min-width: 1600px) {
    left: 64px;
    width: 91%;
    width: 90.5%;
  }
  @media screen and (min-width: 1700px) {
    left: 64px;
    width: 93%;
    width: 92.5%;
  }
  @media screen and (min-width: 1800px) {
    left: 64px;
    width: 93.5%;
    width: 93%;
  }
  @media screen and (min-width: 1900px) {
    left: 187px;
    width: 80%;
  }
  @media screen and (min-width: 2400px) {
    left: 524px;
    width: 61.5%;
    width: 61%;
  }
  @media screen and (min-width: 3000px) {
    left: 15%;
    width: 65%;
  }
}
.cheese {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  .menu-item-mobile {
    padding: 10px 10px 0 10px;
  }
}
.inner-cheese {
  height: max-content;
}
.sticky-desktop-nav {
  background-color: if(
    $clientName == "hdfc",
    #fff !important,
    if($clientName == "maya", #fff !important, inherit)
  );
  margin-top: if($clientName == "hdfc", -3px, -3px);
  width: 100% !important;
}
.sticky-mbl {
  background-color: if(
    $clientName == "hdfc",
    #fff !important,
    if($clientName == "maya", #fff !important, inherit)
  );
  margin-top: -10px;
}
.sticky-mbl-inv {
  background-color: if(
    $clientName == "hdfc",
    #fff !important,
    if($clientName == "maya", #fff !important, inherit)
  );
  margin-top: -10px;
  height: 100px;
}
.sticky-mbl-nav {
  background-color: if(
    $clientName == "hdfc",
    #fff !important,
    if($clientName == "maya", #fff !important, inherit)
  );
  //margin-top: 6px;
}

#header-content {
  .bazaar-header-smartbuy {
    max-width: 176px;
    max-height: 42 !important;
    margin-left: 14px;
    margin-right: 15px;
    img {
      min-width: 80px;
      height: 100%;
    }
  }
}
.header {
  .mt-15 {
    margin-top: 0px !important;
  }
  .navbar-div-mbl {
    height: 66px;
    display: flex;
    align-items: flex-end;
    // padding-left: 15px;
  }
}

.header-mobile-view {
  &.hdfc {
    background-color: #004b92;
    padding-left: 15px;
    padding-top: 0px !important;
    height: 70px !important;
  }
  .hdfc-head {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    .logo-hdfc {
      display: grid;
      grid-template-areas: "img img";
      align-items: center;
      max-width: 240px;
      max-height: 42px;
      .hdfc-mbl-logo {
        width: 25px;
        height: 25px;
        margin-right: 7px;
      }
      .smartbuy-logo {
        width: 100%;
        height: 100%;
        padding-left: 10px;
        padding-right: 10px;
        border-left: 1px solid #fff;
      }
    }
    .right {
      display: flex;
      max-width: 50%;
      width: 200px;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.header-details {
  padding-left: 0px !important;
}

div.search-box {
  position: absolute;
  left: 0px;
  width: 90vw;

  @keyframes slideinput {
    from {
      display: 0%;
    }
    to {
      width: 100%;
    }
  }
  .input-box {
    display: flex;
    flex-direction: row;
    .react-autosuggest__container {
      width: 100%;
      position: relative;
      animation: slideinput 2s linear;
    }
    .close-icon {
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      svg {
        path {
          stroke: #000;
        }
      }
    }
  }
}
