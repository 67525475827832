/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/
@import "../variable.scss";
// Filter Modal

.filter-modal {
  width: 100%;
  height: 100%;

  .main-header {
    border-bottom: 1px solid #e7e7e7;

    .filter-header {
      padding: 14px 28px;
      @media screen and (max-width: 768px) {
        align-items: center;
        justify-content: space-between;
      }
      svg {
        cursor: pointer;
        path {
          stroke: $redeem-black-color;
        }
      }
    }
    .filter-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 23px;
      color: #000;
      font-family: if(
        $clientName == "adib",
        "Bliss_Bold" !important,
        "Noto Sans" !important
      );
      width: 91%;
    }
  }
  .filter-modal-content {
    padding: 35px 50px;
  }
  .filter-item-title,
  .item-title {
    font-size: 17px;
    margin-bottom: 28px !important;
    color: #000;
    font-weight: 600;
    font-family: if(
      $clientName == "adib",
      "Bliss_Bold" !important,
      "Noto Sans" !important
    );
  }
  .item-title {
    font-size: 15px !important;
    font-weight: 500;
    font-family: if(
      $clientName == "adib",
      "Bliss_Medium" !important,
      "Noto Sans" !important
    );

    &.clear {
      text-decoration: underline;
      margin-bottom: 0 !important;
      font-weight: 600;
      font-family: if(
        $clientName == "adib",
        "Bliss_Bold" !important,
        "Noto Sans" !important
      );
    }
  }
  .filter-type {
    margin-top: 28px;
    border-bottom: 1px solid #e7e7e7;
  }
  .filter-button {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 8px;
    vertical-align: middle;
    text-align: center;
    padding: 5px 18px;
    font-size: 13px;
    line-height: 18px;
    cursor: pointer;
  }
}

.filter-modal-mbl {
  width: 90%;
  height: 100%;
  margin: 0 15px;
  .modal-mbl-cnt {
    width: 100%;
    font-family: "Noto Sans";
    font-style: normal;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    .main-header-mbl {
      display: flex;
      align-items: center;
      margin-top: 21px;
      .mdl-mbl-tit {
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        letter-spacing: 0.35px;
        color: #000000;
        flex-grow: 1;
      }
      .mdl-cls-mbl {
        width: 30px;
        height: 30px;
        background: #e7e7e7;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .filter-title-mbl {
      font-family: "Noto Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
      color: #989898 !important;
    }
    .item-cnt {
      margin-top: 17px;
      .item-title-mbl {
        font-family: "Noto Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 22px;
        letter-spacing: -0.41px;
        color: #000000;
      }
    }

    .mdl-shw-btn {
      width: 100%;
      padding: 10px 0;
      background: #000;
      border-radius: 4px;
      font-family: "Noto Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 23px;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
    }

    .clr-cnt {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      width: 100%;
      margin: 16px 0 18px 0;
      .item-clr-mbl {
        font-family: "Noto Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #727272 !important;
      }

    }
  }
}
