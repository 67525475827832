.taste-modal {
  font-family: "Inter";

  @media (max-width: 768px) {
    overflow-y: auto;
    height: inherit;
    padding-bottom: 13rem;
  }

  .top-sec {
    display: flex;
    justify-content: space-between;

    .close-btn {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      top: 10px;
      right: 17px;
      border: 1px solid #e1e1e1;
      box-sizing: border-box;
      border-radius: 50%;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      cursor: pointer;

      @media (max-width: 768px) {
        left: 20px !important;
        top: 30px !important;
        background-color: #e5e7eb;
        position: relative;
      }
    }

    .tskp {
      display: none;

      @media (max-width: 768px) {
        display: block;
        margin-top: 30px !important;
        margin-right: 20px !important;
        border-radius: 50px !important;
        width: 60px !important;
        height: 32px !important;
        background-color: #e5e7eb !important;
        color: #111827 !important;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .cat-section {
    .cat-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      text-align: center;
      color: #000000;
      padding: 50px 150px 10px 150px;

      @media (max-width: 768px) {
        padding: 35px 37px 10px 37px !important;
      }
    }

    .cat-sub {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #6b7280;
      padding-bottom: 20px;

      @media (max-width: 768px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #4b5563;
      }
    }

    .cat-list {
      display: flex;
      flex-wrap: wrap;
      padding-left: 40px;

      @media (max-width: 768px) {
        padding-left: 0px;
        justify-content: center;
      }

      .cat-item {
        position: relative;
        border: 1px solid #e5e7eb;
        border-radius: 16px;
        margin-top: 20px;
        margin-right: 20px;

        @media (max-width: 768px) {
          background-color: #ffffff;
          margin: 15px 15px 0px 15px;
        }

        &.selected {
          border: 1px solid #ff9231;
        }

        .container-checkbox .checkmark:after {
          border: solid #ff922f;
          border-width: 0 2px 2px 0;
          top: 3px;

          @media (max-width: 768px) {
            left: 5px;
            top: 1px;
          }
        }

        .container-checkbox input:checked ~ .checkmark {
          border: 1px solid #ff9231;
        }

        .container-checkbox .checkmark {
          background-color: #ffffff !important;

          @media (max-width: 768px) {
            width: 16px !important;
            height: 16px !important;
            top: 10px !important;
          }
        }

        .container-checkbox {
          @media (max-width: 768px) {
            padding-left: 18px !important;
          }
        }

        img {
          height: 44px;
          padding: 0px 80px;
          margin-top: 40px;

          @media (max-width: 768px) {
            padding: 0px 50px;
            height: 35px;
            margin-top: 32px;
          }
        }

        .cat-name {
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #111827;
          padding-top: 5px;
          padding-bottom: 40px;

          @media (max-width: 768px) {
            padding-bottom: 17px;
            font-size: 14px;
            padding-top: 15px;
          }
        }
      }
    }

    .warning-text {
      font-size: 14px;
      font-weight: 400;
      color: #6b7280;
      padding-top: 24px;
      padding-left: 40px;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .bot-sec {
      display: flex;
      justify-content: space-between;
      padding: 50px 30px 30px 40px;
      align-items: center !important;

      @media (max-width: 768px) {
        padding: 18px 15px 25px 15px !important;
        background-color: #ffffff !important;
        position: absolute;
        bottom: 0;
        width: 100%;
        box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
        z-index: 1000;
      }

      .cat-sel {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        color: #6b7280;
        padding-top: 20px;

        @media (max-width: 768px) {
          display: none;
        }
      }

      .button-sec {
        display: flex;
        padding-top: 10px;

        @media (max-width: 768px) {
          width: 100%;
        }

        .btn {
          width: 135px;
          background-color: #ffffff;
          color: #ff9231;

          &.nxt {
            @media (max-width: 768px) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .sub-section {
    .back-btn {
      color: #374151;
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      display: flex;
      padding: 10px 20px 0px 20px;
      align-items: center;

      svg {
        margin-right: 10px;

        path {
          fill: #000 !important;
        }
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .cat-title {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      color: #000000;
      margin-top: -20px;

      @media (max-width: 768px) {
        padding-top: 35px;
        margin-top: 0px;
      }
    }

    .cat-sub {
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      color: #6b7280;
      padding-top: 10px;

      @media (max-width: 768px) {
        font-size: 14px;
        color: #4b5563;
        padding-top: 8px;
      }
    }

    .nav-tab {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding-top: 20px;

      .nav-item {
        border-radius: 50px;
        padding: 5px 20px;
        margin: 10px;
        border: 1px solid #e5e7eb;
        font-size: 16px;
        font-weight: 400;
        background-color: #ffffff;

        @media (max-width: 768px) {
          margin: 7px 7px 0px 0px;
          font-size: 14px;
          font-weight: 500;
          padding: 8px 14px;
        }

        &.selected {
          color: #ffffff;
          background-color: #ff9231;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }

    .tag-section {
      padding: 20px 50px;

      @media (max-width: 768px) {
        padding: 30px 20px;
      }

      .tag-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #111827;
        padding-bottom: 15px;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }

      .tags {
        display: flex;
        flex-wrap: wrap;

        .tag-item {
          margin: 10px 10px 0px 0px;
          padding: 5px 30px;
          border: 1px solid #e5e7eb;
          border-radius: 4px;
          font-size: 14px;

          @media (max-width: 768px) {
            margin: 7px 7px 0px 0px;
            padding: 8px 14px;
            background-color: #ffffff;
          }

          &.selected {
            background-color: #fef3c7;
            border: 1px solid #ff9231;
          }
        }
      }

      .warning-text {
        color: #6b7280;
        font-size: 14px;
        padding-top: 25px;

        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }

    .merchants-section {
      padding: 0px 50px 20px 50px;

      @media (max-width: 768px) {
        padding: 10px 20px 0px 20px;
      }

      .merchant-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #111827;
      }

      .merchants {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 20px 130px;

        @media (max-width: 768px) {
          padding: 25px 30px 0px 30px;
        }

        .merchant-item {
          border: 1px solid #e5e7eb;
          border-radius: 50%;
          padding: 15px;
          margin: 10px;

          &:nth-child(odd) {
            margin-bottom: 30px;
          }

          &:nth-child(even) {
            margin-top: 30px;
          }

          &.selected {
            background-color: #fef3c7;
            border: 1px solid #ff9231;
          }

          img {
            width: 45px;
            height: 43px;
          }

          @media (max-width: 768px) {
            border: 1px solid #e5e7eb;
            background-color: #ffffff;
            padding: 15px;
            margin: 5px;
          }
        }
      }
    }

    .bot-sec {
      display: flex;
      justify-content: end;
      padding: 20px 20px 30px 20px;
      align-items: center !important;

      @media (max-width: 768px) {
        padding: 18px 15px 25px 15px !important;
        background-color: #ffffff !important;
        position: absolute;
        bottom: 0;
        width: 100%;
        box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
        z-index: 1000;
      }

      .button-sec {
        display: flex;
        padding-top: 10px;

        .btn {
          width: 135px;
          background-color: #ffffff;
          color: #ff9231;

          &.nxt {
            @media (max-width: 768px) {
              width: 100%;
            }
          }
        }

        @media (max-width: 768px) {
          width: 100%;
          padding-top: 0px;
        }
      }
    }
  }
}

.ReactModal__Content::-webkit-scrollbar-thumb {
  @media (min-width: 769px) {
    background: #242457;
    border-radius: 6px;
    width: 6px;
    margin-left: 5px;
  }
}

.ReactModal__Content::-webkit-scrollbar {
  @media (min-width: 769px) {
    width: 6px;
    background-color: #d6caca;
  }
}

.taste-modal::-webkit-scrollbar-thumb {
  @media (max-width: 768px) {
    background: #242457;
    border-radius: 6px;
    width: 6px;
    margin-left: 5px;
  }
}

.taste-modal::-webkit-scrollbar {
  @media (max-width: 768px) {
    width: 6px;
    background-color: #d6caca;
  }
}

.form-group {
  // margin: 
  // position: absolute;

  @media (max-width: 768px) {
    margin: 2px 7px 5px 0px !important ;
  }
}

.ReactModal__Content {
  @media (max-width: 768px) {
    width: 100% !important;
    height: 100% !important;
    background-color: #F9FAFB !important;
    border-radius: 0px !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }
}

.skp {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.loader-comp {
  padding: 0px 50px;
  @media (max-width:768px) {
    padding: 25px 45px 0px 45px;
}
}

.success-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;

  .sm-content {
    position: relative;
    width: 100%;
    max-width: 580px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 70px 65px;
    color: #000;

    @media (max-width: 768px) {
      padding: 30px 25px;
      margin: 0px 20px;
      max-width: 100%;
    }

    .close-btn {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 10px;
      right: 17px;
      box-sizing: border-box;
      border-radius: 50%;
      cursor: pointer;
      background-color: #f3f4f6;

      img {
        width: 32px;
        height: 32px;

        @media (max-width: 768px) {
          width: 24px;
          height: 24px;
        }
      }
    }

    .gif-content {
      display: flex;
      justify-content: center;

      .tc-success {
        width: 200px;
        height: 145px;

        @media (max-width: 768px) {
          width: 135px;
          height: 100px;
        }
      }

      .tc-skip {
        margin-bottom: 15px;
        width: 85px;
        height: 85px;

        @media (max-width: 768px) {
          width: 72px;
          height: 72px;
        }
      }
    }

    .main-content {
      padding-top: 15px;
      font-size: 24px;
      font-weight: 600;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    .sub-content {
      padding-top: 15px;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        padding-top: 8px;
      }
    }
  }
}
