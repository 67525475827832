.wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .main-img {
        svg {
            @media screen and (max-width: 768px) {
                width: 290px;
                height: 180px;
            }
        }
    }
    .att-tit {
        font-family: "Noto Sans";
        font-size: 30px;
        line-height: 41px;
        letter-spacing: -0.24px;
        color: #000000;
        font-style: normal;
        font-weight: 500;
        margin-top: 42px;
        max-width: 600px;
        text-align: center;
        @media screen and (max-width: 900px) {
            font-size: 18px;
            font-weight: 700;
            line-height: 25px;
            margin-top: 15px;
            max-width: 300px;
        }
    }
    .att-des {
        font-family: "Noto Sans";
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        letter-spacing: -0.24px;
        color: #727272;
        margin-top: 8px;
        max-width: 600px;
        text-align: center;
        @media screen and (max-width: 900px) {
            font-size: 14px;
            line-height: 19px;
            max-width: 300px;
        }
    }
}