/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Search Component
@import "../mixins.scss";
@import "../variable.scss";

@font-face {
  font-family: 'Noto_Sans_Bold';
  src: url('../../fonts/NotoSans-ExtraBold.woff2') format('woff2'),
       url('../../fonts/NotoSans-ExtraBold.woff') format('woff');
}
@font-face {
  font-family: 'Noto_Sans_CondBold';
  src: url('../../fonts/NotoSans-SemiCondensedSemiBold.woff2') format('woff2'),
       url('../../fonts/NotoSans-SemiCondensedSemiBold.woff') format('woff');
}

.search-bar-div {
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 64px;
  transition: opacity 250ms ease-in-out;
  z-index: 100;
  display: none;
  @media (min-width:934px) {  
    display: unset !important;
    }
  .search-content {
    position: absolute;
    overflow: hidden;
    transition: all 1s !important;
    background: #fff !important;
    width: 100%;
    left: 0;
    z-index: 100;
    padding: 20px;
    @include media-break("lg") {
      padding: 20px 240px 40px 240px;
    }
  }
  .base-container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    .main-title {
      font-family: if($clientName == 'adib', 'Bliss_Bold' !important , 'Noto_Sans_Bold' !important);
      font-weight: 900;
      font-size: 18px;
      line-height: 32px;
      color: #000811;
      // text-align: left !important;
      @include media-break("lg") {
        font-size: 40px;
        line-height: 52px;
      }
    }
    .location {
      text-transform: capitalize;
      text-decoration: underline;
    }
    .card-cont {
      font-size: 13px;
  //    width: 110px;
      padding: 0 15px;
      line-height: 18px;
      height: 36px;
      letter-spacing: -0.24px;
      color: #001122;
      flex-direction: column;
      margin-right: 12px;
      margin-bottom: 12px;
   /*   background: #edffff; */
      border-radius: 4px;
      font-weight: 600;
    }
    .search-card {
      width: auto !important;
      height: auto !important;
      min-height: 36px
    }
    .lef-col {
      flex-direction: column;
    }
    .flex-item {
      flex-wrap: wrap;
      margin-top: 36px;
    }
    .col-rht {
      text-align: left;
    }
    .recent-title{
      font-family: if($clientName == 'adib', 'Bliss 2' !important , 'Noto_Sans_CondBold' !important);
      color: if($clientName == 'adib', #262626 !important , #000811 !important);
    }
    .recent-search {
      font-size: 13px;
      line-height: 26px;
      text-transform: capitalize;
      color: if($clientName == 'adib', #787b86, #001122);

      @include media-break("lg") {
        font-size: 15px;
        line-height: 28px;
      }
    }
  }
}

.header-search-box { 
  display: flex;
  justify-content: space-between;
}
.headerBoxLeft {
  width: 70%;
}
