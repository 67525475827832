/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Breadcurmb Style
.bcm {
  align-items: flex-start;
  display: flex;
  // flex-wrap: wrap;
  min-height: 18px;
  width: 100%;

  &-spl,
  &-lnk {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-decoration: none;
    text-transform: capitalize;
    white-space: nowrap;
  }

  &-spl {
    color: #fff;
    padding: 0 4px;
    // svg{ path {
    //   fill: #ffffff;
    // }

    // }
  }

  &-lnk,
  &-lnk:visited {
    color: #fff;
  }

  &-lnk:hover,
  &-lnk:active {
    color: #fff;
  }

  &-lnk:focus {
    color: #fff;
    outline: none;
  }
  &-backtoresult{
    // color: #ffffff;
    cursor: pointer;
    margin-left: 10px;
  } 
  &-backtoresult-merchant{
    cursor: pointer;
    margin-left: 0px !important;
  }
}
