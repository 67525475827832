/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Mechant Details
@import "../mixins.scss";
@import "../variable.scss";

.merchant-details {
  width: 100%;
  height: 100%;
  margin-bottom: 63px;
  padding: 0 0px;

  @include media-break("md") {
    // padding: 0 60px;
  }

  @media(max-width:600px){
    margin-bottom: 20px;
  }

  .main-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: stretch;
    // margin-top: 18px;

    .wrap {
      flex-wrap: wrap;
    }
    .merchant-details-img-content {
      max-height: 187px !important;
      width: 300px;
      //padding: 10px 57px 10px 57px ;
      border-radius: 8px;
      position: relative;
      // background-position: center;
      // background-size: cover;
      // background-repeat: no-repeat;
      border-radius: 8px;
    }
    .col-lft-desk {
      //display: inline;
      align-items: center;
      justify-content: center;
      background: #303133 ;
      border-radius: 14px;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 15px;
      color:  #FFFFFF;
      text-align: center;
      letter-spacing: -0.08px;
      padding: 5px 12px;
      position: absolute;
      top: 10px !important;
      margin-left: 8px !important;
    }

    .col-lft-desk-ar {
      background: #303133 !important;
      margin-left: 0px !important;
      margin-right: 11px !important;
    }
    .merchant-details-img-content-mbl {
      height: 80px !important;
      width: 127.79px;
      // padding: 10px 57px 10px 57px ;
      border-radius: 4px;
      position: relative;
      border: 1px solid #ECECEC;

      .col-lft {
        //display: inline;
        align-items: center;
        justify-content: center;
        background: #000000;
        border-radius: 14px;
        font-style: normal;
        font-weight: 600;
        font-size: 8px;
        line-height: 8px;
        color: #FFFFFF;
        text-align: center;
        letter-spacing: -0.08px;
        padding: 5px 6px;
        position: absolute;
        top: 4px !important;
        margin-left: 4px !important;
      }

      .col-lft-ar {
        margin-left: 0px !important;
        margin-right: 3px !important;
      }
    }
    .col-left {
      width: 100%;
      height: 100%;

      .col-rht {
        margin-bottom: 40px;
      }
      .flex-between{
        justify-content: space-between;
        align-items: center;
      }
      .merchant-details-breadcrumb{
        margin-bottom: 46px;
      }
      .detail-bg{
        position:absolute;
        height: 100%;
      }
      .detail-bg-ar{
        right: auto !important;
        left: 20px !important;
        width: 177px;
        height: 167px;
        top: 117px;
      }
      .merchant-img-div {
        background-color: #000000;
      }
      .merchant-img-content {
        position: relative;
        padding: 26px 0px 48px 80px;
        margin-bottom: 10px;

        .settings-icn {
          padding-right: 60px;
          svg {
            path {
              stroke: #fff;
            }
          }
        }

        .settings-icn-ar {
          svg {
            path {
              stroke: #fff;
            }
          }
        }

        &.mbl {
          padding: 28px 16px;

          .settings-icn-mbl {
            svg {
              path {
                stroke: #fff;
              }
            }
          }
        }

        .col-header-info {
          display: inline-flex;
          border-radius: 8px;
          vertical-align: middle;
          align-items: center;
        }

        .card-info {
          width: 60%;
          align-self: center;
          margin-left: 8px;
          margin-top: 10px;
          
          &.mbl {
            align-self: unset;
            margin-top: 0;
          }

          @include media-break("md") {
            margin-left: 35px;
            margin-top: 0;
          }
        .pt-18{
          padding-top: 18px;
          margin-top: 18px !important;
        }
        .footlin{
          border-top: 1px solid #727272;
        }
        .taste-match-score{
          font-weight: 600;
          font-size: 15px;
          line-height: 0px;
          letter-spacing: 0.07px;
        }       
        .card-footer-taste-div{
          flex-direction: column;
          align-items: center;
          width: 85px;
         
        }   
        .recommend-text{
          font-size: 11px;
          line-height: 15px;
          font-weight: 400;
          text-align: center;
          letter-spacing: 0.01em;
          text-transform: capitalize;
          color: #FFFFFF;
        }
      
        .reason-text{
          width: 510px;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.24px;
          padding-left: 24px;
          margin-left: 24px;
          border-left: 1px solid #E3E3E3;
          color: #FFFFFF;

          @media(max-width:600px){
            font-size: 14px;
            line-height: 19px;
            padding-left: 16px;
            margin-left: 16px;
            letter-spacing: -0.24px;
          }
        }
        .remove-taste-score{
          padding: 0;
          margin: 0;
          border: none;
        }
        .reason-text-ar{
          padding-left: 0px !important;
          margin-left: 0px !important;
          border-left: none !important;
          padding-right: 24px;
          margin-right: 24px;
          border-right: 1px solid #ECECEC;
          
          @media(max-width:600px){
            padding-right: 16px;
            margin-right: 16px;
          }
        }
        .align-items-center{
          align-items: center;
        }
        .avail-info {
            display: inline-block;
            flex-direction: row;
            align-items: center;
            padding: 5px 12px;
            margin-bottom: 8px;
            background: rgba(229, 229, 234, 0.4);
            border-radius: 14px;
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            color: #fff;
            letter-spacing: -0.08px;

            &.mbl {
              width: 50px;
              font-size: 9px;
              font-weight: 400;
              padding: 0 0 0 12px;
            }
          }

          .item-title {
            font-style: normal;
            // color: #ffffff;
            margin-bottom: 12px !important;
            font-family: 'Noto Sans';
            font-weight: 400;
            font-size: 30px;
            line-height: 41px;
            letter-spacing: -0.24px;
            &.mbl {
              font-weight: 500;
              font-size: 20px;
              line-height: 27px;
              letter-spacing: -0.24px;
              
            }
          }

          .card-details {
            display: flex;
            .site {
              padding-right: 13px;
            }
            .divider {
              width: 2px;
              background-color: #fff;
              height: 2em;
              position: relative;
              top: 8px;
            }
            .country-select-merchant {
              .slt__value-container
              .country-lbl {
                margin: 0 0 0 2px !important;
                font-weight: 600;
              }
            }
          }

          .website-content {
            max-width: 122px;
          }

          .ellipsis {
            display: inline-block;
            max-width: 260px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @media(max-width:600px){
              max-width: 180px !important;
            }
          }

          .website {
            color:#F58532;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.5px;
            // color: #f3f3f3;
            border-bottom: 1px solid #ececec;
          //  margin-bottom: 10px;
            position: relative;
            direction: ltr;
            cursor: pointer;

            &.mbl {
              border-bottom: none;
              font-size: 10px;
              margin-left: 0px;
              align-items: unset;
          //    margin-top: 3px;
            }

            @include media-break("lg") {
              border-bottom: none;
              margin-bottom: 0;
              // border-right: 1px solid #ececec;
            }
            @media(max-width:600px){
              line-height: 24px !important;
            }
          }

          .item-des {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #ffffff;
            border-right: 1px solid #E3E3E3;
       //     margin-bottom: 8px !important;
            padding-right: 6px;
            margin-right: 6px;
            white-space: nowrap;
            &.mbl {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
            }
            @media(max-width:768px){
              border-right: none;
            }
          }
          .item-des-port{
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #ffffff;
            // border-right: 1px solid #E3E3E3;
       //     margin-bottom: 8px !important;
            padding-right: 6px;
            margin-right: 6px;
            white-space: nowrap;
            &.mbl {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
            }
            @media(max-width:768px){
              border-right: none;
            }
          }

          .flexPad {
            @media screen and (min-width: 768px) {
              display: flex;
            }
            @media screen and (max-width: 768px){
              display: flex;
              flex-direction: column;
            }
          }
        }

        .card-info-ar {
          width: 62%;
          align-self: center;
          margin-right: 25px;
          margin-top: 10px;

          @include media-break("md") {
            margin: 0 46px 0 0 !important;
          }

          .website {
            border-bottom: none !important;
             &.mbl {
               margin-right: 3px;
             }
           }
 
           .item-des {
             border-left: 1.3px solid #ffffff;
             border-right: none !important;
             padding-left: 6px;
             margin-left: 6px;
             padding-right: 0px !important;
             margin-right: 0px !important;

             @media(max-width:600px){
              display: block;
              border-left: none;
              padding: 0px 5px 0px 0px !important;
              margin: 0px !important;
            }
           }

          .flex-row_rev{
            flex-direction: row-reverse;
          }
        }

        .mbl-list {
          .rat-tit {
            font-weight: 600;
            font-size: 13px;
            line-height: 13px;
            letter-spacing: 0.07px;
            text-transform: uppercase;
            margin-top: 1px;
            color: #F3F3F3;
          }

          .dot {
            width: 3px;
            height: 3px;
            border-radius: 3px;
            background: #c7c7cc;
          }
        }

        .rating {
          height: 36px;
          border-right: 1px solid #fff;
          padding-right: 10px;

          @include media-break("lg") {
            padding-right: 0;
          }

          .rat-tit {
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 0.07px;
            text-transform: uppercase;
            color: #ffffff;
          }

          .des-info {
            font-size: 11px;
            line-height: 15px;
            letter-spacing: 0.01em;
            text-transform: capitalize;
            color: #f3f3f3;
          }

          &.last-col {
            border-right: none;
            padding: 0 10px;

            @include media-break("lg") {
              margin-left: 36px;
              padding: 0 0;
            }
          }

          &.center-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 10px;
            border-right: 1px solid transparent;

            @include media-break("lg") {
              padding: 0 0;
            }
          }
        }
        .align-card{
          .card-section-top{
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          .card-section-bottom{
            .card-info {
              width: 100%;
              margin-left: 0px;
              &.card-info-ar{
                margin-right: 0px;
              }
              .card-footer-taste-div{
                width: 40%;
                @media screen and (max-width: 768px) {
                  width: auto;
                }
              }
            }
          }
        }
      }

      .merchant-img-content-ar {
        padding: 23px 32px 48px 24px !important;
      }
      .offerDiv{
        padding-left: 64px;
        padding-right: 24px;

        @include media-break("xxxxl") {
          margin-left: 10px;
        }
        // @media(max-width:600px){
        //   margin-left: 10px;
        // }
        @media screen and (max-width: 933px){
          padding-left: 24px;   
        }
      }
      .offer-info {
        // width: 97% !important;
        margin-top: 36px;
        border-radius: 8px;
        cursor: default;
        padding:30px 24px;
        // margin-left: 30px;
        @include media-break("xxl") {
          // width: 97% !important;
          padding: 28px;
          margin-left: 27px;
        }
        @include media-break("xxxl") {
          // width: 96% !important;
          padding: 30px;
          margin-left: 27px;
          margin-right:30px;
        }
        @media(max-width:600px){
          padding:23px 16px 12px 16px;
          margin-left: 5px;
          margin-right: 5px;
          margin-top: 20px;
        }
        @media(max-width:768px){
          margin-top: 20px;
        }
        @media (min-width:1400px){
          margin-left: 0px !important;
        }
      }
    }

    .info-offer{
      margin-top: 24px;
      word-break: break-word;
    }
    .best-offer-header{
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.24px;
      color: #000811;
      word-break: break-word;
    }
    .col-right {
      margin-top: 10px;
      margin-left: 0;
      // display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .offer-title {
        font-weight: 600;
        color: #252220; 
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        padding-bottom: 25px;
        padding-top: 73px;

        letter-spacing: -0.24px;
              }
    }
  }

  .merchant-content-info {
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 28px;
  }

  .line {
    margin-top: 18px;
    margin-bottom: 14px;
    border-bottom: 1px solid #ececec;

    &.scnd {
      margin-top: 14px;
      margin-bottom: 18px;
    }
  }

  .link-item {
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    color: #00a6ff;
  }

  .location-text {
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #000000;
  }

  .details {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #060606;
  }

  .phone-number {
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    color: #00a6ff;
    margin-top: 4px;
  }

  .info-item {
    .bold {
      font-weight: bold;
      font-size: 13px;
      line-height: 21px;
      color: #000000;
    }
    .close-item {
      font-weight: 500;
      font-size: 13px;
      line-height: 21px;
      color: #000000;
    }
  }

  @include media-break("lg") {
    .main-content {
      flex-direction: column;

      .col-right {
        margin-top: 0;
        // margin-left: 46px;
      }
    }
  }
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}


.merchant-breadcrumb {
  padding: 0 0px !important;

  @include media-break("lg") {
    padding: 0 0px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 0px;
  }
}

// .merchant-popup-more-like {
//   top: 8px;
//   right: -15px;
//   position: absolute;
//   z-index: 11;
//   background: #fff;
//   width: 200px;
//   height: 120px;
//   padding: 20px;
//   box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.12);
//   border-radius: 8px;

//   @include media-break("xl") {
//     width: 242px;
//     height: 130px;
//   }

//   .radiotext-like {
//     font-size: 17px;
//     font-weight: 400;
//     height: 40px;
//     color: #000;
//     border-bottom: 1px solid #ececec;
//   }
    
//   .radiotext-dislike {
//     font-size: 17px;
//     font-weight: 400;
//     height: 40px;
//     margin-top: 10px;
//     color: #FF3B30;
//   }
  
  
// input[type='radio'] {
//   -webkit-appearance:none;
//   width:20px;
//   height:20px;
//   border:1px solid #000;
//   border-radius:50%;
//   outline:none;
//   float: right; 
//   cursor: pointer;
// }

// input[type='radio']:before {
//   content:'';
//   display:block;
//   width:60%;
//   height:60%;
//   margin: 20% auto;    
//   border-radius:50%;    
// }
// input[type='radio']:checked:before {
//   background:green;
// }

// .dislikeradio:before {
//   content:'';
//   display:block;
//   width:60%;
//   height:60%;
//   margin: 20% auto;    
//   border-radius:50%;    
// }
// .dislikeradio:checked:before {
//   background: #FF3B30 !important;
// }
// }

.merchant-popup-more-like-ar {
  left: -9px;
  right: auto;

  @include media-break("xl") {
    width: 242px;
    height: 130px;
  }

  input[type='radio'] {
    float: left; 
  }
}

.merchant-popup-taste-n, .merchant-popup-more-like {
  top: 10px;
  right: -5px;
  position: absolute;
  z-index: 11;
  background: #fff;
  width: 180px;
  height: 101px;
  padding: 19px 0px;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  .radiotext-like {
    font-size: 13px;
    font-weight: 500;
  //  height: 30px;
    color: #000811;
    padding: 8px 0;
    letter-spacing: 0.015em;
    &.chkd {
      background:#D0E7FF;
      color: #004388;
    }
    &:hover {
      background: #D0E7FF;
      color: #004388;
    }
  }
    
  .radiotext-dislike {
    font-size: 13px;
    font-weight: 500;
  //  height: 30px;
    padding: 8px 0;
    letter-spacing: 0.015em;
    color: #000811;
    &.chkd {
      background:#D0E7FF;
      color: #004388;
    }
    &:hover {
      background: #D0E7FF;
      color: #004388;
    }
  }
  
  input[type='radio'] {
    appearance: none;
    -webkit-appearance:none;
    -moz-appearance: none;
    outline: none;
    float: left;
    cursor: pointer;

    &::before {
      opacity: 0;
    }
  }

  .likeradio {
    background-image: url(../../../components/Icons/Svgs/likenew.svg);  
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    margin: 4px 2px 4px 14px;
    border: none;
    border-radius: 0 !important;
  }
  .dislikeradio {
    background-image: url(../../../components/Icons/Svgs/dislikenew.svg);
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    margin: 4px 2px 4px 13px;
    border: none;
    border-radius: 0 !important;
  }
}

.merchant-popup-taste-ar-n {
  left: -9px;
  right: auto;

  input[type='radio'] {
    float: right;
  }

  .likeradio {
    margin: 4px 14px 4px 8px;
  }
  .dislikeradio {
    margin: 4px 14px 4px 8px;
  }
}

.merchant-popup {
    top: 8px;
    right: -35px;
    position: absolute;
    z-index: 11;
    background: #fff;
    width: 210px;
    height: 185px;
    padding: 20px;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.12);
    border-radius: 8px;

    @include media-break("md") {
      width: 260px;
      height: 221px;
    }

    .radiotext-like {
      font-size: 17px;
      font-weight: 400;
      height: 40px;
      color: #000;
      border-bottom: 1px solid #ececec;
    }
      
    .radiotext-dislike {
      font-size: 17px;
      font-weight: 400;
      height: 40px;
      margin-top: 10px;
      color: #FF3B30;
    }
    
    
  input[type='radio'] {
    -webkit-appearance:none;
    width:20px;
    height:20px;
    border:1px solid #000;
    border-radius:50%;
    outline:none;
    float: right; 
    cursor: pointer;
  }
  
  input[type='radio']:before {
    content:'';
    display:block;
    width:60%;
    height:60%;
    margin: 20% auto;    
    border-radius:50%;    
  }
  input[type='radio']:checked:before {
    background:green;
  }

  .dislikeradio:before {
    content:'';
    display:block;
    width:60%;
    height:60%;
    margin: 20% auto;    
    border-radius:50%;    
  }
  .dislikeradio:checked:before {
    background: #FF3B30 !important;
  }
}

.custom-alert{
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.custom-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.custom-alert-button-group > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #fff;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
}

.share_container{
  display: flex;
  flex-flow: wrap;

  .share_button{
    padding: 5px;
  }
}

.online-div {
  position: absolute !important;
  top: 6px !important;
  left: 6px !important;
}
.similar-heart {
  position: absolute !important;
  right: 6px;
  top: 6px !important;
}
.similar-heart-arab {
  position: absolute !important;
  left: 13px;
  top: 10px !important;
}
.similarDiv {
  margin-top: 72px;
  border-top: 1px solid #ECECEC;
}
.page-title {
  margin-top: 72px;
  padding-right: 0;
  &.home {
    margin-top: 50px;
    @media screen and (max-width: 600px) {
      margin-top: 60px;
    }
  }
  &.home-mbl {
    margin-top: 0px !important;
  }
  &.home-mbl-ban {
    margin-top: 50px !important;
  }
}
.similar-merchants-title{
  margin-top: 73px;
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.24px;
  color: #000000;
}
.similar-merchants{
  margin-bottom: 0px !important;
  @media screen and (min-width: 1400px) {
    margin-left: 10px !important;
  }
  @media screen and (min-width: 1500px) {
    margin-left: -10px !important;
  }
  .slick-slider{
    // margin-left: 50px !important;
    // padding-right: 30px !important;
    @media screen and (max-width: 768px) {
      padding-right: 0px !important;
    }
    @media screen and (min-width: 1500px) {
      margin-left: 26px !important;
      padding-right: 0 !important;
    }
    @media screen and (min-width: 1900px) {
      margin-left: 26px !important;
      padding-right: 0 !important;
    }
  }
  .slick-list {
    // padding-left: 30px !important;
    // @media screen and (max-width: 900px) {
    //   padding-left: 30px !important;
    // }
  }
  .hed-tit{
    // margin-left: 15px !important;
    // @media screen and (min-width: 800px) {
    //   margin-left: 80px !important;
    // }
    @media screen and (min-width: 1500px) {
      margin-left: 26px !important;
    }
    @media screen and (min-width: 1920px) {
      margin-left: 30px !important;
    }
  }
  .hed-tit-ar{
    margin-left: 0px !important;
    margin-right: 52px !important;
    @media screen and (max-width: 768px) {
      margin-right: 15px !important;
    }
  }
  .hed-tit-lst {
    font-size: 15px;
    line-height: 20px;
    font-weight: normal;
  }
}
.carousel_arabic{
  .slick-list{
    padding-left: 0px !important;
  }
}

.mer-img{
  min-width: 80px;
  min-height: 80px;
}

.card-image-merch {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 180px;
  max-width: 100%;
  border-radius: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.locAddress {
  margin-bottom: 19px;
  .locHead {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
    @media screen and (max-width: 738px) {
      align-items: flex-start !important;
    }
    .viewMap {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #005AB7;
      cursor: pointer;
    }
  }
  .locAdd {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #6B7280;
    width: 170px;
    @media screen and (max-width: 992px) {
      width: 300px;
    }
  }
}
.locMar {
  margin-right: 30px;
}
.offerHdrDiv {
  font-size: 24px;
   font-weight: 600;
   line-height: 36px;
   padding-left: 64px;
   padding-right: 24px;
   color: 000811;
   font-family: Noto Sans;
   display: flex;
   overflow: hidden;
   @media(max-width:933px){
    padding-left: 24px;
  }
  @media screen and (min-width: 1920px) {
    padding-left: 198px !important;
  }
  @media screen and (min-width: 2400px) {
    padding-left: 541px !important;
  }
  @media screen and (min-width: 4000px) {
    padding-left: 30% !important;
  }
  @media screen and (min-width: 5000px) {
    padding-left: 36% !important;
  }
}
  .Hdrline {
   border-top: 1px solid #E3E3E3;
   margin-left: 64px;
   margin-right: 29px;
   margin-top: 17px;
   @media(max-width:933px){
    margin-left: 24px;
  }
   }