/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Footer Component
@font-face {
  font-family: 'Noto_Sans_SemiBold';
  src: url('../../fonts/NotoSans-Light.woff2') format('woff2'),
       url('../../fonts/NotoSans-Light.woff') format('woff');
}

.ftr {
  &-main {
    .display-flex{
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: inherit;
      }
    }
    .justify-start{
      display: flex;
      justify-content: flex-start;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        line-height: 25px;
      }
      .ftr-text-div{
        padding-left: 60px;
        color: #000000;
        @media screen and (max-width: 768px) {
          padding-left: 0px;
        }
      }
      .ftr-text-div-ar{
        padding-left: 0px !important;
        padding-right: 62px;
        @media screen and (max-width: 768px) {
          padding-right: 0px !important;
        }
      }
    }


    .justify-end{
      .contact-div{
        padding:9px 13px;
        background: #FFFFFF;
        border-radius: 18px;
        width: fit-content;
        @media screen and (max-width: 768px) {
          margin-top: 10px;
        }
      }
      .contact-phone{
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: #000000;
        padding-left: 11px;
      }
      .contact-phone-ar{
        padding-left: 0px !important;
        padding-right: 11px;
      }
    }
   
    background: #f7f7f7;
    padding: 48px 15px 24px 15px;

    @include media-break("lg") {
      padding: 48px 60px 24px 60px;
    }

    .border-line {
      border: 0.9px solid #dddddd;
    }
    @media screen and (max-width: 768px) {
    .footer-alignment{
      flex-direction: column;
    }
    .contact{
      margin-bottom: 30px;
      .ftr-support,.ftr-sup-website{
        text-align: center !important;
      }
    }
  }
  }
  &-support{
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    margin-top: 8px;
    color: #000000;
  }
  &-support-ar{
    text-align: left !important;
  }
  &-sup-website{
    text-align: right;
    color: #4679B2
  }
  &-sup-website-ar{
    text-align: left !important;
  }
  &-tit {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #1d1b1b;
    letter-spacing: 1px;
    margin-bottom: 18px;
  }
  &-sub {
    font-family: "Noto_Sans_SemiBold";
    margin-bottom: 18px;
    margin-top: 18px;
    font-size: 14px;
    line-height: 19px;
    color: #1d1b1b;
    letter-spacing: 0.25px;
    font-weight: 600;
  }
  &-txt {
    font-size: 13px;
    line-height: 20px;
    color: #000000;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      line-height: 25px;
      height: 45px;
    }
  }
  &-disc-text{
    font-size: 14px;
    line-height: 22px;
    padding: 16px 0px 42px 0px;
    color: #000000;
  }
  &-site-text{
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 44px;
    color: #000000;
  }
}
.wrap-ftr {
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
}
