/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

@import "../variable.scss";

.wishlist-div {
  .title-wlst {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.24px;
    color: #000811;
    margin-top: 34px;
  }

  .des-wlst {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px; 
    letter-spacing: 0.25px;
    color: #001122;
    margin-top: 5px;
  }
  .wishlist-pagination {
    .pagination-wrapper, .pagination-wrapper-mobile  {
      padding-top: 20px;
      justify-content: center !important;
    }
    .pagination-container, .pagination-container-mobile  {
      .pagination-item-leftarrow, .pagination-item-rightarrow, .pagination-item-leftarrow-mobile, .pagination-item-rightarrow-mobile {
        border: 1px solid #e1e1e1 !important;
        border-radius: 50% !important;
        cursor: pointer !important;
        svg {
          fill: #000;
        }
      }
      .pagination-item-leftarrow:hover, .pagination-item-rightarrow:hover , .pagination-item-leftarrow-mobile:hover, .pagination-item-rightarrow-mobile:hover{
        background: #fff;
        svg {
          fill: #000;
        }
      }
      // .pagination-item-rightarrow {
      //   margin-right: 0px !important;
      //   margin-left: 8px !important;
      // }
      .leftarrow-disabled, .rightarrow-disabled {
        svg {
          fill: #D1D5DB !important;
        }
      }
      .pagination-item-rightarrow {
        margin-right: 0px !important;
        margin-left: 8px !important;
      }
      .pagination-item-rightarrow-mobile {
        margin-right: 0px !important;
        margin-left: 4px !important;
      }
      .pagination-item , .pagination-item-mobile{
        border-radius: 50%;
        background: #fff;
        color: #000;
        cursor: pointer;
        width: 14px !important; 
      }
      .filled-selected {
        background: rgba(0, 67, 136, 1);
        color: #fff;
      }
    }
  }
}
