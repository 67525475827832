/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

@import "../variable.scss";

/* Switch toggle */
.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  cursor: pointer;
  width: 44px;
  height: 26px;
  background: #EFE9E5;
  border-radius: 36.5px;
  position: relative;
  transition: background-color 0.5s;
  &.arabic-dirc-label{
    &.active{
      .arabic-dirc-span{
        content: '';
        right: -2px;
      }
    }
  }
  &.active {
    background: #35C759;
  }
}


.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 22px;
  height: 22px;
  border-radius: 45px;
  transition: 0.2s;
  background: #FFF;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.01), 0px 3px 1px rgba(0, 0, 0, 0.03);
  border-radius: 100px;
  &.active {
    background: #fff;
  }
}
.react-switch-label .arabic-dirc-span{
  content: "";
  right: 2px;
  left: 0px;
}


.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-checkbox:checked + .arabic-dirc-label .arabic-dirc-span {
  right: calc(100% - 2px);
  transform: translateX(100%);
}

.react-switch-label:active .react-switch-button {
  width: 45px;
}
