/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

.mob-footer{
    background-color: #323642;
    @media screen and (min-width: 767px){
        display: none;
    }
    .item-1{
        background-color: #143874;
        padding-top: 1rem;
        padding-left: 16px;
        padding-right: 16px;
        cursor: pointer;
        .h-1{
            display: flex;
            justify-content: space-between;
            padding-bottom: 1rem;
            .desc{
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                color: #FFFFFF;
            }
            .arrw{
                padding-top: 3px;
            }
            .arrw-ar{
                padding-top: 3px;
                transform: rotate(180deg);
            }
        }
        .sub{
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            color: #FFFFFF;
        }   
    }
    .item-o{
        padding-top: 1rem;
        padding-left: 16px;
        padding-right: 16px;
        cursor: pointer;
        .h-1{
            display: flex;
            justify-content: space-between;
            padding-bottom: 1rem;
            .desc{
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                color: #FFFFFF;
            }
            .arrw{
                padding-top: 3px;
            }
            .arrw-ar{
                padding-top: 3px;
                transform: rotate(180deg);
            }
        }
        .sub{
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            color: #FFFFFF;
        }
    }
    .sep-line{
        border: 0.5px solid #979797;
        margin-top: 2rem;
        margin-left: 17px;
        margin-right: 17px;
    }
    .maya{
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
        font-family: 'Noto Sans' !important;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #FFFFFF;
    }
    .rights-content{
        display: flex;
        justify-content: center;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        color: #FFFFFF;
    }
    .privacy{
        display: flex;
        justify-content: center;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        color: #FFFFFF;
        // padding-bottom: 6rem;
    }
}