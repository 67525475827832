/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Best Offer Component
@import "../mixins.scss";

.best_offer {
  //margin-top: 32px;

  .heading-best-offer {
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #000;

    &.mbl {
      font-size: 14px;
      line-height: 20px;
      text-transform: unset;
      justify-content: space-between;
    }
    .offer-desc{
      font-size: 18px;
      line-height: 25px;
      letter-spacing: -0.24px;
      color: #000811;

      &.mbl {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.24px;
      }
    }
    .hea-offer-txt {
      width: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .desc-best-offer {
    font-size: 14px;
    line-height: 26px;
    color: #3d4151;

    &.mbl {
      font-size: 10px;
    }
  }
  .info-offer {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    word-break: break-word;
    color: #303133;
    &.mbl {
      font-size: 13px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-transform: capitalize;
      color: #303030;
    }
  }
  .infooff{
    font-size: 12px;
    line-height: 16px;
    color: #303133;
}
  .toggle {
    cursor: pointer;
    width: 40px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 4px;
  //  margin-left: 8px;
  }

  .claim-btn-div {
    width: 120px;
    padding-left: 0;

    @include media-break("lg") {
    //  padding-left: 15px;
    }
  }
  .btn-div {
    margin-top: 16px;
    .btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.24px;
      text-transform: uppercase;
      color: #FFFFFF;
      height: 36px;
      background: #0071E6     !important;
      border-radius: 4px;
      padding: 18px 3px;
      &-scs {
        background-color:#208924 !important; 
      }
      &:focus {
        color: #fff;
      }
    }
  }
  .expire-div {
    background: #CF3131;
border-radius: 4px;
padding: 6px 25px;
font-weight: 500;
font-size: 14px;
line-height: 19px;
color: #FFFFFF;
margin-top: 16px;
align-items: center;
justify-content: space-between;
.oops-div{
  height: 27px;
  width: 0px;
border-right: 1px solid rgba(255, 247, 247, 0.4);
margin-left: 12px;
margin-right: 12px;
}
  }
}
.p-100{
  padding-right:100px;
  padding-left: 0px;
  word-break: break-all;

  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 15px;
  }
}
.p-100-ar{
  padding-left:100px !important;
  padding-right: 0px !important;
}
.p-50{
  // padding-right:50px;
  padding-left: 0px;
  // word-break: break-all;
}
.p-50-ar{
  padding-left:50px !important;
  padding-right: 0px !important;
}
.item-det-info {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  color: #3D4151;
  white-space: pre-line;
  overflow-wrap: break-word;
  // word-break: break-word;
  @media screen and (max-width: 600px) {
    color: #001122 !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
.item-det-info-ar {
  line-height: 13px !important;
}
.info-offer {
  font-weight: 400;
  font-size: 16px;
line-height: 24px;
word-break: break-word;

color: #303133;

  &.mbl {
    font-size: 13px;
  }
}
.redeem-url{
  display: inline-block !important;
  direction: ltr !important;
  vertical-align: top;
  color:#F58532;
  cursor: pointer;

  &.ellipsis {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media(max-width:600px){
      max-width: 180px !important;
    }
  }
}
.redeem-url:hover{
  color: #F58532;
  text-decoration: underline;
}
.card-hide{
  display: none;
  opacity: 0;
  height: 0px;
  transition: height 0ms 1s, opacity 1s 0ms;
  -webkit-transition: height 0ms 1s, opacity 1s 0ms;
  -moz-transition: height 0ms 1s, opacity 1s 0ms;
  -o-transition: height 0ms 1s, opacity 1s 0ms;
  -ms-transition: height 0ms 1s, opacity 1s 0ms;
}
.card-show{
  display: block;
  opacity: 1;
  height:auto;
  transition: height 0ms 1s, opacity 1s 0ms;
  -webkit-transition: height 0ms 1s, opacity 1s 0ms;
  -moz-transition: height 0ms 1s, opacity 1s 0ms;
  -o-transition: height 0ms 1s, opacity 1s 0ms;
  -ms-transition: height 0ms 1s, opacity 1s 0ms;
}
.text-expand{
  color: #005AB7;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 38px;
  cursor: pointer;
  width:150px;
  font-family: 'Bliss_Bold';
}
.header-item { 
  height: 100%;
  margin: 50px 0px 8px 0px;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (min-width: 768px) {
    width: max-content;
    border-bottom: 1px solid #ececec;
  }
  
  .nav {
    cursor: pointer;
    font-size: 13px;
    line-height: 32px;
    font-weight: 600;
    height: 34px;
    color: #8e8e93;
    margin-right: 58px;

    @media screen and (max-width: 768px) {
      margin-right: 30px;
    }

    @include media-break("lg") {
      font-size: 13px;
    }

    &-act {
      color: #000000 ;
      border-bottom: 3px solid #000000 ;
    }
  }
  .nav-ar {
    margin-right: 0px;
    margin-left: 58px;
  }
}
.viewStore {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0071E6;
  cursor: pointer;
}
.locationscroll {
  max-height: 300px;
  overflow: auto;
  margin-right: 30px;
  cursor: pointer !important;
  @media screen and (max-width: 768px) {
    margin-right: 0px;
  }
}

.locationscroll::-webkit-scrollbar {
  width: 10px;
  padding-left: 20px;
  margin-left: 50px;
  cursor: pointer !important;
}

.locationscroll::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
  cursor: pointer !important;
  z-index: 500;
  margin-left: 50px;
}
.storeLocationList{
  display:flex;
}
.locationscroll::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 2px;
  // cursor: default;
  z-index: 500;
  cursor: pointer !important;
  margin-left: 50px;
}

.locationscroll::-webkit-scrollbar-track:hover {
  background-color: rgb(255, 255, 255);
  cursor: pointer !important;
  z-index: 500;
}

.locationscroll::-webkit-scrollbar-thumb:hover {
  background-color: rgb(0, 8, 17);
  border-radius: 2px;
  z-index: 500;
  cursor: pointer !important;
}

table {
  border-collapse: collapse; /* This is important for table borders to work */
  // max-width: 296px;
  margin-bottom: 15px;
  margin-top: 15px;
  
}
tr{
  overflow: auto;
}
th, td {
  border: 0.4px solid #444444; /* Set the border property for <th> and <td> elements */
  padding: 8px;
  text-align: left;
 font-size:14px;
 text-align: center;
}
.tablediv {
  overflow-x: auto !important;
  width:100%;
  // white-space: nowrap !important;
}
.hyperlink{
  font-size: 15px;
  font-family: 'Noto Sans';
  font-weight: 400;
  color: #DC2626;
  line-height: 26px;
   }
   .tablediv::-webkit-scrollbar-thumb {
    background: #333;
    width: 1px ;
    border-radius: 8px;
  }
  .tablediv::-webkit-scrollbar {
   width: 5px;
    height: 5px;
    border-radius: 8px;
  }
  .tablediv::-webkit-scrollbar-track {
    background: #e2e0c3;
    border-radius: 8px;
  }