.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    font-family: "Noto Sans" !important;
    justify-content: center;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: #004B92;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 15px;
    line-height: 20px;
    font-size: 16px;
    font-weight: 600;
    min-width: 32px;

    @media only screen and (max-width: 500px) {
      min-width: 28px;
      height: 28px;
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      //   background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      color: #fff;
      background-color: #004B92;
      
    @media only screen and (max-width: 932px) {
      background-color:#004B92;
    }
 
    }
  }
}

.search-circle-pag {
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e1e1e1;
  border-radius: 32px;
  margin-left: 10px;
  margin-right: 10px;

  &.disable {
    cursor: not-allowed;
    pointer-events: none;
  }
  @media only screen and (max-width: 500px) {
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 500px) {
  ul {
    margin-right: 30px;
  }
}
