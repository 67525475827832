/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/


// Home page
@import "../mixins.scss";

.home-page {
  padding-right: 0;
  padding-left: 0;

.header-details-adib{
  height: 74px;
  padding:0px !important;
}


  .header-details {
    width: 100%;
    padding: 0 0 0 15px;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 10;

    // @include media-break("lg") {
    //   padding: 0 60px !important;
    // }
    // @media screen and (max-width: 380px) {
    //   padding: 0 5px;
    // }

  }

  
    // @include media-break("md") {
    //   padding: 0 0 0 60px !important;
    // }

    &.discover {
      margin-bottom: 144px;
    }
  }

  .flex-start-header {
    display: flex;
    justify-content: flex-start;
  }

  .showmore {
    cursor: pointer;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #121213;
    text-align: center;
  }

.page-title {
  margin-top: 72px;
  padding-right: 0;

  @media screen and (max-width: 900px) {
    padding-left: 0px !important;
  }
  &.home {
    margin-top: 50px;

    @media screen and (max-width: 600px) {
      margin-top: 60px;
    }
  }

  &.home-mbl {
    margin-top: 50px !important;
  }

  &.home-mbl-ban {
    margin-top: 50px !important;
  }
}
.homeflex{
  z-index: 1;
}