/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Table Component
@import "../mixins.scss";
@import "../variable.scss";
@font-face {
  font-family: 'Noto_Sans_CondMed';
  src: url('../../fonts/NotoSans-SemiCondensedMedium.ttf') format('truetype');
}

.table-main {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  @include media-break("lg") {
    width: 90% !important;
  }

  &.redemption-table {
    .table-header {
      font-family: if($clientName == 'adib', 'Bliss_Medium' !important , 'Noto_Sans_CondMed' !important);
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      background: #f5f5f5;
      color: #252220;
      padding: 12px 16px 12px 8px;
      text-align: start;
      unicode-bidi: plaintext;
      min-width: 68px;

      @include media-break("lg") {
        font-size: 15px;
      }
    }
    .table-content {
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      color: #000000;
      padding: 12px 8px 12px 16px;      
      border-bottom: 1px solid #e7e7e7;

      &:first-child {
        padding: 12px 8px 12px 2px;
        text-align: unset;
      }

      @include media-break("lg") {
        text-align: unset;
        font-size: 15px;
        padding: 12px 16px 12px 16px;
      }

      .rtm-fst-col {
        display: flex;
      }
      .text-trunc{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
      }

      .trans-info {
        font-size: 10px;
        line-height: 15px;
        color: #c2c3c6;

        @include media-break("lg") {
          font-size: 11px;
        }
      }
    }
    .table-content-ar {
      padding: 12px 16px 12px 8px;
      &:first-child {
        padding: 12px 2px 12px 8px;
      }
      @include media-break("lg") {
        padding: 12px 16px 12px 16px;
      }
    }

    &.support {
      width: 100% !important;
      
      .table-header {
        padding: 12px 4px 12px 15px !important;
        &:first-child {
          padding: 12px 8px 12px 2px !important;
        }
        @include media-break("lg") {
          padding: 12px 16px 12px 16px !important;
        }
      }
      .table-header-ar {
        padding: 12px 15px 12px 4px !important;
        &:first-child {
          padding: 12px 2px 12px 8px !important;
        }
        @include media-break("lg") {
          padding: 12px 16px 12px 16px !important;
        }
      }
      .table-header-ar {
        padding: 12px 15px 12px 4px;

        @include media-break("lg") {
          padding: 12px 24px 12px 16px;
        }
      }
      .table-content {
        .status-div {
          color: #fff;
          padding: 1px 15px 1px 8px;
          // border-top-right-radius: 50px 30px;
          // border-bottom-right-radius: 50px 30px;
          clip-path: polygon(0% 0, 82% 2%, 120% 106%, 100% 54%, 84% 100%, 0 100%);
          -webkit-clip-path: polygon(0% 0, 82% 2%, 120% 106%, 100% 54%, 84% 100%, 0 100%);
          font-size: 10px;

          &.red {
            background: #F15154;
          }
          &.green {
            background: #2CA03E;
          }
        }
        .status-div-ar {
          white-space: nowrap;
          padding: 1px 10px 2px 14px;
          -webkit-clip-path: polygon(100% 0%, 100% 100%, 16% 100%, 0 50%, 16% 0);
          clip-path: polygon(100% 0%, 100% 100%, 16% 100%, 0 50%, 16% 0); 
        }
        &:first-child {
          text-align: center;
          text-transform: uppercase;
        }
        .check {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 60px;

          @include media-break("lg") {
            white-space: unset;
            overflow: unset;
            text-overflow: unset;
            max-width: unset;
          }
        }
        .trans-info {
          font-size: 9px;

          @include media-break("lg") {
            font-size: 11px;
          }
        }
      }
      .table-content-ar {
        padding: 12px 16px 12px 8px;
  
        &:first-child {
          padding: 12px 2px 12px 8px;
        }
        @include media-break("lg") {
          padding: 12px 16px 12px 16px;
        }
      }
    }
  }
}
