/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/
// @font-face {
//   // font-family: 'Noto_Sans_Nav';
//   src: url('../../fonts/NotoSans-Medium.woff2') format('woff2'),
//        url('../../fonts/NotoSans-Medium.woff') format('woff');
// }
// NavBar Component
@import "../../scss/variable.scss";

.new-navbar-div {
    white-space: nowrap;
    display: flex;
    padding-left: 64px;
    padding-right: 64px;

          @media screen and (min-width: 1400px) {
              padding-left: 60px;
              padding-right: 60px;
          }
          @media screen and (min-width: 1500px) {
              padding-left: 240px;
              padding-right: 240px;
          }
          .circle-left{
            display: flex;
            align-items: center;
            padding-right: 3px;
          }
          .circle-right{
            display: flex;
            align-items: center;
            padding-left: 3px;
          }
          .nav-slide{
            display: flex;
            width: 100%;
            overflow-x: scroll;
            scroll-behavior: smooth;
            white-space: nowrap;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;
          }
          .nav-slide::-webkit-scrollbar {
            display: none;
          }   
    .nav {
      cursor: pointer;
      font-size: 13px;
      font-weight: 500;
      height: 50px;
      padding-top: 15px;
      color: #8e8e93;
      &-act {
        color: #00AE8E;
        border-bottom: 3px solid #00AE8E;
        svg {
          path {
            fill: #00AE8E;
          }
        }
      }
    }
    .nav:hover{
      background: if($clientName == "hdfc", #D0E7FF,#fad5aa);
    }
  }
  .navbar-div-mbl {
    overflow-x: auto;
    scrollbar-width: 0px; /* Firefox */
    display: flex;
    padding-bottom: 16px;
    border-bottom: 1px solid #ececec;
    z-index: 0;
    
    margin-right: 17px;
    margin-left: 17px;
  
    .nav-mbl {
      cursor: pointer;
      height: 20px;
      border-radius: 100px;
      color: #8e8e93;
      margin-right: 0px;
      padding: 15px;
  
      .title-mbl {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 120px;
      }
  
      &-act {
        color: #fff;
        background: #00AE8E;
      }
    }
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .navbar-div-mbl::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .navbar-div-mbl {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .homeiconenglish{
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 11px;
  }
  .homeiconarabic {
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 11px;
  }
  .nav-bar-items {
    padding-left: 16px;
    padding-right: 16px;
    @media screen and (min-width: 1014px) {
      padding-left: 4.5px;
      padding-right: 4.5px;
  }
  @media screen and (min-width: 1050px) {
    padding-left: 6px;
    padding-right: 6px;
  }
  @media screen and (min-width: 1100px) {
    padding-left: 8px;
    padding-right: 8px;
  }
  @media screen and (min-width: 1200px) {
    padding-left: 12.5px;
    padding-right: 12.5px;
  }
  @media screen and (min-width: 1300px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  }