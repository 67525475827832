/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/
@import "../variable.scss";
// Banner
.banner-cont {
  .slick-list {
    // margin-top: 18px;
    padding: 0 20% 0 0;
    overflow: hidden !important;
  }
  .slick-prev {
    left: -50px;
    z-index: 100;
  }
  .slick-next {
    right: 38px;
    z-index: 100;
  }
  .slick-prev,
  .slick-next {
    top: 36%;
    z-index: 1 !important;
  }
  .slick-prev::before,
  .slick-next::before {
    content: "";
  }
  .slick-disabled {
    img {
      display: none;
    }
  }
  .slick-next.slick-disabled + .slick-dots {
    li:last-child button:before {
      color: if(
        $clientName == "adib",
        #459ddd !important,
        if($clientName == "hdfc", #004388 !important, #f58532 !important)
      );
      opacity: 1;
    }
  }

  .slick-slide {
    //width: 542px !important;
    padding: 0 8px 0 0;
    box-sizing: border-box;
    cursor: pointer !important;

    div {
      position: relative;
      border-radius: 8px !important;
      display: block !important;
      &:hover {
        //   box-shadow: 0px 8px 32px 5px rgba(0, 0, 0, 0.12);
      }
    }
    .banner-img {
      display: block !important;
    }
    .banner-img:hover {
      box-shadow: 0px 8px 32px 5px rgba(0, 0, 0, 0.12);
    }
    img {
      width: 100%;
      // height: 230px;
      border-radius: 8px !important;
      position: relative;
      @media screen and (min-width: 1920px) {
        height: 390px !important;
      }
      @media screen and (min-width: 1601px) and (max-width: 1919px) {
        height: 360px !important;
      }
      @media screen and (min-width: 1401px) and (max-width: 1600px) {
        height: 316px !important;
      }
      @media screen and (min-width: 1200px) and (max-width: 1400px) {
        height: 281px !important;
      }
      @media screen and (max-width: 899px) {
        height: 225px !important;
      }
      @media screen and (max-width: 500px) {
        height: 145px !important;
      }
    }
    .banner-text {
      width: 60%;
      position: absolute;
      bottom: 16px;
      left: 18px;
      background: rgba(255, 255, 255, 0.72);
      mix-blend-mode: normal;
      backdrop-filter: blur(7px);
      padding: 5px 25px 5px 16px;
      border-radius: 8px;
      color: #000811;
      font-weight: 700;
      font-size: 26px;
      line-height: 34px;
      letter-spacing: -0.24px;
      // min-height: 78px;
      font-family: if(
        $clientName == "adib",
        "Bliss_Bold" !important,
        "Noto Sans" !important
      );
      // // background: rgba(238,118,35,0.7);
      // border-radius: 10px;
      // word-break: break-word;
      @media screen and (max-width: 1024px) {
        width: auto;
        font-size: 18px;
        line-height: 22px;
      }

      @media screen and (max-width: 700px) {
        width: 56% !important;
        // min-height: 58px;
      }
      span {
        // background: rgba(255, 255, 255, 0.72);
        // mix-blend-mode: normal;
        // backdrop-filter: blur(7px);
        // padding: 5px 25px 5px 16px;
        // border-radius: 8px;
        // color: #000811;
        // font-weight: 700;
        // font-size: 26px;
        // line-height: 34px;
        // letter-spacing: -0.24px;
        // -webkit-box-decoration-break: clone;

        @media screen and (max-width: 600px) {
          padding-bottom: 4px;
        }
      }
    }

    .banner-text-arabic {
      // width: 55%;
      position: absolute;
      bottom: 10px;
      right: 0px;
      font-weight: bold;
      font-size: 26px;
      line-height: 35px;
      letter-spacing: -0.24px;
      padding: 10px;
      color: #ffffff;
      // border-radius: 10px;
      // word-break: break-word;
      @media screen and (max-width: 1024px) {
        width: auto;
        font-size: 17px;
        line-height: 23px;
      }
      span {
        background: rgba(238, 118, 35, 0.7);
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 10px;
        // border-bottom-left-radius: 1px;
        // border-top-left-radius: 1px;
        -webkit-box-decoration-break: clone;
      }
    }
    .banner-items-arabic {
      padding-right: 30px !important;
    }
  }

  .banImgArab {
    width: 100%;
    max-height: 300px !important;
    margin-top: 32px;
    transform: scaleX(-1);
    @media screen and (min-width: 1900px) {
      max-height: 400px !important;
    }
    @media screen and (max-width: 992px) {
      padding-left: 15px;
    }
    @media screen and (max-width: 450px) {
      height: 140px;
    }
  }

  .banImg {
    max-width: 100%;
    // margin-top: 30px;
    @media screen and (max-width: 992px) {
      padding-right: 15px;
    }
    @media screen and (max-width: 450px) {
      object-fit: unset;
    }
    @media screen and (min-width: 1024px) {
      // margin-right: 30px;
    }
    @media screen and (min-width: 1200px) {
      // margin-right: 30px;
    }
    @media screen and (min-width: 1400px) {
      // margin-right: 57px;
    }
    @media screen and (min-width: 1500px) {
      // margin-right: 0px;
    }
  }

  .adb {
    .slick-prev {
      left: -20px;
    }
    .slick-next {
      right: 55px;

      @media screen and (min-width: 1200px) {
        right: 50px;
      }
      @media screen and (min-width: 1400px) {
        right: 95px;
      }
      @media screen and (min-width: 1500px) {
        right: 33px;
      }
      @media screen and (min-width: 1700px) {
        right: 38px;
      }
    }
    .slick-prev,
    .slick-next {
      top: 46%;
    }
  }

  .boldTxt {
    font-family: if(
      $clientName == "adib",
      "Bliss_Bold" !important,
      "Noto Sans" !important
    );
  }
}

.slick-dots {
  @media screen and (max-width: 600px) {
    margin-top: -5px !important;
    position: absolute !important;
    // bottom: -15px !important;
  }
}

.slick-dots li button:before {
  font-size: 36px !important;
  content: "\2015" !important;
  color: #e5e5e5   !important;
  font-weight: bolder;
  opacity: 1;

  @media screen and (max-width: 600px) {
    content: "\2015" !important;
    font-size: 20px !important;
    font-weight: 800 !important;
  }
}

.slick-dots li.slick-active button:before {
  color: if(
    $clientName == "adib",
    #459ddd !important,
    if($clientName == "hdfc", #005AB7     !important, #f58532 !important)
  );
  opacity: 1;

  @media screen and (max-width: 600px) {
    color: if(
      $clientName == "adib",
      #143973 !important,
      if($clientName == "hdfc", #E30613  
      !important, #f58532 !important)
    );
  }
}
.slick-dots li {
  margin: 0px 12px !important;

  @media screen and (max-width: 600px) {
    margin: 0px 2px !important;
  }
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  // @media screen and (min-width: 768px) {
  //   color: if($clientName == 'adib',#459DDD !important,if($clientName == 'hdfc',#FF0000 !important,#F58532 !important));
  //   opacity: 1;
  // }
}

.slick-slider {
  .slick-arrow {
    opacity: 0;
    transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
  }
  &:hover {
    .slick-arrow {
      opacity: 1;
    }
  }
}
[dir="rtl"] .slick-next {
  right: 55px !important;
  left: unset !important;
}

.ban-arabic {
  .slick-list {
    padding: 0px !important;
  }
  .slick-track {
    margin: 0px !important;
  }
}

.banner-wraper {
  position: relative;
  width:103%;
  @media screen and (min-width: 1500px) {
    width:100%;
  }
  @media screen and (max-width: 899px) {
    width:102%;
  }
  .banImg {
    // max-height: 300px !important;
    max-height: 100%;
    width: 100%;
    @media screen and (min-width: 1900px) {
      max-height: 400px !important;
    }
    @media screen and (max-width: 450px) {
      // height: 140px;
    }
  }
  .banner-content-default {
    position: absolute;
    top: 11px;
    left: 4% !important;
    @media screen and (min-width: 1900px) {
      top: 25%;
    }

    @media screen and (min-width: 850px) {
      top: 18%;
    }

    @media screen and (max-width: 450px) {
      top: 11px;
    }
    @media screen and (max-width: 330px) {
      top: 11px;
    }
    h1 {
      margin-top: 0px;
      font-weight: 500 !important;
      font-size: 24px;
      letter-spacing: -0.24px;
      color: #000000;
      @media screen and (max-width: 992px) {
        font-size: 30px;
      }
      @media screen and (max-width: 450px) {
        font-size: 24px;
      }
      @media screen and (max-width: 350px) {
        font-size: 18px;
      }
    }
    span {
      font-weight: 600;
      text-decoration: underline;
    }
  }
}

.ban-arabic {
  .banner-content {
    top: 18%;
    left: 0;
    right: 4%;
    @media screen and (min-width: 1900px) {
      top: 25%;
    }
    @media screen and (max-width: 992px) {
      top: 25%;
    }
    @media screen and (max-width: 450px) {
      top: 25%;
    }
    @media screen and (max-width: 330px) {
      top: 22%;
      right: 3%;
    }
    h1 {
      @media screen and (max-width: 992px) {
        font-size: 27px;
      }
      @media screen and (max-width: 450px) {
        font-size: 13px;
      }
      @media screen and (max-width: 330px) {
        font-size: 11px;
      }
    }
  }
}
.progress-slick-line{
  width: 30px;
  height: 3px;
  background-color: #e5e5e5;
  position: relative;
  top:25px;
  @media screen and (max-width: 600px){
    width: 16px;
  }
}
.slick-active{
  .progress-slick-line{
    span{
      display: block;
      width: 30px;
      height: 3px;
      @media screen and (max-width: 600px){
        animation: barmbl 2.5s linear;
        width: 16px;
      }
        animation: bar 2.5s linear;
        // background-color: if($clientName == "hdfc", #005AB7  !important, #f58532 !important);
        @keyframes barmbl {
          0%   {width: 0px;}
          100%  {width: 15px;}
        }
        @keyframes bar {
          0%   {width: 0px;}
          50%  {width: 15px;}
          100% {width: 30px;}
        }
    }
  }
}
.toast-background {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 998;
}