/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Claimed Component
@import "../mixins.scss";

.claimed-div {
  margin-bottom: 80px;

  @include media-break("lg") {
    margin-bottom: 140px;
  }

  .claimed-title {
    font-size: 18px;
    line-height: 16px;
    color: #787b86;
  }
  .claimed-list {
    margin-top: 60px;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.24px;
    color: #000000;
  }
  .item-list {
    margin-top: 14px;
    border-bottom: 1px solid #e7e7e7;

    .itm-lst-div {
      display: flex;

      @include media-break("lg") {
        display: unset;
      }
    }

    &:last-child {
      border-bottom: 1px solid transparent;
    }
    .claimed-with-icon {
      border-radius: 8px;
      //width: 130px;
      height: 100%;
      border: 0.8px solid #f0f0f0;
      // padding-top: 7px;
      // padding-bottom: 14px;
      // padding-right: 7px;

    }
    .claimed-item-div {
      border-radius: 5px;
      text-align: center;
    }
    .claim-btn {
      margin-top: 10px;
      @include media-break("lg") {
        margin-top: 0;
      }
    }
    .avail-det {
      background: rgba(229, 229, 234, 0.4);
      border-radius: 14px;
      display: flex;
      height: 25px;
      width: fit-content;
      flex-direction: row;
      align-items: center;
      padding: 5px 12px;
      font-weight: 600;
      font-size: 11px;
      line-height: 12px;
      color: #281302;
    }
    .avail-det-ar {
      float: right;
    }
    .heading-item-title {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      text-transform: capitalize;
      color: #000;
      margin: 8px 0;

      @include media-break("lg") {
        font-size: 15px;
        line-height: 18px;
      }
    }
    .expirees-det {
      display: flex;
      font-weight: 600;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.07px;
      color: #616161;
    }
    .expirees-det-ar {
      float: right;
    }
    .rating {
      display: flex;
      align-items: center;
      .dot {
        width: 3px;
        height: 3px;
        border-radius: 3px;
        background: #c7c7cc;
    //    margin-left: 10px;

        @include media-break("lg") {
      //    margin-left: 0;
        }
      }

      .star {
        height: 11px;
        transform: scale(2);
      }

      .rat-tit {
        margin-left: 4px;
      }
    }
    .claims-info {
      background: linear-gradient(0deg, #ebf9ee, #ebf9ee);
      border-radius: 4px;
      width: fit-content;
      padding: 6px 12px;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      color: #12a738;
    }
  }
}
.claimed_offer_imgdiv {
  width: 130px;
}
.claimed-heart {
  position: absolute;
  left: 109px !important;
  top: 6px !important;
}
.claimed-heart-ar {
  left: 6px !important;
  right: auto !important;
}
.nodata_hea {
  letter-spacing: -0.24px;
  font-weight: normal;

  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
}
.nodata_cap {
  color: #787B86;
  max-width: 400px;
  font-size: 18px;
  text-align: center;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 75%;
  }
}
.no_data_div{
  margin-top: 100px;
  margin-bottom: 140px;
}
.no_data_div_wishlist{
  margin-top: 100px;
  margin-bottom: 129px;

  @media screen and (max-width: 768px) {
    margin-top: 70px;
    margin-bottom: 100px;
  }
}
.no_data_div_wishlist_ar{
  margin-top: 100px;
  margin-bottom: 129px;

  @media screen and (max-width: 768px) {
    margin-top: 70px;
    margin-bottom: 100px;
  }
}
.no_data_button_ar{
  margin: 24px auto auto auto;
  padding: 8px 62px;
  font-size: 16px;
line-height: 22px;
letter-spacing: -0.24px;
color: #FFFFFF;
background: #141414;
border-radius: 4px;
width: 256px;
cursor: pointer;
img{
    margin-left: 8px;
}
}
.no_data_button{
  margin: 24px auto auto auto;
  padding: 8px 35px;
  font-size: 16px;
line-height: 22px;
letter-spacing: -0.24px;
color: #FFFFFF;
border-radius: 4px;
width: 256px;
cursor: pointer;
img{
  margin-right: 8px;
  margin-top: -4px;

}
}
