/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/
@import "../variable.scss";
// Terms And Condition
.terms-div {
  .prv-tit-mbl {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.24px;
    color: #000811;
  }
  .terms-title {
    font-size: 18px;
    line-height: 16px;
    color: #000;
    margin-bottom: 42px;
  }
  .terms-title-mbl {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: #001122;
    margin-bottom: 44px;
  }
  .terms-details {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 40px;

    b {
      color: #212529;
    }

    .title {
      color: #212529;
      font-weight: bold;
    }
  }
  .mainTitle {
    font-family: if($clientName == 'adib', 'Bliss_Bold' !important , 'Noto Sans' !important);
  }
  .mainTitle-mbl {
    font-family: if(
      $clientName == "adib",
      "Bliss_Bold" !important,
      "Noto Sans" !important
    );
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.24px;
    color: #000811;
  }
}
