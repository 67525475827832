/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/
@import "../variable.scss";
// Redeem Modal
$redeem-black-color: #000;
$redeem-header-color: #616161;

.share-modal {
  .mdl {
    margin-top: 100px !important;
  }
  .header {
    padding: 0px 8px 8px 28px;
    background: #f8f8f8;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    .image {
      position: relative;
    }
    .header-image {
      display: inline-block;
      border: 0 none;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    .abs-close {
      position: absolute;
      cursor: pointer;
      right: 20px;
      top: 8px;
      background: #f7f7f7;
      width: 32px;
      height: 32px;
      border-radius: 42px;
      margin-top: 12px;
      border: 1px solid #e1e1e1;
      svg {
        path {
          stroke: $redeem-black-color;
        }
      }
    }

    .merchant-details-img-content-mbl-shr {
      height: 187px !important;
      width: 300px;
      border-radius: 8px;
      position: relative;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 8px;
      align-items: center;
      position: relative;
      margin-top: -65px;
      @media (max-width: 600px) {
        width: 180px !important;
        position: static;
      }
      img{
        border-radius: 8px;
      }
      object-fit: cover;
    }
    .merchant-name {
      margin-top: 40px;
      font-size: 30px;
      line-height: 41px;
      letter-spacing: -0.24px;
      color: #000000;
      @media (max-width: 600px) {
        margin-top: 10px;
      }
    }
    .desc {
      margin-bottom: 36px;
      margin-top: 24px;
    }
    .detail {
      font-size: 16px;
      line-height: 24px;
      margin-top: 4px;
      color: #303133;
      span {
        color: #f58532;
        cursor: pointer;
      }
    }
    .item-des {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
      padding-right: 5px;
      margin-right: 5px;
      border-right: 1px solid #000000;
      font-family: if(
        $clientName == "adib",
        "Bliss_Medium" !important,
        "Noto Sans" !important
      );
    }
    .website {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #f58532;
      cursor: pointer;

      &.ellipsis {
        direction: ltr;
        display: inline-block;
        max-width: 240px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;
      }
    }
  }

  //Arabic header
  .header-ar {
    padding: 0px 28px 8px 8px !important;

    .abs-close-ar {
      //Arabic
      left: 20px !important;
      right: auto !important;
    }
    .item-des {
      padding-left: 5px;
      margin-left: 5px;
      border-left: 1px solid #000000;
      padding-right: 0px;
      margin-right: 0px;
      border-right: none;
    }
  }

  .share-body {
    padding: 50px 86px 0px 86px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    .body-text {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      text-align: center;
      letter-spacing: -0.24px;
      color: #000000;
      font-family: if(
        $clientName == "adib",
        "Bliss_Bold" !important,
        "Noto Sans" !important
      );
    }
    .share-options {
      margin-top: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .copy-div {
      width: fit-content;
      text-align: center;
      margin-bottom: 40px;
      .copy-link {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #6e6d79;
        margin-bottom: 8px;
      }
      .copy-input {
        padding: 8px;
        background: #f3f3f4;
        border-radius: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #9e9ea6;
        .copy-text {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          margin-left: 12px;
          color: #f58532;
          cursor: pointer;
        }
        .copy-text-ar {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          margin-right: 12px;
          color: #f58532;
          cursor: pointer;
        }
      }
    }

    //Arabic
    .copy-div-ar {
      margin-right: 100px;

      @media (max-width: 600px) {
        margin-right: 10px;
      }
      .copy-text {
        margin-left: 0px !important;
        margin-right: 12px;
      }
    }
  }
}

@media (max-width: 600px) {
  .share-modal .share-body {
    padding: 50px;
    .copy-div {
      margin-left: 20px;
    }
  }
}

//New Share Modal
.share-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1071;
  .share-content {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    overflow: auto;
    outline: 0;
    transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
    transform-origin: top center;
    .share-modal {
      width: 50%;
      margin: 100px auto;
      background: #fff;
      border-radius: 16px;
      box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
      @media (max-width: 1024px) {
        width: 75%;
      }
      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }
}
