/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/
@import "../variable.scss";
// Select Component
.select-wrap {
  width: 100%;
  height: 100%;

  .lin-location-pin {
    display: none;
  }

  &.merchant {
    .css-tlfecz-indicatorContainer,
    .css-1gtu0rj-indicatorContainer {
      color: #fff;
    }
    .css-yt9ioa-option,
    .slt__option,
    .slt__single-value,
    .slt__value-container {
      color: #000;
      background-color: transparent !important;
      font-size: 13px !important;
      cursor: pointer;
    }
    .slt__value-container--has-value {
      color: #fff;
    }
    .slt__control--is-focused {
      color: #f7f7f7; 
    }
    .css-109onse-indicatorSeparator {
      display: none;
    }
  }

  .css-yk16xz-control,
  .css-1pahdxg-control,
  .css-1fhf3k1-control {
    padding-left: 5px !important;
    border: 0 none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
  }

  .css-yt9ioa-option,
  .slt__option,
  .slt__single-value,
  .slt__value-container {
    font-size: 13px !important;
    font-weight: 500;
    background-color: #fff;
    color: #121213;
    cursor: pointer;
  }

  .slt__value-container,
  .css-1uccc91-singleValue {
    font-size: 16px !important;
    max-height: 35px;
  }

  .slt__control--is-focused {
    border: 0 none !important;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-4ljt47-MenuList {
    padding: 10px;
  }

  .country-lbl {
    // margin-top: 4px;
  }

  .location-text {
    font-size: 12px;
    line-height: 22px;
    color: #0292df;
  }

  .location-description {
    font-size: 11px;
    line-height: 5px;
    margin-bottom: 10px !important;
    color: #93959f;
  }

  .location-content {
    border-bottom: 1px solid #ececec;
  }

  .css-26l3qy-menu {
    width: 210px;

    .css-11unzgr {
      overflow-y: unset;
    }
    .slt__option--is-selected{
      background-color: #f7f7f7;
    }
  }
}

.async-select {
  .slt__menu {
    position: fixed;
    top: 46px;
    left: 0;
    right: 0;
    overflow: auto;
    box-sizing: border-box;
  }
  .css-yt9ioa-option,
  .slt__option {
    font-size: 16px !important;
    line-height: 22px !important;
    color: #121213;
  }
  .css-tlfecz-indicatorContainer {
    display: none;
  }
  .css-1n7v3ny-option {
    background: #f8f8f8;
  }
}
.select-wrap-normal,
.select-wrap-country {
  height: 100%;
  width: 100%;

  .css-yk16xz-control {
    background-color: #f7f7f7;
    border-radius: 4px;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .slt__control--is-focused {
    background-color: #f7f7f7;
  }
}
.select-wrap-country {
  .css-yk16xz-control,
  .css-1pahdxg-control,
  .css-1fhf3k1-control {
    padding-left: 5px !important;
    border: 0 none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
  }

  .slt__control--is-focused {
    border: 0 none !important;
    box-shadow: 0 0 0 0 transparent !important;
  }

  .slt__value-container,
  .css-1uccc91-singleValue,
  .slt__option {
    font-size: 13px !important;
  }
}
.css-g1d714-ValueContainer {
  font-size: 17px !important;
  padding: 2px 9px !important;
}
