/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

.hdfc-mob-footer{
    background-color: #001933;
    @media screen and (min-width: 767px){
        display: none;
    }
    .disclaimer-content {
        font-size: 16px;
        line-height: 28px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s, overflow 0.5s 0.5s;
        // color : #FFFFFF !important;
        padding: 0 16px;
      }
      .disclaimer-content-show {
        font-size: 15px;
        line-height: 28px;
        max-height: 800px;
        overflow: auto;
        transition: max-height 0.5s, overflow 0.5s 0.5s;
        // color : #FFFFFF !important;
        padding: 0 16px;
      }
    .item-1{
        background-color: #143874;
        padding: 18px 16px;
        cursor: pointer;
        .h-1{
            display: flex;
            justify-content: space-between;
            .desc{
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                // color: #FFFFFF;
            }
            .arrw{
                padding-top: 3px;
            }
            .arrw-ar{
                padding-top: 3px;
                transform: rotate(180deg);
            }
        }
        .sub{
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            // color: #FFFFFF;
        }   
    }
    .item-o{
        padding: 16px 16px;
        cursor: pointer;
        .h-1{
            display: flex;
            justify-content: space-between;
            .desc{
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                // color: #FFFFFF !important;
            }
            .arrw{
                padding-top: 3px;
            }
            .arrw-ar{
                padding-top: 3px;
                transform: rotate(180deg);
            }
        }
        .sub{
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            // color: #FFFFFF;
        }
    }
    .sep-line{
        border: 0.5px solid #979797;
        margin-top: 2rem;
        margin-left: 17px;
        margin-right: 17px;
    }
    .maya{
        padding-top: 2rem;
        display: flex;
        justify-content: center;
        padding-bottom: 2rem;
        font-family: 'Noto Sans' !important;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        // color: #FFFFFF;
        // background-color: #FFFBDC !important;
    }
    .rights-content{
        display: flex;
        justify-content: center;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        // color : #FFFFFF !important;
        // background-color: #FFFBDC !important;
    }
    .privacy{
        display: flex;
        justify-content: center;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        // color : #FFFFFF !important;
        // padding-bottom: 6rem;
        // background-color: #FFFBDC !important;
    // .crayon{
    //     display: flex;
    //     justify-content: center;
    //     font-style: normal;
    //     font-weight: normal;
    //     font-size: 13px;
    //     color : #000811 !important;
    //     padding-top: 1rem;
    //     padding-bottom: 3rem;
    //     background-color: #FFFBDC !important;
    }
}