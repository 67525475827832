/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// New Card
@import "../mixins.scss";
@import "../variable.scss";

a:hover,
a:focus {
  text-decoration: none !important;
}

.new-card-div {
  // height: 100%;
  border-radius: 8px;
  &:hover {
    overflow: hidden;
    box-shadow: 0px 8px 32px 5px rgba(0, 0, 0, 0.12);
    text-decoration: none !important;
  }

  .new_card {
    &.mbl {
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    }
    .content {
      // width: 195px !important;
      cursor: pointer;
      display: inline !important;
    }

      //padding: 8px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      position: relative;
      // border-top: 0.8px solid #f0f0f0;
      // border-left: 0.8px solid #f0f0f0;
      // border-right: 0.8px solid #f0f0f0;

      .col-lft {
        display: inline;
        align-items: center;
        justify-content: center;
        background: #303133;
        border-radius: 14px;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #fff;
        text-align: center;
        letter-spacing: -0.08px;
        padding: 5px 12px;
        position: absolute;
        top: 10px !important;
        margin-left: 8px !important;
        font-family: if(
          $clientName == "adib",
          "Bliss_Bold" !important,
          "Noto Sans" !important
        );
        @media screen and (max-width:750px) {
          font-size: 10px;
        }
      }
      .title-ins {
        color: #000000;
        font-size: 22px;
        line-height: 29px !important;
        font-weight: 600;
        letter-spacing: -0.24px !important;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .card_carousel_heartImgDiv {
        position: absolute !important;
        right: -12px !important;
        top: 0px !important;
        z-index: 1;
      }
      .card_carousel_heartImgDivArab {
        position: absolute !important;
        left: 10px !important;
        z-index: 1;
      }
      .heartImgDivArab_min_len {
        position: absolute !important;
        left: 30px !important;
      }
      .col-lft-ar {
        display: inline;
        align-items: center;
        justify-content: center;
        background: #303133;
        border-radius: 14px;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #fff;
        text-align: center;
        letter-spacing: -0.08px;
        padding: 5px 12px;
        position: absolute;
        top: 10px !important;
        right: 8px !important;
      }

      .col-rgt {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      .card-info {
        // margin-bottom: 13px;
        padding: 8px;
      }
      // &:hover {

      //   box-shadow: 0px 8px 32px 5px rgba(0, 0, 0, 0.12);
      // }
      .imagediv{
        width: auto;
        border: 1px solid #e2e2e2;
        border-bottom: 0px !important;
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
        height: 200px;
        object-fit: cover;
        @media screen and (max-width: 750px) {
          height: 135px;   
        }
        // @media screen and (max-width: 399) {
        //   height: 125px;   
        // }
        img{
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          height: 100%;
          background-color: white;
        }
      }

      .offerExp {
        filter: saturate(.05);
      }

    .info-content {
      //    height: 114px;
      // padding: 16px 13px 16px 13px !important;
      top: 0px !important;
      background: #ffffff;
      border: 1px solid #e2e2e2;
      padding: 12px 12px 12px 7px;
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
      @media screen and (min-width: 750px) {
        padding: 16px 13px 16px 13px !important;
      }

      .card-category-div {
        display: flex;
        justify-content: space-between;
      }
      .card-category-name {
        font-family: if(
          $clientName == "adib",
          "Bliss_Bold" !important,
          "Noto Sans" !important
        );
        font-weight: 600 !important;
        font-size: 12px;
        line-height: 18px;
        color: #303133;
        padding-bottom: 12px;
        text-transform: capitalize;
        @media screen and (max-width:750px) {
          padding-bottom: 2px;
          font-size: 10px;
        }
      }
      .taste-match-div {
        display: flex;
        justify-content: flex-end;
        position: relative;

        &:hover .tooltip--score {
          display: inline-block;
        }
      }
      .total-views{
        display: flex;
        flex-direction: row;
        width: 55px;
        height: 20px;
        justify-content: flex-end;
        align-items: center;
        padding: 0 6px 0 0px;
        // margin-right: 5px;
        font-size: 11px;
        font-weight: 600;
        line-height: 18px;
        &:hover .tooltip--total-views{
          display: inline-block;
        }
        &:hover .tooltip--total-views-right{
          display: inline-block;
        }
      }
      .tooltip--total-views{
        border-radius: 8px;
        background: #111827;
        color: #fff;
        letter-spacing: -0.08px;
        display: none;
        padding: 7px;
        position: absolute;
        bottom: 98%;
        width: 170px;
        height: 31px;
        top: -25px;
        // left: 100px;
        right: 10px;
        font-size: 10px;
        font-weight: 600;
        line-height: 15px;
        text-align: center;
        justify-content: center;
      
        &:before{
          border-top: 8px solid #111827;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          content: '';
          position: absolute;
          top: 100%;
          left: 45%;
          transform: translate(50%, 0%);
        }
      }
      .tooltip--total-views-right{
        border-radius: 8px;
        background: #111827;
        color: #fff;
        letter-spacing: -0.08px;
        display: none;
        padding: 7px;
        position: absolute;
        bottom: 98%;
        width: 170px;
        height: 31px;
        top: -25px;
        right: 10px;
        // left: 100px;
        float: right;
        font-size: 11px;
        font-weight: 600;
        line-height: 15px;
        text-align: center;
        justify-content: center;
      
        &:before{
          border-top: 8px solid #111827;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          content: '';
          position: absolute;
          top: 100%;
          left: 80%;
          transform: translate(50%, 0%);
        }
      }
      .taste-match-text {
        min-width: 20px;
        font-weight: 600;
        font-size: 11px;
        line-height: 18px;
        // margin-top: 0 !important;
        // margin-right: 0 !important;
        color: #303133;
      }

      .tooltip--score {
        border-radius: 4px;
        background: #303133;
        color: #fff;
        letter-spacing: -0.08px;
        display: none;
        padding: 5px;
        position: absolute;
        bottom: 98%;
        width: 103px;
        height: 38px;
        top: -45px;
        left: -40px;
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        text-align: center;

        .firLine {
          display: block;
        }

        .secLine {
          color: #f58532;
          text-decoration-line: underline;
        }

        &:before {
          border-top: 8px solid #303133;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          content: "";
          position: absolute;
          top: 100%;
          left: 54%;
          transform: translate(50%, 0%);
        }
      }

      .tooltip--score-arabic {
        right: -40px !important;
        left: 0px !important;
        &:before {
          transform: translate(-240%, 0%) !important;
        }
      }

      .learn-more-text {
        cursor: pointer;
        color: #f58532;
        margin-left: 2px;
        margin-right: 2px;
      }
      .arrow-img {
        display: inline !important;
        margin-left: 2px;
      }
      .info-div {
        color: #000000;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 6px;
        letter-spacing: -0.24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;

        @media screen and (max-width: 600px) {
          max-width: 18em;
          margin-bottom: 2px;
        }

        @media screen and (max-width: 750px) {
          font-size: 14px;
          line-height: 25px;
          margin-bottom: 2px;
        }
      }
      .info-div-trucate{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis !important;
        width: 100%;
        display: block;
      }

      .info-desc {
        color: #303131; 
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;
        min-height: 46px !important;

        @media screen and (max-width: 750px) {
          font-size: 12px;
          line-height: 19px;
          margin-bottom: 2px;
        }
      }

      .card-det {
        color: #303133;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.07px;
        position: absolute;
        bottom: 12px;
        @media screen and (max-width: 750px) {
          bottom: 5px;
          font-size: 10px;
        }
      }

      .expirees-info {
        display: flex;
        font-weight: 700;
        font-family: if(
          $clientName == "adib",
          "Bliss_Bold" !important,
          "Noto Sans" !important
        );
      }
      .expired-info {
        display: flex;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #E30613;
        font-family: if(
          $clientName == "adib",
          "Bliss_Bold" !important,
          "Noto Sans" !important
        );
      }

      .rating {
        display: flex;
        align-items: center;
        .dot {
          width: 3px;
          height: 3px;
          border-radius: 3px;
          background: #c7c7cc;
        }
        .star {
          height: 11px;
          transform: scale(2);
        }
        .rat-tit {
          margin-left: 4px;
        }
      }
    }
    .offerExpContent {
      opacity: 0.6;
    }
    .slick-list {
      margin-top: 24px;
      padding: 0 20% 5% 0;
    }
    .slick-track {
      margin: 0px !important;
      padding-left: 60px;
    }
    .slick-slide {
      padding: 0 8px 0 0;
      box-sizing: border-box;
    }
  }
  .card_carousel_heartImg {
    display: inline-block !important;
    cursor: pointer !important;
    // position: absolute !important;
    margin-top: 13px !important;
  }
  .expireDate {
    margin-right: 55px;
    margin-left: 5px;
    font-weight: 700;
  }
}

.card-cont {
  height: 120px;
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 8px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width:750px)  {
    height:60px
  }
}

.nearby {
  position: relative;
  &:hover {
    .nearby-span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .nearby-span {
    position: absolute;
    width: 100%;
    bottom: 0px;
    background: #e1f2f7;
    height: 25px;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    display: none;
    @media screen and (max-width: 1024px) {
      width: 15vw;
    }
  }
}

.card-image {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 180px;
  max-width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
