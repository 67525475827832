/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Profile Component
@import "../variable.scss";
@font-face {
  font-family: 'Noto_Sans_Medium';
  src: url('../../fonts/NotoSans-SemiCondensedMedium.woff2') format('woff2'),
       url('../../fonts/NotoSans-SemiCondensedMedium.woff') format('woff');
}

$header-notification-content-color: #f7fafc;
.tiny-popup.tiny-popup-card {
  &.tc{
    top: 305px !important;
  }
  top: if($clientName == "hdfc",340px !important,235px !important);
}
.tiny-popup.tiny-popup-card.arabic-lang{
  left: 116px !important;
  top: 270px !important;
}
.tiny-popup {
  top: 323px !important;
  left: -16px !important;
  z-index: 100;
  .profile-content.content-card{
    height:261px !important
  }
  .profile-content {
    background: #fff;
    width: 225px;
    height: if($clientName == "hdfc",300px !important,300px !important);
    padding: 0px !important;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin-right: 75px;
    &.tc{
      height: 260px !important;
    }


    .notification {
      background-color: $header-notification-content-color;
      border-radius: 4px;

      .content {
        padding: 9px 4px;
        width: 185px;
        .text {
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
        }
        .info {
          max-width: 148px;
          font-size: 8px;
          color: #4a5c7b;
        }
      }
    }
    .profile-info {
      font-family: if($clientName == 'adib', 'Bliss 2' !important , 'Noto_Sans_Medium' !important);
      font-size: 13px;
      line-height: 17px;
      font-weight: 500;
      letter-spacing: 0.015em;
      cursor: pointer;
      margin: 0 !important;
      padding: 10px 0px 10px 15px !important;
      &.logout {
        color: if($clientName == "hdfc" ,#005AB7,#ff0000);
      }
    }
    .profile-info:hover{
      background-color: #D0E7FF;
    }
    .line {
      border: 1px solid #ececec;
    }
    .bdg {
      background: #ff0000;
      border-radius: 10px;
      width: 10px;
      height: 10px;
      position: absolute;
      left: 10px;
    }
  }
}

// Notification Slider Style
.pro-cnt-div {
  display: flex;
//  justify-content: flex-start;
  justify-content: space-between;
  align-items: center;
  height: 37px;
  .not-modal {
    background-color: rgba(0, 0, 0, 0.4);
    min-height: 100%;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    position: fixed;
    right: 0;
    z-index: 11;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    .main {
      background-color: #fff;
      position: absolute;
      color: #000;
      right: 0;
      height: 100%;
      padding: 16px 30px;
      &.item {
        height: unset;
      }
      .not-cont {
        .header {
          .fa-cog,
          .fa-bell {
            font-size: 20px;
          }
          .cancel-icn {
            path {
              stroke: #000;
            }
          }
          .border-rht {
            margin: 0 8px;
            border-right: 1px solid #ececec;
          }
        }
        .notification-title {
          font-weight: normal;
          font-size: 15px;
          line-height: 24px;
          color: #000;
        }
        .not-mnu {
          margin-top: 34px;
          border-bottom: 1px solid #ececec;

          .not-nav {
            cursor: pointer;
            height: 34px;
            color: #8e8e93;
            margin-right: 42px;

            &-act {
              color: #0292df;
              border-bottom: 3px solid #0292df;
            }
          }
        }
        .not-list {
          padding-top: 12px;
          padding-bottom: 12px;
          border-bottom: 1px solid #e7e7e7;

          &:last-child {
            border-bottom: 1px solid transparent;
          }

          .image-itm-div {
            background: #ffffff;
            margin-right: 10px;
            border-radius: 2px;
            width: 84px;
            height: 84px;
          }

          .col-lft {
            .col {
              &-header {
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: -0.24px;
                color: #000000;
              }
              &-des {
                font-size: 13px;
                line-height: 18px;
                color: #8e8e93;
              }
              &-date {
                font-weight: 500;
                font-size: 12px;
                line-height: 13px;
                letter-spacing: 0.07px;
                color: #616161;
              }
            }
          }
        }
      }
    }
  }
  #login-hdfc{
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #FFFFFF;
    background-color: #005AB7;
    border: 1px solid #005AB7;
    &:hover{
      background-color: #1D86FF;
    }
  }
}
