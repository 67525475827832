/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/


// Login Page
@import "../mixins.scss";

.login-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  .login-header {
    background-color: #fff;
    height: 60px;
    padding: 10px 100px;
  }
  .content {
    min-height: 100%;
    background-color: #efeefe;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .logintitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }
  .item-detailbox {
    background-color: #fff;
    border-radius: 4px;

    &.inputError {
      border: 1px solid red;
    }
  }
  .login-div {
    width: 100%;

    @include media-break("lg") {
      max-width: 452px;
      min-width: 452px;
    }
  }
  .login-inputbox {
    height: 52px;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .login-input {
    border: none !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #4c4c4c !important;
  }
  .login-button {
    background: #F58532;
    border-radius: 4px;
    color: #fff;
    font-size: 16px !important;
    line-height: 19px !important;
    min-width: 150px;
  }
  .btn-ttl {
    width: 100%;
  }
  .forgotpwd {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #787b86;

    .reset {
      font-weight: 600;
    }
  }
  .footer-menu {
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
  }
}
