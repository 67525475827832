/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Button Component

.btn {
  width: 100%;
  height: 42px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  vertical-align: middle;
  overflow: hidden;
  outline: 0;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;

  &-prm {
    // background-color: #F58532;
    background-color: #469DDE;
    color: #fff;
    border-color: #fff;

    &:hover {
      // background-color: #F58532;
      background-color: #469DDE;
      color: #dee1f4;
    }

    &:focus {
      color: #fff;
    }

    &.focus {
      border-color: #5460c4;
      box-shadow: 0 0 0 1px darken(#5460c4, 15%);
      outline: 0;
      color: #fff !important;
    }

    &-dis {
      background-color: #b3bcf5;
      color: #989aa8;
    }
  }

  &-scs {
    background-color: #34C759 !important; 
    color: #fff;
    border-color: #fff;

    &:hover {
      background-color: #34C759;
      color: #dee1f4;
    }

    &.focus {
      border-color: #34C759;
      box-shadow: 0 0 0 1px darken(#34C759, 15%);
      outline: 0;
    }

    &-dis {
      background-color: #b3bcf5;
      color: #989aa8;
    }
  }

  &-sec {
    background-color: #ffffff;
    color: #586068;
    border-color: #c7cacd;

    &:hover {
      background-color: #f4f4f5;
      border-color: darken(#f4f4f5, 20%);
      color: #49505a;
    }

    &.focus {
      border-color: #637381;
      box-shadow: 0 0 0 1px darken(#637381, 15%);
      outline: 0;
    }

    &-dis {
      background-color: #ffffff;
      color: #acb6c0;
    }
  }

  &-err {
    background-color: #e03719;
    color: #f9e6e2;
    border-color: #ca2e2e;

    &:hover {
      background-color: #d23317;
      border-color: darken(#d23317, 20%);
      color: #f9e6e2;
    }

    &.focus {
      border-color: #96090e;
      box-shadow: 0 0 0 1px darken(#96090e, 15%);
      outline: 0;
    }

    &-dis {
      background-color: #f9a998;
      color: #fef4f2;
    }
  }
  &-offers{
    background: #000000;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.41px;
    color: #FFFFFF;
  }

  &-rew {
    background-color: #469DDE;
    color: hsl(10, 66%, 93%);
   // border-color: #222222;

    &:hover {
      background-color: #469DDE;
      border-color: #469DDE;
      color: #f9e6e2;
    }

    &.focus {
      border-color: #5d6d7e ;
      box-shadow: 0 0 0 1px darken(#5d6d7e, 15%);
      outline: 0;
    }

    &-dis {
      background-color: #909497 ;
      color: #222222;
    }
  }

  &-lgn {
    background-color: #737ea8;
    color: #ffffff;
    border-color: #b6bcd1;
    border-radius: 50px;

    &:hover {
      background-color: darken(#737ea8, 10%);
      border-color: darken(#737ea8, 20%);
      color: #f9e6e2;
    }
  }

  &-sml {
    height: 18px;
    border-radius: 3px;
    font-size: 8px;
    padding: 0 8px;
    min-width: auto;
  }

  &-med {
    height: 21px;
    border-radius: 3px;
    font-size: 9px;
    padding: 0 9px;
  }
  &-big {
    height: 25px;
    border-radius: 3px;
    font-size: 11px;
    padding: 0 16px;
  }

  &-lrg {
    height: 42px;
    border-radius: 50px;
  }
}