.dropdown {
  width: fit-content;
}

.dropdown-toggle::after {
  display: none;
}
.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 10px;
  margin-bottom: 3px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.btn-default {
  font-size: 14px;
  font-weight: 400;
  padding: 10px 15px;
  border: 1px solid #d5d8db !important;
  border-radius: 2px;
  color: #000;
}

.btn-default:hover {
  color: #000 !important;
  background-color: #fff !important;
}

.dropdown-text {
  color: #000;
  font-weight: 400;
  font-size: 14px;
}

.dropdown-value-text {
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}

.dropdown-menu {
  padding: 1rem;
  width: 100%;
  top: -4px !important;
  background: #ffffff;
  color: #000;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  max-height: 160px;
  overflow-y: scroll;
  scrollbar-width: thin;
}
.dropdown-menu::-webkit-scrollbar {
  width: 4px;
}
.dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #d5d8db;
  border-radius: 50px;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: #000;
  cursor: pointer;
  width: 15px !important;
  height: 15px !important;
  border: 0.05em solid #ebf2f9;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
  border-radius: 4px;
  border: 1px solid #000;
}

input[type="checkbox"]::before {
  content: "";
  width: 15px;
  height: 15px;
  cursor: pointer;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #469dde;
  background-image: url("../../../components/Icons/Svgs/tick.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8px;
  border-radius: 4px;
  border: 1px solid transparent;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.dropdown-menu li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.dropdown-menu label {
  margin-left: 10px;
  color: #000;
}

.labelSort {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  padding: 5px;
}

.labelSort-hilight {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  background-color: #469dde;
  padding: 5px;
}

.labelSort:hover {
  background-color: #D0E7FF;
}

.label {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  &.hilight {
    color: #fff;
  }
}

.label-for-check {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
}

.selectall:checked + .label-for-check {
  color: #469dde;
  font-weight: 700;
}

.dropdown-list {
  position: absolute;
  padding: 1rem;
  width: 100%;
  top: 0px !important;
  background: #ffffff;
  color: #000;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  transform: translate3d(0px, 42px, 0px);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdown-list li {
  display: flex;
  align-items: baseline;
}
.dropdown-list label {
  margin-left: 10px;
  color: #000;
}

.dropdown-backdrop{
  position: static;
}