/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Modal Style
.mdl {
  align-items: center;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  outline: 0;
  // padding: 16px;
  width: 100%;
  perspective: 800px;
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
  opacity: 0;
  transform-origin: top center;
  &[data-enter] {
    opacity: 1;
  }

  &:focus {
  /*  border: 1px solid #3b6ce9;  */
  }

  &-blk {
    display: block;
    margin-bottom: 10px;
  }

  &-bck {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    justify-content: center;
    left: 0;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 250ms ease-in-out;
    z-index: 999;
  }

  &-hdr {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: flex-start;
    padding: 12px 10px;
    width: 100%;

    &-tit {
      flex-grow: 1;
      text-align: center;
    }

    &-cls {
      flex: 0 0 1%;
      max-width: 30px;
      min-width: 30px;
    }
  }

  &-cnt {
    // padding: 0 40px;
    width: 100%;

    &-gsm {
      padding: 0 10px;
    }

    &-gno {
      padding: 0;
    }
  }

  &-ftr {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 108px;
    justify-content: center;
    padding: 18px 10px 50px;
    width: 100%;
  }
}

@include media-break("md") {
  .mdl {
    border: 1px solid #ccc;
    border-radius: 16px;
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
    height: auto;
    margin: 100px auto;
    width: 50%;

    &-sml {
      max-width: 460px;
    }

    &-med {
      width: 50%;
      margin-top: 100px !important;
    }

    &-big {
      width: 75%;
    }

    &-full {
      width: 100%;
    }
  }
}

@media(max-width: 600px)  {
  .mdl {
    border: 1px solid #ccc;
    border-radius: 16px;
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
    height: auto;
    margin: 100px auto;
    width: 90% !important;
    &-redeem{
      width: 96% !important;
      border-radius: 16px;
    }
    &-med {
      width: 50%;
      margin-top: 170px !important;
      max-width: 450px;
    }
  }
}