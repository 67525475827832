.search-bar-divv {
    padding: 30px 17px;
    background: #FFFFFF;
    height: calc(100vh - 65px);
    position: absolute;
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    @media (min-width:934px) {  
      display: none !important;
      }
    .top-part {
      display: flex;
      justify-content: space-between;
      .main-title {
        font-weight: 600;
        font-size: 26px;
        line-height: 36px;
        color: #000811;
        text-align: left;
        &.ar{
          font-family: inherit !important;
          text-align: right !important;
        }
        .location {
          text-decoration: underline;
          color: #004388
        }
        &.arTitle {
          font-family: 'droid-sans-arabic' !important;
          text-align: right;
          .location {
            font-family: 'droid-sans-arabic' !important;
          }
        }
      }
      .close-icon {
        margin-top: 0px;
        position: relative;
        bottom: 25px;
      }
    }
  
    .search-container {
      float: left;
      width: 100%;
      .auto-suggest-wrap {
        position: relative;
        top: 24px;
        .icon-wrap {
          position: absolute;
          top: 10px;
          z-index: 1;
          &.arIcon {
            right: 10px !important;
          }
          &.enIcon {
            left: 12px !important;
          }
        }
        .icon-wrap-ar {
          position: absolute;
          top: 16px;
          right: 10px;
          z-index: 1;
        }
        .search-text {
          position: absolute;
          top: 10px;
          right: 15px;
          z-index: 1;
          span {
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #143973;
            letter-spacing: -0.24px;
            text-transform: uppercase;
            cursor: pointer;
          }
        }
        .search-text-ar {
          position: absolute;
          top: 10px;
          left: 30px;
          z-index: 1;
          font-family: inherit !important;
          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #143973;
            letter-spacing: -0.24px;
            text-transform: uppercase;
          }
        }
        .react-autosuggest__input {
          border: 1px solid #dfdfdf;
          border-radius: 2px;
          padding: 15px 38px !important;
          width: 100% !important;
          outline: none !important;
          font-size: 14px !important;
          font-weight: 500 !important;
          line-height: 22px !important;
          letter-spacing: -0.41px !important;
          color: #999999 !important;
          margin: 0 !important;
          position: relative;
          top: 0px !important;
          left: 0px !important;
        }
        &.arCustm {
          font-family: 'droid-sans-arabic' !important;
          .react-autosuggest__input {
            padding: 13px 35px 13px 13px !important;
            font-family: 'droid-sans-arabic' !important;
          }
        }
        &.enCustm {
          .react-autosuggest__input {
            padding: 15px 38px !important;
          }
        }
        .react-autosuggest__suggestions-container {
          background-color: #fff;
          font-weight: 300;
          font-size: 16px;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          z-index: 2;
          position: absolute;
          top: 40px;
          left: 0;
          right: 0;
          overflow: auto;
          transition: opacity 250ms ease-in-out;
          .react-autosuggest__suggestions-list {
            margin: 0 !important;
            padding: 0px !important;
            max-height: 150px;
            list-style-type: none;
            overflow-y: auto;
            text-align: var(--alignSuggestions, left);
          }
        }
        .react-autosuggest__suggestion {
          cursor: pointer;
          padding: 3px 20px !important;
        }
        .react-autosuggest__suggestion:focus,
        .react-autosuggest__suggestion:hover {
          background-color: #f7f7f7 !important;
        }
      }
    }
    .tag-options {
      position: relative;
      top: 40px;
      .recent-title {
        font-family: 'Bliss 2' !important;
        text-align: left;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.24px;
        font-weight: 400 !important;
        color: #262626;
        &.arPop {
          font-family: 'droid-sans-arabic' !important;
          text-align: right !important;
        }
      }
      .recent-title-ar {
        font-family: inherit !important;
        text-align: right;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.24px;
        color: #262626;
      }
      .flex-item {
        margin-top: 26px;
        flex-wrap: wrap;
        display: flex;
        margin-bottom: 30px;
        .card-cont {
          font-size: 15px;
          font-weight: 500 !important;
          padding: 0 17px;
          line-height: 18px;
          height: 36px;
          flex-direction: column;
          margin-right: 15px;
          margin-bottom: 15px;
          border-radius: 4px;
          border: 1px solid #143973;
          color: #143973;
          text-transform: uppercase;
          cursor: pointer;
          &.arCont {
            margin-left: 15px !important;
            margin-right: 0 !important;
            font-family: 'droid-sans-arabic' !important;
          }
        }
      }
      .recent-list {
        font-size: 15px;
        font-family: "Bliss_Light";
        &.ar{
          font-family: inherit !important;
        }
        div {
          display: flex;
          cursor: pointer;
        }
      }
  
      &.sec {
        margin-top: 20px;
      }
    }
  }
  
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }