/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Customer Support
@import "../mixins.scss";

.customer-div {
  margin-bottom: 144px;
  .customer {
    &-title {
      font-size: 18px;
      line-height: 16px;
      color: #787b86;
    }
    &-banner {
      background: #fafbfc;
      border-radius: 6px;
      padding: 14px 15px;
      flex-wrap: wrap;

      @include media-break("lg") {
        flex-wrap: unset;
      }

      .col {
        &-lft {
          flex-direction: column;
        }
        &-header {
          font-weight: 600;
          font-size: 17px;
          line-height: 23px;
          color: #000000;
        }
        &-des {
          width: 100%;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.24px;
          color: #8e8e93;
          margin-bottom: 10px;

          @include media-break("lg") {
            margin-bottom: 0;
          }
          @include media-break("xxl") {
            width: 500px;
          }
        }
        &-rgt {
          align-self: center;
        }
      }
    }
  }
  .line {
    border: 0.9px solid #e7e7e7;
    margin-bottom: 36px;
  }
  .dark {
    color: #000;
  }
  .support-button{
    width: 90px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    border-radius: 4px;
    vertical-align: middle;
    text-align: center;
//    padding: 10px 15px;
    font-size: 13px;
//    line-height: 18px;
    cursor: pointer;
    color: #fff;

    &.reply {
      width: 68px !important;
      height: 28px !important;
  //    padding: 5px 10px;
    }
  }
}
