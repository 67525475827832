/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/
@import "../variable.scss";

// Header Component
.header {
  .header-content {
    padding-bottom: 10px;
    border-bottom: 1px solid #ececec;
    align-items: center;
  }
  .location-text{
    cursor: pointer;
    margin-left: 12px;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.41px;
    color: #212121;
    text-transform: capitalize;
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .not-loc-modal {
    background-color: rgba(0, 0, 0, 0.4);
    min-height: 100%;
    bottom: 0;
    left: 0;
    //overflow-y: auto;
    position: fixed;
    right: 0;
    z-index: 100;
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
    .main-arabic{
      right:0 !important;
      .search-location{
        input[type="text"]{
          padding: 16px 20px !important;
        }
        input[type="text"]:focus{
          border: 1px solid #000 !important;
          outline: none !important;
        }
      }
      .gps{
        padding:22px 21px !important;
      }
      .gps-title{
        padding-right: 30px !important;
      }
      .recent-location{
        padding:19px !important;
      }
    }
    .main {
      background-color: #fff;
      position: absolute;
      color: #000;
      left: 0;
      height: 100%;
      padding: 50px 44px;
      overflow: auto;
      &.mbl{
        padding: 23px 12px 23px 14px;
        width: 96%;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        overflow: unset;
        ::-webkit-scrollbar {
          width: 2px;
        }
        
        /* Track */
        ::-webkit-scrollbar-track {
          background: #E7E7E7 
        }
         
        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #000000;
        }
      }
      .search-location{
        margin-top: 50px;
        width: 100%;
        position: relative;
        .search-icn{
          position: absolute;
          top:9px;
          left: 8px
        }
        &.mbl{
          margin-top: 24px;
        }
        input[type="text"]{
          width: 100%;
          border: 1px solid #E3E3E3;
          box-sizing: border-box;
          box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.12);
          border-radius: 4px;
          padding: 16px 26px;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.25px;
          @media(max-width:932px){
            background: rgba(118, 118, 128, 0.12);
            border-radius: 4px;
            box-shadow: none; 
            padding: 8px 31px;
          }
        }
        input[type="text"]::placeholder{
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.25px;
          color: #A9ABB1 !important;
          @media(max-width:932px){
            font-weight: 400;
            font-size: 17px;
            line-height: 22px;
            letter-spacing: -0.408px;
            color: rgba(60, 60, 67, 0.6) !important;
          }
        }
        input[type="text"]:focus{
          border: 1px solid #000 !important;
          outline: none !important;
        }
      }
      .gps{
        margin-top: 30px;
        padding:22px 26px;
        border: 1px solid #E3E3E3;
        box-sizing: border-box;
        border-radius: 4px;
        &.mbl{
          border: none;
          padding: 0;
          margin-top: 24px;
        }
      }
      
.recent-location{
  margin: 30px 0px;
  padding:10px 26px;
  border: 1px solid #E3E3E3;
  box-sizing: border-box;
  border-radius: 4px;
  &.mbl{
    margin: 24px 0px;
    padding: 0;
    border: none;
    overflow : auto;
    //height: calc(100% - 236px);
    width: 100%;

  }
  .recent-gps-text{    
    width: 100%;     
    margin:0px 13px;
    display: flex;
    flex-direction: column;
    padding-bottom: 18px ;
    border-bottom: 1px dashed #E3E3E3;
    cursor: pointer;
    &.mbl{
      padding-top: 11px;
      padding-bottom: 11px;
      border-bottom: 0.5px solid #C6C6C8;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
     .sub-text{
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.408px;
      font-weight: 400;
      color: #000000;
      &.act {
        font-weight: 600;
        // color: #003978;
      }
    }
  }   
 }
}
.recent-location .box:last-child .recent-gps-text{
  border-bottom: 0px;
}
.box{
  display: flex;
  justify-content: flex-start;
  padding-top: 18px;
  &.mbl{
    margin-top: 0px;
    padding: 0px;
  }
}

.gps-title{
  margin-bottom: 6px;
  margin-top: 9px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  margin-left: 32px;
  color: if($clientName == 'adib', #143973 !important , #727272 !important);
  text-transform: uppercase;
}
.gps-text{
  width: 100%;       
  margin:0px 13px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.sub-text{
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.25px;
  color: if($clientName == 'adib', #292C3E, #000811 );
  font-family: if($clientName == 'adib','Bliss_Bold' !important , 'Noto Sans' !important);
  &.act {
    color: #003978;
  }
}
.sub-text.gps-sub-text{
  // color : #005AB7;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.41px;
}
.support-text{
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  margin-top: 4px;
  // color:  if($clientName == 'adib', #404040 !important , #727272 !important);
  &.mbl{
    font-size: 13px;
    line-height: 22px;
    letter-spacing: -0.41px;
    color: #1A2C4A !important;
  }
  &.act {
    color: #003978 !important;
  }
}
.location-auto-suggest{
  position: absolute;
  max-height: 140px;
  border: 1px solid;
  width: 100%;
  padding: 16px 26px;
  border: 1px solid #D4D5D9;
  border-top: 0px;
  background-color: #ffffff;
  z-index: 1000;
}
  &.item {
    height: unset;
  }
    .not-cont {
      height: 100%;
      .header {
        .fa-cog,
        .fa-bell {
          font-size: 20px;
        }
        .title{
          font-weight: 600;
          font-size: 22px;
          line-height: 28px;
          letter-spacing: 0.35px;
        }
        .abs-close {
          cursor: pointer;
          right: 20px;
          top: 8px;
          background: #E7E7E7;
          width: 30px;
          height: 30px;
          border-radius: 42px;
          z-index: 1;
          svg {
            path {
              stroke: #000;
            }
          }
        }
        .cancel-icn {
          path {
            stroke: #000;
          }
        }
        .border-rht {
          margin: 0 8px;
          border-right: 1px solid #ececec;
        }
      }
      .title-desc{
        margin-top: 4px;
        width: 70%;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.24px;
      }
      .notification-title {
        font-weight: normal;
        font-size: 15px;
        line-height: 24px;
        color: #000;
      }
      .not-mnu {
        margin-top: 34px;
        border-bottom: 1px solid #ececec;

        .not-nav {
          cursor: pointer;
          height: 34px;
          color: #8e8e93;
          margin-right: 42px;

          &-act {
            color: #0292df;
            border-bottom: 3px solid #0292df;
          }
        }
      }
      .not-list {
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid #e7e7e7;

        &:last-child {
          border-bottom: 1px solid transparent;
        }

        .image-itm-div {
          background: #ffffff;
          margin-right: 10px;
          border-radius: 2px;
          width: 84px;
          height: 84px;
        }

        .col-lft {
          .col {
            &-header {
              font-weight: 600;
              font-size: 15px;
              line-height: 18px;
              letter-spacing: -0.24px;
              color: #000000;
            }
            &-des {
              font-size: 13px;
              line-height: 18px;
              color: #8e8e93;
            }
            &-date {
              font-weight: 500;
              font-size: 12px;
              line-height: 13px;
              letter-spacing: 0.07px;
              color: #616161;
            }
          }
        }
      }
    }
  }
}
  .header-mobile-view {
    display: flex;
    padding-top: 10px;
    .select-wrap-country {
      width: 100px;
    }
    .menu-item-mobile {
      padding: 10px 10px 0 10px;
    }
    .search-content {
      .async-select {
        .css-2b097c-container {
          width: 170px;
        }
        .css-1wa3eu0-placeholder {
          font-size: 10px;
        }
      }
      .react-autosuggest__input {
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .image-item {
      margin-right: 1.275rem !important;
      transition: all 1s !important;
      @media screen and (max-width: 768px) {
        margin-right: 0 !important;
      }
    }
    .hide-image {
      margin-left: -122px;
      margin-right: 11px;
      transition: all 1s;
    }
    .search-contain {
      width: 100%;
      justify-content: space-between;
    }
  }
  .none {
    display: none;
  }
  .hide-search-mobile .react-autosuggest__input {
     display: none;
  }
  .mobile-country-select {
    // margin-left: 60px;
  }
  .mobile-profile-dropdown {
    // position: absolute;
    // right: 40px;
    z-index: 1000 !important;
    // background-color: "#ffffff";
  }
  .none-profile {
    opacity: 0;
    pointer-events: none;
  }
  .half-div {
    transition:width 1s;
    -webkit-transition:width 1s;
    -moz-transition: width 1s;
    display: flex;
    align-items: center;
    padding-left: 15px !important;
    z-index: 10 !important;
  }
  .full-div {
    transition: 1s;
    transition-timing-function: ease;
    display: flex;
    align-items: center;
    padding-left: 15px !important;
    z-index: 100000 !important;
    width: 100%;
  }
  .icon-remove {
    path {
      stroke: #000;
    }
  }
  .country-select {
    border-right: 1px solid #ececec;
    border-left: 1px solid #ececec;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 20px;
    padding-left: 15px;
  }
  .border-right {
    border-right: 1px solid #ececec;
  }
  .line {
    border: 1px solid #ececec;
  }
  .search-icon {
    cursor: pointer;
    padding-right: 8px;
    justify-content: flex-end;
    @media screen and (max-width: 767px) {
      padding-right: 0px !important;
    }
    .fa-search {
      font-size: 16px;
      margin-top: 12px;
      position: relative;
      bottom: 4px;
    }
    svg {
      // margin-top: 12px;
      path {
        stroke: #000;
      }
    }
  }
  .opacity-div {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    left: 0;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 64px;
    z-index: 100;
  }

  .username {
    @media screen and (max-width: 1366px){
      // width: 85px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .myacc-lbl {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    color: #212121
  }
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 38px;
  padding: 10px 1px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;
  left: 7px;
}

.react-autosuggest__input::placeholder {
  font-size: 15px;
  line-height: 22px;
  color: if($clientName == 'adib', #999999 !important , rgba(142, 142, 147, 0.6) !important);
  letter-spacing: -0.408px;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-list {
  list-style-type: none;
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto;
  @media screen and (max-width: 1499px) {
    margin-right: 74px !important;
    margin-left: 34px !important;
  }
}

.react-autosuggest__suggestion--highlighted {
  background-color: if($clientName == 'adib',#f7f7f7 !important , #D0E7FF  !important );
  color: #004388;;
}

.react-autosuggest__suggestions-container {
  // box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  font-weight: if($clientName == 'adib', 300, 500);
  font-size: 16px;
  color: #000811;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  position: fixed;
  top: 60px;
  left: 0px;
  right: 0;
  overflow: auto;
  transition: opacity 250ms ease-in-out;
}
.react-autosuggest__suggestions-container--open{
  @media screen and (max-width: 899px) {
    left: -15px;
    top: 50px;
    right:-15px;
  }
  @media screen and (min-width :900px) and (max-width: 933px) {
    left: -30px;
    top: 50px;
    right:-30px;
  }
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 3px 0px !important;
  @media screen and (min-width: 934px) {
    padding: 4px 0px 4px 12px !important;
  }

  &:focus,
  &:hover {
    background-color: #ddd;
  }
}


input[disabled] {
  cursor: default;
  background: transparent;
}

.react-autosuggest__suggestion--focused {
  background-color: #ddd;
}
.react-autosuggest__input::placeholder {
  color: rgba(142, 142, 147, 0.6);
}
.wishlistedDiv {
  padding: 5px 36px 0px 36px !important;
  @media screen and (max-width: 1024px){
    padding: 0px 22px;
  }

  @media screen and (max-width: 1200px){
    padding: 0px 22px;
  }
}
.myAccountText {
  font-size: 15px;
  font-weight: 500;
}
.headerRightDiv {
  display: flex;
  align-items: center;
  position: absolute;
   right: 0px;
   border-left: 1px solid #ececec;
}
.headerLeftDiv {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0px;
  border-right: 1px solid #ececec;
}
.searchDiv {
  width: 320px !important;
  @media screen and (max-width: 1200px) {
    width: 230px !important;
  }
}
.arabic-search {
  padding-left: 8px !important;
  padding-right: 15px !important;
  @media screen and (max-width: 992px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.arabic-location-icon {
  padding-left: 12px;
}
.search-close-button {
  min-width: 100px;
}
.language-setting{
  ul{
    padding-left: 15px;
    padding-right: 15px;
    li{
      list-style-type: none;
      padding-top: 10px;
    }
  }
}
.animate-slider {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeft {
  from {
      opacity:0;
      -webkit-transform: translatex(-400px);
      -moz-transform: translatex(-400px);
      -o-transform: translatex(-400px);
      transform: translatex(-400px);
  }
  to {
      opacity:1;
      -webkit-transform: translatex(0);
      -moz-transform: translatex(0);
      -o-transform: translatex(0);
      transform: translatex(0);
  }
}
@keyframes fadeInLeft {
  from {
      opacity:1;
      -webkit-transform: translatex(-400px);
      -moz-transform: translatex(-400px);
      -o-transform: translatex(-400px);
      transform: translatex(-400px);
  }
  to {
      opacity:1;
      -webkit-transform: translatex(0);
      -moz-transform: translatex(0);
      -o-transform: translatex(0);
      transform: translatex(0);
  }
}
