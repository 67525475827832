/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// All Offer Component
@import "../mixins.scss";
@import "../variable.scss";
@font-face {
  font-family: 'Noto_Sans_CondMed';
  src: url('../../fonts/NotoSans-SemiCondensedMedium.woff2') format('woff2'),
       url('../../fonts/NotoSans-SemiCondensedMedium.woff') format('woff');
}

.alloffer {
  // padding: 0px !important;
  .map-lst-row {
    margin-top: 20px;
    display: flex;
    .List-view {
      width: 35%;
      .sec-pag{
        .card-paginate-map {
          margin: 20px 0 0 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .circle {
            &.box-shadow {
              box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.12);
            }
          }
          .number {
            align-self: center;
            margin: 0 4px;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 20px;
            width: 32px;
            height: 32px;
            border-radius: 32px;
        
            &-act {
              color: #fff;
              background-color: #000;
            }
          }
          .item-count {
            display: flex;
            justify-content: center;
            text-align: center;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: #222222;
            margin-bottom: 70px;
            margin-top: 10px;
          }
        }
      }
    }
    .map-view-des {
      width: 65%;
      height: 100%;
      position: relative;
      .map-view-loader-des {
        position: absolute;
        width: 100%;
        height: 710px;
        border-radius: 20px;
        background: hsla(0deg, 0%, 100%, 0.9);
        opacity: 0.75;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .loader-map-des {
          width: 50px;
         
            @keyframes rotate {
              100% {
                transform: rotate(360deg);
              }
            }
            .circular-loader {
              animation: rotate 2s linear infinite;
              .loader-path {
                fill: none;
                stroke: #b0b7c0;
                stroke-width: 3px;
                stroke-linecap: round;
                stroke-dasharray: 10, 10;
              }
            }
          
        }
      }
      .map-des {
        position: relative;
      }
    }
  }

  padding-left: 15px;
  @include media-break("lg") {
    // padding: 0px !important;
  }
  &.wishlist-div {
    margin-bottom: 144px;
  }
  .notification-banner {
    margin-right: 60px;
    margin-left:30px !important;
    background: #0b2d33;
    border-radius: 8px;
    padding: 27px 28px;
    margin-bottom: 45px;
    flex-wrap: wrap;
    display: inline-block !important;

    @include media-break("md") {
      flex-wrap: unset;
    }

    &.search-page {
      margin-bottom: 0;
    }

    .col-lft {
      padding-right: 38px;
    }
    .col-rgt {
      .col-header {
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        line-height: 25px;
        margin-bottom: 15px;
        color: #ffffff;

        .highlight {
          color: #3dc93f;
        }
      }
      .col-des {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 21px;
        letter-spacing: -0.24px;
        color: #f4f4f4;
      }
    }
  }
  .search-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #252220;
    margin-bottom: 10px;

    @include media-break("lg") {
      margin-bottom: 0 !important;
    }
  }
  .bldTxt {
    font-family: if($clientName == 'adib','Bliss_Bold' !important , 'Noto Sans' !important);
  }
  .title-header {
    // margin-right: 60px !important;
    // margin-left:30px !important;
    // flex-wrap: wrap;
    .sort,
    .map-view {
      font-family: if($clientName == 'adib','Bliss_Bold' !important , 'Noto_Sans_CondMed' !important);
      font-style: normal;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: #262626;
      margin-right: 18px;
      align-self: center;
    }

    .map-view {
      margin-right: 0 !important;
      margin-left: 18px;
    }

    .vertical-line {
      border-right: 1px solid #ececec;
    }

    .hyp-title{
      font-size: 24px;
      font-weight: 600;
      letter-spacing: -0.24px;
      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
  }
  .offerlist {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-left: -10px;
    // padding:0px 20px 0px 0px

    @media screen and (max-width: 768px) {
      justify-content: center;
    }

    @media only screen and (max-width: 450px) {
      margin-left: 0px !important;
    }


    .wishlist-section{
      padding:10px 10px 10px 10px;

      @media screen and (max-width: 600px) {
        padding: 10px 0;
        width: 100%;
        width: -moz-available;          /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
        width: fill-available;
      }
      @media screen and (max-width: 450px) {
        padding: 10px 0;
        width: 90%;
        width: -moz-available;          /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
        width: fill-available;
      }
    }
  }

  .otherSearch {
    position: relative;
    top: 28px;
    margin-bottom: 36px;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.24px;
    color: #000811;
  }
  
  .card-paginate {
    margin: 20px 0 0 0;
  //  width: 298px;
    display: flex;
    justify-content: center;
    align-items: center;
    .circle {
      &.box-shadow {
        box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.12);
      }
    }
    .number {
      align-self: center;
    //  margin: 0 4px;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 20px;
      width: 32px;
      height: 32px;
      border-radius: 32px;

      &-act {
        color: #fff;
        background-color: rgba(0, 67, 136, 1);
      }
    }
  }
  .item-count {
    text-align: center;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #727272;
    margin-bottom: 70px;
  }
}
.crdDiv {
  // padding: 10px;

  @media screen and (max-width: 600px) {
    // padding: 10px 0;

    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
  }
}

.slide-ser {
  // border: 1px solid black;
}
@media (min-width: 992px) {
  .slide-ser {
    width: 23% !important;
  }
}

@media (max-width: 810px) {
  .slide-ser {
    width: 47% !important;
  }
}

@media screen and (max-width: 450px) {
  .slide-ser {
    width: 100% !important;
    margin: 0px !important;
  }
}

.slide-ser:hover {
  transition: 0.3s;
  transform: translate(0, -10px) !important;
  box-shadow: 0px 8px 32px 5px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}
.noResultDiv {
  text-align: center;
  width: 100%;
  font-size: 14px;
  padding-bottom: 20px;
  margin-top: 138px;

  .noResultTitle {
    font-weight: normal;
    font-size: 30px;
    line-height: 41px;
    letter-spacing: -0.24px;
    color: #000811;
    margin-top: 24px;
    font-family: if($clientName == 'adib','Bliss_Bold' !important , 'Noto Sans' !important);
    @media (max-width:768px){
      font-size: 18px;
    }
  }
  .noResultSubTitle {
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #727272;
    width: 45%;
    padding-top: 8px;
    margin:auto;
    padding-bottom: 200px;

    @media screen and (max-width: 768px) {
      width: 50%;
      font-size: 18px;
    }
  }
}
