/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/


$primary-color: #006fcf;
$secondary-color: #fff;
$tertiary-color: #00175a;
$light-bg-blue: #0093d2;
$success: #52c023;
$dark-green: #389e0c;
$danger: #d71a21;
$light-color: #7ac1ff;
$mild-color: #038aff;
$gray-color: #ccc;
$warning: #b42c01;
$bright-blue: #006fcf;
$yellow: #fdb92d;
$gray-01: #f7f8f9;
$gray-02: #ecedee;
$gray-03: #c8c9c7;
$gray-04: #97999b;
$gray-05: #53565a;
$gray-06: #333333;
$clientName:'hdfc'; //change client name to get color in banner slick slider
/*  ["adib", "maya","hdfc"]   */
