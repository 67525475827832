/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/


// Common
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import "react-date-picker/dist/DatePicker.css";
@import "./adib-fonts.scss";
@import "./variable.scss";


@font-face {
  font-family: 'SF Pro Text';
  src: url("../fonts/SFProText-Regular.ttf") format("truetype");
}

/* Set core body defaults */
body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5 !important;
  font-weight: 400;
  font-size: 1rem;
  font-family: if($clientName == 'adib','Bliss 2' !important , 'Noto Sans' !important);
  background-color: #fff;
}
