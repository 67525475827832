
.stop-scrolling {
    overflow-y: hidden !important;
}
.ReactModal__Overlay--after-open {
    overflow-y: auto; /* prevents background page from scrolling when the modal is open */
  }
.ReactModal__Content {
    &.ReactModal__Content--after-open{
        @media screen and (min-width: 768px) {           
          height: 560px;
        }
    }
}
.lmodal{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    &-logo{
        position: relative;
        background: #D0E7FF;
        width: 33%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-content: center;
        img{
            //position: absolute;
            margin-top: -20px;
            margin-left: 40px;
            margin-right: 23px;
        }
        span{
            display: block;
            //position: absolute;
            margin-top:19px;
            margin-left: 31px;
            margin-right: 31px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
        }
        
        .mdl-fot {
            position: absolute;
            bottom: 27px;
            left: -10px;
            .tit-txt {
                font-family: 'Noto Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #001122;
            }
        }
    }
    &-content{
        width: 67%;
        height: 100%;
        padding-top: 20px;
        padding-left: 40px;
        padding-right: 60px;
        position: relative;
        .close-btn{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            top: 10px;
            right: 17px;
            border: 1px solid #E1E1E1;
            box-sizing: border-box;
            border-radius: 50%;
            transform: matrix(-1, 0, 0, 1, 0, 0);
            cursor: pointer;     
        }
        .title{
                font-family: Noto Sans;
                font-style: normal;
                font-weight: normal;
            h3{
                color: #000811;
                font-size: 22px;
                line-height: 30px;
            }
            p{
                font-size: 12px;
                color: #001122;
            }
        }
        form{
            input[type=text],input[type=password]{
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                color: #000811;
                letter-spacing: 2px;
            }
            #phonenumber.form-control{
                height: 52px;
                padding-top: 26px;  
                padding-left: 48px;
            }
            .form-control{
                height: 52px;
                padding-top: 26px; 
                :focus{
                    background-color: transparent;
                }
            }
            .form-phone{             
                position: relative;
                margin-top: 44px;
                margin-bottom: 14px;
                .phone-label{
                    position: absolute;
                    top: 7px;
                    left: 12px;
                    font-size: 12px;
                    line-height: 16px;
                    color: #727272;
                    bottom: 25px;
                }
                .phone-prefix{
                    position: absolute;
                    top: 26px;
                    left: 12px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    color: #000811;
                }
                #editPhone{
                    display: inline-block;
                    position: absolute;
                    top: 17px;
                    right: 6px;
                    cursor: pointer;
                }     
                .form-control-edit{
                    border: transparent;
                    background-color: transparent;
                }       
            }
            .form-credit-card{
                position: relative;
                margin-top: 14px;
                .card-label{
                    position: absolute;
                    top: 7px;
                    left: 12px;
                    font-size: 12px;
                    line-height: 16px;
                    color: #727272;
                }
            }
            .form-consent{
                margin-top: 14px;
                margin-bottom: 36px;
                display: flex;
                input{
                    -webkit-appearance: none;
                    appearance: none;
                    background-color: #fff;
                }
                input[type=checkbox] {
                    width: 40px;
                    height: 16px;
                    border: 1px solid #E3E3E3;
                    box-sizing: border-box;
                    border-radius: 2px;
                    transform: translateY(-0.075em);
                    &:focus{
                        outline: none;
                    }
                }
                input[type=checkbox]::before {
                    content: "";
                    transform: scale(0);
                    // background-color: #005AB7;
                    // border: none;
                    // border-radius: 2px;
                  }
                input[type=checkbox]:checked {                                          
                        display: grid;
                        place-content: center;
                        outline: none;
                        color: #fff;
                        background-color: #005AB7;
                        &::before{
                            // content: "✔";                           
                            transform: scale(1);
                        }
                }
                span{
                    display: block;
                    font-family: 'Noto Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 14px;
                    color: #001122;
                    padding-left: 12px;
                }
            }
            .error-text{
                font-size: 12px;
                padding-top: 10px;
                color: red !important;
            }
            .form-otp{
                position: relative;
                margin-top: 14px;
                .otp-label{
                    position: absolute;
                    top: 7px;
                    left: 12px;
                    font-size: 12px;
                    line-height: 16px;
                    color: #858585;
                }
            }
            .btn{
                width: 120px;
                height: 36px;
                background: #005AB7;
                border-radius: 2px;
                color: #FFFFFF;
                font-weight: 600;
                font-size: 12px;
            }
            .timer-div{
                display: flex;
                align-items: center;
            }
            .timer{
                color: #004388;
            }
            .submit-otp{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .resend{
                    color: #004388;
                    margin-right: 15px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    cursor: pointer;
                   
                }
                .resend-disabled{
                    color:#727272 !important;
                    margin-right: 15px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    cursor: not-allowed !important;
                }
            }
        }
        .foot-text{
            font-size: 12px;
            line-height: 16px;
            color: #727272;
            margin-top: 42px;
            .guest{
                display: inline-block;
                // color: #0071cc;
                cursor: pointer;
                // text-decoration: underline;
            }
        }
    }
}
.lmodal-mbl{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: auto;
    // height: 90%;
    box-sizing: border-box;
    &-logo{
        position: relative;
        background: #D0E7FF;
        width: 100%;
        // height: 20%;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction:  column;
        padding-bottom: 18px;
        img{
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 35px;
        }
        .logo-text{
            margin-top: 12px;
            margin-left: 78px;
            margin-right: 76px;
            margin-bottom: 30px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: #000811;
        }
        .close-btn{
            position: absolute;
            top: 20px;
            right: 21px;
            // cursor: pointer;
            img{
                margin-top: 0px;
            }
        }
    }
    &-content{
        width: 100%;
        height: 80%;
        padding-top: 32px;
        padding-left: 16px;
        padding-right: 16px;
        position: relative;
        .close-btn{ 
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            top: 10px;
            right: 17px;
            border: 1px solid #E1E1E1;
            box-sizing: border-box;
            border-radius: 50%;
            transform: matrix(-1, 0, 0, 1, 0, 0);
            cursor: pointer;     
        }
        .title{
                font-family: Noto Sans;
                font-style: normal;
                font-weight: 400;
                .login-name {
                    font-size: 22px;
                    line-height: 30px;
                    color: #000811;
                }
            h3{
                color: #000811;
                font-size: 22px;
                line-height: 30px;
            }
            p{
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.25px;
                color: #001122;
                padding-top: 8px;
            }
        }
        form{
            input{
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                color: #000811;
                letter-spacing: 2px;
            }
            #phonenumber.form-control{
                height: 52px;
                padding-top: 18px;  
                padding-left: 48px;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                outline: 0;
            }
            .form-control {
                height: 52px;
                padding-top: 26px; 
                :focus{
                    background-color: transparent;
                }
            }
            .form-phone{             
                position: relative;
                margin-top: 72px;
                margin-bottom: 14px;
                display: flex;
                flex-direction: column;
                .phone-label{
                    // position: absolute;
                    // top: 7px;
                    // left: 12px;
                    // bottom: 25px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    color: #858585; 
                    padding: 0 12px;
                    pointer-events: none;
                    position: absolute;
                  transform: translate(0, 26px) scale(1);
                  transform-origin: top left;
                  transition: all 0.2s ease-out;
                }
                .form-phone:focus-within label {
                    transform: translate(0, 12px) scale(0.75);
                }
                .phone-prefix{
                    position: absolute;
                    top: 26px;
                    left: 12px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    color: #000811;
                }
                #editPhone{
                    display: inline-block;
                    position: absolute;
                    top: 17px;
                    right: 6px;
                    cursor: pointer;
                }     
                .form-control-edit{
                    border: transparent;
                    background-color: transparent;
                } 
            }
            .form-credit-card{
                position: relative;
                margin-top: 14px;
                display: flex;
                flex-direction: column;
                .card-label{
                    // position: absolute;
                    // top: 7px;
                    // left: 12px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    color: #858585; 
                    padding: 0 12px;
                    pointer-events: none;
                    position: absolute;
                  transform: translate(0, 26px) scale(1);
                  transform-origin: top left;
                  transition: all 0.2s ease-out;
                }
            }
            .form-consent{
                margin-top: 14px;
                margin-bottom: 36px;
                display: flex;
                input{
                    -webkit-appearance: none;
                    appearance: none;
                    background-color: #fff;
                }
                input[type=checkbox] {
                    width: 40px;
                    height: 16px;
                    border: 1px solid #E3E3E3;
                    box-sizing: border-box;
                    border-radius: 2px;
                    transform: translateY(-0.075em);
                    &:focus{
                        outline: none;
                    }
                }
                input[type=checkbox]::before {
                    content: "";
                    transform: scale(0);
                    // background-color: #004A8D;
                    // border: none;
                    // border-radius: 2px;
                  }
                input[type=checkbox]:checked {                                          
                        display: grid;
                        place-content: center;
                        outline: none; 
                        color: #fff;
                        background-color: #005AB7;
                        &::before{
                            // content: "✔";                           
                            transform: scale(1);
                        }
                }
                span{
                    display: block;
                    font-family: 'Noto Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #001122;
                    padding-left: 12px;
                }
            }
            .error-text{
                font-size: 12px;
                padding-top: 10px;
                color:red !important
            }
            .form-otp{
                position: relative;
                margin-top: 14px;
                display: flex;
                flex-direction: column;
                .otp-label{
                    // position: absolute;
                    // top: 7px;
                    // left: 12px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    color: #858585; 
                    padding: 0 12px;
                    pointer-events: none;
                    position: absolute;
                  transform: translate(0, 26px) scale(1);
                  transform-origin: top left;
                  transition: all 0.2s ease-out;
                }
                .resend-mbl{
                    display: inline-block;
                    //position: absolute;
                    //top: 17px;
                    //right: 12px;
                    color: #004388;
                    margin-right: 15px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    cursor: pointer;
                    font-weight: 500;
                    letter-spacing: -0.24px;
                    
                }
                .resend-mbl-disabled{
                    display: inline-block;
                    //position: absolute;
                    //top: 17px;
                    //right: 12px; 
                    margin-right: 15px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    font-weight: 500;
                    letter-spacing: -0.24px;
                    color:#858585 !important;
                    cursor: not-allowed !important;
                }

            }
            button{
                width: 100%;
                height: 36px;
                background: #005AB7;
                border-radius: 2px;
                color: #FFFFFF;
                font-weight: 600;
                font-size: 15px;
                line-height: 26px;
            }
            .timer-div{
                position: absolute;
                top: 17px;
                right: 12px;
                display: flex;
                align-items: center;
            }
            .timer{
                color: #004388;
            }
            .submit-otp-mbl{
                display: flex;
                justify-content: space-between;
                align-items: center;
                // .resend{
                //     color: #004388;
                //     margin-right: 15px;
                //     font-weight: 500;
                //     font-size: 12px;
                //     line-height: 20px;
                //     cursor: pointer;
                // }
            }
        }
        .foot-text{
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #001122;
            margin-top: 42px;
            margin-bottom: 68px;
            .guest{
                display: inline-block;
                color: #0071E6;
                cursor: pointer;
                font-weight: 800;
                line-height: 15px;
                border-bottom: 1px solid #0071E6 ;
            }
        }
    }
}

.foot-logo {
    text-align: center;
    width: 100%;
    background: #F9F9F9;

    .cpy-text{ 
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #3F3F3F;
        margin: 14px 30px 9px 30px;
    }
}

#float-label {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  #float-label-card {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  #float-label-otp {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  #float-label input {
    height: 56px;
    padding: 14px 16px 0 10px;
    outline: 0;
    border-radius: 4px;
    background: #fff;
    font-size: 14px;
  }

    
  #float-label-card input {
    height: 56px;
    padding: 14px 16px 0 10px;
    outline: 0;
    border-radius: 4px;
    background: #fff;
    font-size: 14px;
  }

  #float-label-otp input {
    height: 56px;
    padding: 14px 16px 0 10px;
    outline: 0;
    border-radius: 4px;
    background: #fff;
    font-size: 14px;
  }
  
  #float-label label {
    padding: 0 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #858585;
    pointer-events: none;
    position: absolute;
  transform: translate(0, 18px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  }

  #float-label-card  label {
    padding: 0 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #858585;
    pointer-events: none;
    position: absolute;
  transform: translate(0, 18px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  }

  #float-label-otp label {
    padding: 0 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #858585;
    pointer-events: none;
    position: absolute;
  transform: translate(0, 18px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  }

  #float-label:focus-within label {
    transform: translate(0, 12px) scale(0.75);
  }

  #float-label-card:focus-within label {
    transform: translate(0, 12px) scale(0.75);
  }

  #float-label-otp:focus-within label {
    transform: translate(0, 12px) scale(0.75);
  }

  #float-label .phone-label-active {
    transform: translate(0, 12px) scale(0.75);
  }

  #float-label-card .card-label-active {
    transform: translate(0, 12px) scale(0.75);
  }

  #float-label-otp .otp-label-active {
    transform: translate(0, 12px) scale(0.75);
  }