/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

.footer{
    padding: 0px!important;
    @media screen and (max-width: 767px){
    display: none;
    }
    .footer-top{
        display: flex;
        justify-content: space-between;
        background-color: #143874;
        width: 100%;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        font-family: "Bliss_Bold";
        @media screen and (min-width: 768px){
            padding-left: 30px;
            padding-right: 30px;
        }
        @media screen and (min-width: 1400px){
            padding-left: 60px;
            padding-right: 60px;
        }
        @media screen and (min-width: 1500px){
            padding-left: 240px;
            padding-right: 240px;
        }
        .footer-top-content{
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            position: relative;
            top: 5px;
            cursor: pointer;
        }
        .aref{
            color: #FFFFFF;
            text-decoration-line: none;
        }
        .line-sep{
            padding-left: 30px;
            padding-right: 30px;
            @media screen and (max-width: 1051px){
                padding-left: 15px;
                padding-right: 15px;
            }
            @media screen and (max-width: 961px){
                padding-left: 10px;
                padding-right: 10px;
            }
            @media screen and (max-width: 931px){
                padding-left: 5px;
                padding-right: 5px;
            }
            @media screen and (max-width: 901px){
                padding-left: 2px;
                padding-right: 2px;
            }
        }
    }
    .footer-inside{
        background-color: #323642;
        padding-bottom: 1rem;
        @media screen and (min-width: 768px){
            padding-left: 30px;
            padding-right: 30px;
        }
        @media screen and (min-width: 1400px){
            padding-left: 60px;
            padding-right: 60px;
        }
        @media screen and (min-width: 1500px){
            padding-left: 240px;
            padding-right: 240px;
        }
        .footer-disc{
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            width: 80%;
            line-height: 22px;
            padding-top: 39px;
            padding-bottom: 128px;
            }
            
        .site-content{
            padding-top: 3rem;
        }
        .footer-line{
            border: 0.5px solid #4B4E5A;
        }
        .footer-bottom{
            padding-top: 1rem;
            display: flex;
            justify-content: space-between;
        .rights-reserved{
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
        }
        .maya-logo{
            position: relative;
            top: 2px;
        }
    }
    }
}