/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/
@import "../variable.scss";

.ser-mbl {
  margin-top: 22px;
  .ser-page-cnt {
    .map-view {
      position: relative;
      .map-view-loader {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        // height: 900px;
        background: hsla(0deg, 0%, 100%, 0.9);
        opacity: 0.75;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .loader-map {
          width: 50px;
          @keyframes rotate {
            100% {
              transform: rotate(360deg);
            }
          }
          .circular-loader {
            animation: rotate 2s linear infinite;
            .loader-path {
              fill: none;
              stroke: #b0b7c0;
              stroke-width: 3px;
              stroke-linecap: round;
              stroke-dasharray: 10, 10;
            }
          }
        }
      }
    }
    .ser-tit {
      font-family: "Noto Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      letter-spacing: -0.24px;
      color: #000811;

      .name-bld {
        font-weight: 700;
      }
    }
    .flt-sec {
      margin-top: 24px;
    }
    .sec-card {
      margin-top: 39px;
      border: 1px solid black;
    }
    .sec-pag {
      margin-top: 30px;
      .wishlist-pagination {
      
        .pagination-wrapper,
        .pagination-wrapper-mobile {
          padding-top: 0px;
          justify-content: center !important;
        }
    
        .pagination-container,
        .pagination-container-mobile {
    
          .pagination-item-leftarrow,
          .pagination-item-rightarrow,
          .pagination-item-leftarrow-mobile,
          .pagination-item-rightarrow-mobile {
            border: 1px solid #e1e1e1 !important;
            border-radius: 50% !important;
            cursor: pointer !important;
            margin: 0px 8px;
    
            @media screen and (max-width: 800px) {
              margin: 0px 4px;
            }
    
            svg {
              fill: #000;
            }
          }
    
          .pagination-item-leftarrow:hover,
          .pagination-item-rightarrow:hover,
          .pagination-item-leftarrow-mobile:hover,
          .pagination-item-rightarrow-mobile:hover {
            background: #fff;
    
            svg {
              fill: #000;
            }
          }
    
          .leftarrow-disabled,
          .rightarrow-disabled {
            svg {
              fill: #D1D5DB !important;
            }
          }
    
          .pagination-item,
          .pagination-item-mobile {
            background: #fff;
            color: #000;
            cursor: pointer;
          }
    
          .filled-selected {
            background: rgba(0, 67, 136, 1);
            color: #fff;
          }
        }
      }
      .card-paginate {
        margin: 20px 0 0 0;
        //  width: 298px;
        display: flex;
        justify-content: center;
        align-items: center;
        .circle {
          &.box-shadow {
            box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.12);
          }
        }
        .number {
          align-self: center;
          //  margin: 0 4px;
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 20px;
          width: 32px;
          height: 32px;
          border-radius: 32px;

          &-act {
            color: #fff;
            background-color: #000;
          }
        }
      }
      .item-count {
        text-align: center;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: #727272;
        margin-bottom: 22px;
        margin-left: 2px;
      }
    }
  }
  .otherSearch {
    position: relative;
    top: 28px;
    margin-bottom: 36px;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.24px;
    color: #000811;
  }
}
