// Filter HDFC

.clear {
  width: 100px;
  height: 32px;
  background: #3d4151;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  cursor: pointer;
}

.cls-icn {
  width: 16px;
  height: 16px;
  background-color: darkgray;
  border-radius: 8px;
  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  margin-left: 5px;
}

@media screen and (min-width: 420px) {
  .merc-type {
    margin: 10px !important;
  }

  .first-row:first-child {
    margin: 0px 0px 0px -10px;
  }

  .sec-row {
    margin:  0 0 0 -10px;
  }

  .catg {
    margin: 10px !important;
  }
}

@media screen and (max-width: 420px) { 
  .clear {
    margin: 10px 10px 10px 0;
  }
}

@media only screen and (max-width: 800px) {
  .filter-wrp {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start !important;
  }

  .first-row {
    display: flex;
    flex-wrap: wrap;
  }

  .sec-row {
    display: flex;
    flex-wrap: wrap;
  }

  .clear {
    margin: 10px 10px 10px 0;
  }
}

@media only screen and (min-width: 1900px) {
  .filter-wrp {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .first-row {
    display: flex;
  }

  .sec-row {
    display: flex;
  }
}

@media only screen and (max-width: 1900px) {
  .filter-wrp {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .first-row {
    display: flex;
  }

  .sec-row {
    display: flex;
  }
}


@media only screen and (max-width: 450px) {
  .first-row {
    display: flex;
    flex-direction: column;
  }

  .sec-row {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 -10px;
  }

  .first-row:first-child {
    margin: 0px 0px 0px -10px;
  }

  .sec-row:first-child {
    margin: 0px 0px 0px -10px;
  }

  .catg {
    margin: 10px !important;
  }

  .merc-type {
    margin: 10px !important;
  }

  .clear {
    margin: 10px 10px 10px 0;
  }
}
