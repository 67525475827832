/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Merchant Info
@import "../mixins.scss";

.merchant-info {
  background: #f4f4f4;
  border-radius: 8px;
  padding: 13px;

  .know-more {
    font-size: 13px;
    line-height: 18px;
    text-align: right;
    letter-spacing: -0.08px;
    color: #a9a9a9;
  }

  .taste-info {
    margin-top: 18px;

    .rating {
      height: 36px;
      border-right: 1px solid #ececec;
      margin-bottom: 10px;

      @include media-break("lg") {
        margin-bottom: 0;
      }

      .rat-tit {
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.07px;
        text-transform: uppercase;
      }

      .des-info {
        font-size: 11px;
        line-height: 15px;
        letter-spacing: 0.01em;
        text-transform: capitalize;
        color: #000000;
      }
    }
  }

  .header-info-title,
  .desc {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #000000;
  }

  .desc {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
  }
}

.merchant-info-div {
  padding-left: 0;
  padding-right: 0;

  @include media-break("lg") {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.case-map-div {
  padding-left: 0;
  padding-right: 0;
  margin-top: 10px;

  @include media-break("lg") {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 0;
  }
}

.flex-location-item {
  display: flex;

  @include media-break("lg") {
    display: unset;
  }
}

.info-item-div {
  display: flex;
  .info-item,
  .link-item {
    margin-left: 20px;
  }
  @include media-break("lg") {
    display: unset;

    .info-item,
    .link-item {
      margin-left: 0;
    }
  }
}
