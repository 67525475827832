/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Filter Component
@import "../mixins.scss";

.filter-div {
  // margin-left: 30px;
  // margin-right: 60px;
  .select-wrap-filter {
    margin-bottom: 10px;
    // width: 145px;
    @include media-break("lg") {
      margin-right: 10px;
      margin-bottom: 0;
    }
    .lin-location-pin {
      display: none;
    }
    .css-2b097c-container{
      z-index: 2;
    }
    .css-yk16xz-control,
    .css-1pahdxg-control,
    .css-1fhf3k1-control {
      padding-left: 5px !important;
      cursor: pointer;
    }
    // .css-1pahdxg-control{
    //   box-shadow: 0 0 0 1px #E30613 !important;
    //   border-color: #E30613 !important;
    // }
    // .css-1fhf3k1-control {
    //   height: 38px;
    // }
    // [class$="menu"]{
    //   [class$="option"]{
    //     background-color: #fff;
    //     &:hover{
    //       color: #E30613;
    //       background: #FFFBDC;
    //     }
        
    //   }
    //   .css-9gakcf-option{
    //     color: #E30613 !important;
    //     background: #FFFBDC !important;
    //   }
    // }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    // .css-1pahdxg-control,
    // .css-1pahdxg-control:hover {
    //   border: 1px solid #E30613 !important;
    //   border-color: #E30613 !important;
    // }
    .css-1hwfws3 > div {
      // color: #d5d8db !important;
    }
    .css-tlfecz-indicatorContainer {
      color: #000 !important;
    }
    .css-1wa3eu0-placeholder {
      font-size: 14px;
      line-height: 19px;
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .css-1uccc91-singleValue {
      font-size: 13px;
      color: #001933;
    }
    .css-1tqlaoc-MenuList {
      font-size: 11px;
    }
  }
  .more-filter {
    border: 1px solid #d5d8db;
    height: 38px;
    font-size: 13px;
    line-height: 19px;
    color: #001933;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    // margin-left: 160px;

    .more-filter-div {
      display: flex;

      .flt-nam {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #001933;
      }
      @include media-break("lg") {
        display: unset;
      }
    }
  }
}

.filter-div-arab {
  display: flex;
  width: 100%;
}

.filter-row-arab {
  display: flex;
  justify-content: flex-start;
}
.select-wrap-arab {
  margin-right: 0px !important;
  margin-left: 10px;
}
