/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/

// Page profile
@import "../mixins.scss";
@import "../variable.scss";

.pag-pro {
  padding: 0 15px;
  @include media-break("lg") {
    padding: 0 0px;
  }

  .profile-div {
    .prof {
      cursor: pointer;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 14px;
      width: 195px;
      color: #696969;
      padding: 5px 22px 4px 22px;
      border-left: 3px solid transparent;

      &.red-text {
        color: #ff0000;
      }

      &-act {
        background-color: #F5F5F5;
        border-left: 3px solid #00AE8E;
        color: #00AE8E;
        font-weight: 600;
        font-family: if($clientName == 'adib','Bliss_Bold' !important , 'Noto Sans' !important);
      }
    }
    .prof-ar {
      font-weight: normal;
      color: #222222;
      margin-bottom: 5px;
      letter-spacing: 0.02em;
      border-left: none;
      border-right: 3px solid transparent;

      &-act {
        font-weight: 600;
        background-color: #f9fafa;
        border-left: none;
      }
    }
    .empty-div {
      height: 355px;
      background: #e9f2f8;
      border: 1px solid #e9f2f8;
      box-sizing: border-box;
      border-radius: 4px;
      margin-top: 38px;
      margin-bottom: 10px;
    }
    .profile-nav {
      @media screen and (min-width: 1200px) {
        margin-right: 77px;
      }
      @media screen and (min-width: 1000px) {
        margin-right: 58px;
      }
    }
    .profile-nav-ar {
      @media screen and (min-width: 1000px) {
        margin-right: -24px;
        margin-left: 90px;
      }
    }
    .option {
      align-self: center;
      background: #f9fafa;
      border-radius: 4px;
      color: #7a7c99;
      width: 38px;
      line-height: 10px;
      padding: 10px;
      margin-left: 16px;
    }
    .profile-photo {
      .title {
        font-weight: 600;
      }
    }
    .bottom-line {
      border-bottom: 2px solid #f7f7f9;
    }
    .field-div {
      &-header {
        font-weight: 600;
        font-size: 13px;
        line-height: 19px;
        margin-bottom: 10px;
        letter-spacing: 0.02em;
      }
      &-content {
        .form-control {
          border: 1px solid #edeeee !important;
          background: #f9fafa;
          height: 43px !important;
          color: #000;
        }
        .form-control[disabled] {
          background: #ecedf0;
          color: #a2a7b0;
        }
      }
    }
    .delete {
      &-acnt {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #222222;
        margin-bottom: 16px !important;
      }
      &-link {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.24px;
        color: #8e8e93;
      }
    }
    .link-red {
      color: #ff0000;
      cursor: pointer;
    }
  }
  .select-wrap-filter {
    .lin-location-pin {
      display: none;
    }
    .css-yk16xz-control,
    .css-1pahdxg-control,
    .css-1fhf3k1-control {
      padding-left: 5px !important;
      border: 1px solid #edeeee !important;
      background: #f9fafa;
      cursor: pointer;
    }
    .css-1fhf3k1-control {
      height: 38px;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-1hwfws3 > div {
      // color: #d5d8db !important;
    }
    .css-tlfecz-indicatorContainer {
      color: #000 !important;
    }
    .css-1wa3eu0-placeholder,
    .css-1uccc91-singleValue,
    .css-1tqlaoc-MenuList {
      font-size: 13px;
      line-height: 19px;
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    // DatePicker Style
    .react-date-picker {
      width: 100%;
      height: 43px;
    }
    .react-date-picker__inputGroup {
      font-size: 14px;
      padding: 2px 14px;
      cursor: pointer;
    }
    .react-calendar {
      background: #fff;
      border: 0;
      border-radius: 16px;
      box-shadow: 0 16px 64px rgba(0, 0, 0, 0.07);
      font-size: 16px;
    }
    .react-date-picker__calendar--open {
      top: 100% !important;
      bottom: unset !important;
    }
    .react-date-picker__wrapper {
      border: 1px solid #edeeee !important;
      background: #f9fafa;
    }
    .react-date-picker__inputGroup__input:invalid {
      background: transparent;
    }
  }
  .login-inputbox {
    height: 56px;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .login-button {
    background: #00AE8E;
    border-radius: 8px;
    color: #fff;
    font-size: 18px !important;
    line-height: 25px !important;
    padding: 17px 19px;
    min-width: 150px;

    .btn-ttl {
      width: 100%;
    }
    .img-rotate {
        svg {
          transform: rotate(180deg);
        }
    }
  }
}
