/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/


// Default
@import "./variable.scss";
@import "./mixins.scss";


html,body {
  overflow-x: hidden !important;
}
.mainapp {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.homeflex {
  flex: 1;
}

a {
  text-decoration: none;
  color: $primary-color;
}

.dib {
  display: inline-block !important;
}

.lang-direction-ltr{
  direction: ltr;
}

.flex {
  display: flex !important;
  &-wrap {
    flex-wrap: wrap;
  }
  &-direction-row {
    flex-direction: row;
  }
  &-direction-col {
    flex-direction: column;
  }
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.justify-se {
  justify-content: space-evenly !important;
}
.justify-between {
  justify-content: space-between !important;
}
.justify-end {
  justify-content: flex-end !important;
}
._ai-self {
  align-self: center;
}
._ai-c {
  align-items: center !important;
}
._ai_baseline {
  align-items: baseline !important;
}
.error {
  border-top: 15px solid $warning !important;
}
.item__selected {
  border-top: 15px solid $bright-blue !important;
}
.width-200 {
  width: 200px;
}
.width-108 {
  width: 108px;
}
.width-60 {
  width: 60px;
}
.flex-space {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-space-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
}
.flex-grow {
  flex-grow: 1;
}
.flex-align {
  display: flex;
  align-items: center;
}
.w-100 {
  width: 100%;
}
.w-100px {
  width: 100px;
}
.p-2 {
  padding: 2rem;
}
.pr-1 {
  padding-right: 1rem;
}
.p-3 {
  padding: 3rem;
}
.p-0-5 {
  padding: 0.5rem !important;
}
.p-sm {
  padding: 0.75rem !important;
}
.p-1 {
  padding: 1rem;
}
.mt--30 {
  margin-top: -30px;
}
.mt-1 {
  margin-top: 1px;
}
.mt-2 {
  margin-top: 1.25rem;
}
.mtp-1 {
  margin-top: 1px;
}
.mtp-4 {
  margin-top: 4px;
}
.mtp-10 {
  margin-top: 10px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-14 {
  margin-top: 14px;
}
.mt-18 {
  margin-top: 18px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-21 {
  margin-top: 21px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-26 {
  margin-top: 26px;
}
.mt-28 {
  margin-top: 28px;
}
.mt-32 {
  margin-top: 28px;
}
.mt-36 {
  margin-top: 36px;
}
.mt-42 {
  margin-top: 42px;
}
.mt-46 {
  margin-top: 46px;
}
.mt-6 {
  margin-top: 6px;
}
.mt-12 {
  margin-top: 12px;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-14 {
  margin-bottom: 14px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-28 {
  margin-bottom: 28px;
}
.mb-4 {
  margin-bottom: 4px !important;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-30 {
  padding-top: 30px;
}
.primary-color {
  color: $primary-color !important;
}

.pl-0 {
  padding-left: 0px;
}

.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px !important;
}
.pr-0 {
  padding-right: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.mt-25 {
  margin-top: 25px !important;
}
.mtp-2 {
  margin-top: 2px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10{
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.bg-primary {
  background: $primary-color !important;
}

.bg-white {
  background: $secondary-color !important;
}

.b-grey {
  border: 1px solid #cfe9ff;
}

.b-l {
  border-left: 1px solid #cfe9ff;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-center {
  text-align: center;
}
.cursor-default {
  cursor: default !important;
}
.vertical-align-text-bottom {
  vertical-align: text-bottom;
}
.filter-tab {
  padding-top: 1em;
  padding-left: 7em;
}
.mt-30 {
  margin-top: 30px !important;
}
.m-30 {
  margin: 30px;
}
.m-3 {
  margin: 3rem;
}
.m-2 {
  margin: 2rem;
}
.m-1 {
  margin: 1rem;
}
.m--1 {
  margin: -1rem;
}

.m-auto {
  margin: 0 auto;
}
.mt-10 {
  margin-top: 10px;
}
.mt-4 {
  margin-top: 4px;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-18 {
  margin-top: 18px;
}
.mtb-30 {
  margin: 30px 0;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-15 {
  margin-top: 15px;
}
.mb-2 {
  margin-bottom: 1.5em;
}
.mt-2 {
  margin-bottom: 1.5em;
}
.ml-minus15 {
  margin-left: -15px;
}
.ml-auto {
  margin-left: auto;
}
.ml-5 {
  margin-left: 5px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-9 {
  margin-left: -9px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-18 {
  margin-left: 18px;
}
.ml-3 {
  margin-left: 4px;
}
.mr-auto {
  margin-right: auto;
}
.mr-10 {
  margin-right: 10px;
}
.mr-12 {
  margin-right: 12px;
}
.mr-18 {
  margin-right: 18px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-15 {
  margin-right: 15px;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.action {
  margin: 4px 0px;
}

.text-right {
  text-align: right !important;
}
.text-left{
  text-align: left !important;
}
.mt-3 {
  margin-top: 1.25em !important;
}
.no-padding {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.no-padding-right {
  padding-right: 0px !important;
}
.no-padding-left {
  padding-left: 0px !important;
}
.white {
  color: white !important;
}
p {
  word-wrap: break-word;
}
.m-r-3 {
  margin: 1.5rem 0;
}

.br-1 {
  border-right: 1px solid #595959;
}

.b-top {
  border-top: 1px dotted #ddd;
}
.bg-blue {
  background: #cfe9ff !important;
}
.h-65 {
  height: 65px;
}
.pad-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.pb-10 {
  padding-bottom: 10px;
}
.placeholder::placeholder {
  color: $primary-color;
}
.margin-0 {
  margin: 0px !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.p-1-5 {
  padding: 1.25em 0 0 0;
}

.p-0\.75 {
  padding: 0.75rem !important;
}

.pd_lr_2 {
  padding: 0 2px;
}
.m-1-5 {
  margin: 0.5em;
}

.p-1-2 {
  padding: 1rem 2rem !important;
}
.b-thin {
  border: 1px solid #606060;
  border-radius: 3px;
  font-size: 12px;
}
.bg-light-grey {
  background: #606060 !important;
}

.bg-dark-white {
  background: #f7f7f7;
}

.bg-grey {
  border: 1px solid #606060;
  border-radius: 0.5px;
}
.bg-fare {
  background: #eeeeee;
  height: 30px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.v-t {
  vertical-align: top;
}

.ml-30 {
  margin-left: 30px;
}

//fonts
.f-11 {
  font-size: 11px !important;
}
.f-12 {
  font-size: 12px !important;
}
.f-18 {
  font-size: 18px !important;
}
.f-20 {
  font-size: 20px !important;
}
.f-30 {
  font-size: 30px !important;
}
.f-13 {
  font-size: 13px !important;
}
.f-14 {
  font-size: 14px !important;
}
.f-15 {
  font-size: 15px !important;
}

.flex-space .form-group {
  flex-grow: 0;
  flex-basis: 100%;
  margin-right: 10px;
}

.capital {
  text-transform: lowercase;
  line-height: 3;
}

.capital::first-letter {
  text-transform: uppercase;
}

.fl-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

._fd-row {
  flex-direction: row;
}

._fd-column {
  flex-direction: column;
}
.m-p {
  margin: 0.5em;
  padding: 0.5em;
}
.mb-0 {
  margin-bottom: 0px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.pointer-none {
  cursor: default;
  pointer-events: none;
}

.m-p-5 {
  margin: 1em;
  padding: 1em;
}
.box .lin {
  color: #595959 !important;
}

.customer-right-sec {
  height: calc(100vh - 50px);
  overflow-x: scroll;
}
.pr {
  position: relative;
}

.table1 tbody tr td,
.table1 tbody tr th {
  vertical-align: middle;
  text-align: center;
}

.p-0 {
  padding: 0px;
}
.pb-1 {
  padding-bottom: 1rem !important;
}
.padding-30 {
  padding: 0 30px;
}
.pr-0 {
  padding-right: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-1 {
  padding-left: 5px;
}
.pl-6 {
  padding-left: 6px;
}
.pl-32 {
  padding-left: 32px;
}
.ml-3 {
  margin-left: 3rem;
}

.ml-0\.5 {
  margin-left: 1rem;
}

.mt-3 {
  margin-top: 3rem;
}
.cursor {
  cursor: pointer;
}
.search-circle {
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e1e1e1;
  border-radius: 32px;
  margin-left: 27px;
  margin-right: 27px;

  &.disable {
    cursor: not-allowed;
    pointer-events: none;
    // background-color: #f7f7f7;

    // svg {
    //   fill: #a0a0a0;
    // }
  }
}
.circle {
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e1e1e1;
  border-radius: 32px;
 

  &.disable {
    cursor: not-allowed;
    pointer-events: none;
    // background-color: #f7f7f7;

    // svg {
    //   fill: #a0a0a0;
    // }
  }
}

.red {
  background-color: #ee3a43;
}

.dark-green {
  background-color: #0e5534;
}

.case-map {
  margin-bottom: 16px;

  .small-map {
    height: 170px !important;
  }
}

.theme-search-results-item-rating-stars > li.active {
  color: $yellow;
}

.theme-search-results-item-rating-stars {
  list-style: none;
  margin: 0;
  margin-right: 4px;
  padding: 0;
  float: left;
}
.theme-search-results-item-rating-stars > li {
  display: inline-block;
  margin-right: 3px;
  font-size: 11px;
  color: #ccc;
}
.light-gray-color {
  color: $gray-04 !important;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.card-categoryName {
  border-radius: 3px;
  background-color: rgba(229, 229, 234, 0.4);
  color: #616161;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 9px;
  text-transform: capitalize;
}
.loder-div-notification{
  padding: 0 0px;
  margin-top: 20px;
}
.loder-div {
  padding: 0 30px;
  margin-top: 20px;

  &.merchant {
    margin-top: 0px;
    padding: 0 !important;
    
  }
}

.floatNone {
  float: none;
}
.floatRight {
  float: right;
}

.red-text {
  color: #ff0000;
}

.common-bg {
  background-color: #00AE8E;
}

.header-details {
  width: 100%;
  padding: 0 15px;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 10;

  // @include media-break("lg") {
  //   padding: 0 60px !important;
  // }
}

.menu-disable {
  cursor: not-allowed;
  pointer-events: none;
  color: #C6C6C6 !important;
}
.headerCountry {
  padding-right: 36px;
}
.headerLeftCountry {
  padding-left: 36px !important;
  &.arabic-align{
    .css-26l3qy-menu{
      right: -35px;
    }
  }
}
.user-content {
  align-items: center;
}

.circleADB {
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 32px;

  &.camp {
  //  width: 44px;
  //  height: 44px;
  }
 
  &.disable {
    cursor: not-allowed;
    pointer-events: none;
  }
}
.p-l-45px{
  padding-left: 45px;
}