/**
*
* Disclaimer: Source code mentioned below is(are) Intellectual Property of
* Crayon Data Holdings Limited (including its subsidiaries and affiliates).
* Crayon Data Holdings Limited reserves right to own and control it the way
* it may deem fit. You must refrain from use, access, read, modify, add or
* delete, sell or use in any other package or programme pertaining to such
* source code without explicit prior written approval of
* Crayon Data Holding Limited. Breach of the same shall attract penalty as
* applicable.
*
*/
@import "../variable.scss";
// Redeem Modal
$redeem-black-color: #000;
$redeem-header-color: #616161;

.redeem-modal {
  width: 100%;
  height: 100%;
  .header {
    .image {
      position: relative;
      .lottie-wrap {
        position: absolute;
        top: 5px;
        z-index: -1;
    }
    }

    .abs-close {
      position: absolute;
      cursor: pointer;
      right: 20px;
      top: 8px;
      background: #f7f7f7;
      width: 42px;
      height: 42px;
      @media(max-width:600px){
        right: 7px;
        top: 12px;
        width: 30px;
        height: 30px;
        background: #E7E7E7;
      }
      border-radius: 42px;
      z-index: 1;
      svg {
        path {
          stroke: $redeem-black-color;
        }
      }
    }

    .content-details {
      .content {
        &-div {
          width: 336px;
          margin-top: 18%;
          margin-bottom: 10%;
          @media(max-width:600px){
            margin-top: 40px;
            margin-bottom: 32px;
          }
          .mt-22{
            @media(max-width:600px){
              margin-top: 22px !important;
            }
          }
          .dashed-line {
            margin-top: 28px;
            margin-bottom: 28px;
            border-bottom: 2px dashed #E3E3E3;
          }
        }
        &-header {
          font-size: 30px;
          line-height: 28px;
          color: $redeem-black-color;
        }
        &-info {
          font-size: 17px;
          line-height: 23px;
          color: #616161 !important;
          @media screen and (max-width: 932px) {
            margin-left: 41px;
            margin-right: 41px;
          }
        }
        &-code {
          font-size: 13px;
          line-height: 16px;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          color: #1A2C4A !important;
        }
        &-redeem-code {
          font-size: 30px;
          line-height: 36px;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          color: #01336d;
          position: relative;
          }
        &-desc {
          font-size: 13px;
          line-height: 15px;
          color: #616161;
        }
        &-desc-offline{
          font-size: 18px;
          line-height: 21px;
          color: #404040;
          margin-top: 24px;
        }
        &-footer {
          margin-top: 48px;
          display: flex;
          @media(max-width:600px){
            margin-top: 27px;
          }
          .btn {
            height: 56px !important;
            border-radius: 8px;
          }
        }
      }
      
      .code-tooltip{
        position: relative;
        display: inline-block;
          .tooltiptext{
                visibility: visible;
                width: 82px;
                line-height: initial;
                background-color: rgb(83, 83, 83);
                color: #fff;
                text-align: center;
                font-size: 10px;
                border-radius: 1px;
                padding: 2px 0;
                position: absolute;
                z-index: 1;
                top: 5px;
                left: 110%;
                text-transform: capitalize;
                &::after{
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 100%;
                    margin-top: -5px;
                    border-width: 4px;
                    border-style: solid;
                    border-color: transparent rgb(83,83,83) transparent transparent;
                }
            }
          // &:enabled{
          //   .tooltiptext{
          //     visibility: visible;
          //   }
          // }
        }
      .redeem-button {
        width: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        border-radius: 8px;
        vertical-align: middle;
        text-align: center;
        padding: 6px 12px;
        font-size: 17px;
        line-height: 23px;
        cursor: pointer;

        &.adb {
          font-weight: 600;
          text-transform: uppercase;
          font-family: if($clientName == 'adib','Bliss_Bold' !important , 'Noto Sans' !important);
        }
      }
      .mainName {
        font-family: if($clientName == 'adib','Bliss_Bold' !important , 'Noto Sans' !important);
        color : #000000
      }
    }
  }
}

.card-image-redeem{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 180px;
  max-width: 100%;
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  @media screen and (max-width: 932px) {
    width: 180px;
    margin: 0 auto;
  }
}